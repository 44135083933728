<template>
  
      <div class="all_messages">
        <div class="messages">
         
          <div class="column">
            <div
              v-for="(item, index) in groupKnowledges"
              :key="index"
              @click="showMessage = item"
              class="row"
              :class="showMessage ? (showMessage.id == item.id ? 'active_background' : '') : ''"
            >
              <div class="message_information">
                <i
                  style="font-size:25px;color:#29BCFF;margin-right:10px;"
                  class="fas fa-newspaper"
                ></i>
                <div>
                  <span class="message_title">
                    {{ item.title | truncate(30) }}</span
                  >
                  <br />
                  <span class="message_subject">
                    {{ item.published_date }}
                  </span>
                </div>
              </div>
              <div>
                <br />
                <!-- <span class="message_date">{{ item.date }}</span> -->
              </div>
            </div>
          </div>
        </div>

        <div class="main_messages_container">
          <div class="message_description_container" v-if="showMessage">
            <div class="date">{{ formattedDate }}</div>
            <div class="message_description">
              <div class="arrow-left"></div>
              <div class="message_title">
                <!-- <span>Rep: {{showMessage.count }}</span>
                <span>Open: {{showMessage.open }}</span> -->
              </div>
              <h2 class="show-message">{{ showMessage.title }}</h2>
              <div class="message_content">
                <p v-html="showMessage.description"></p>
                <br />
                <div class="pt-4" v-if="showMessage.file && showMessage.file.length > 0">
                  <h6>Attachments:</h6>
                  <ul class="pl-4" style="list-style:none">
                    <li class="mb-2" v-for="file in showMessage.file" :key="file.id">
                      <a
                        :href="knowledgeBaseDownloadUrl + file.filename"
                        target="__blank"
                        download
                         class="document-link"
                      >
                        <i class="fa fa-file"></i>
                        {{ file.filename }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import { mapGetters } from "vuex";
import moment from "moment";
import { knowledgeBaseDownloadUrl } from "@/resource/EndPoints/endpoints";
export default {
  data: () => {
    return {
      showMessage: null,
      knowledgeBaseDownloadUrl: knowledgeBaseDownloadUrl,
      breadcrumbItems: [{ title: "Knowledge Center", active: true }],
    };
  },
  computed: {
    ...mapGetters({ groupKnowledges: "groupKnowledges" }),
    formattedDate() {
      return moment(this.showMessage.published_date).format("gg MMM , ddd");
    },
  },
  mounted() {
    //incase of some udpates - dispatching.
    this.$store.dispatch("getGroupKnowledges");
    let loader = this.$loading.show({
      container: this.$refs.knowledgeBaseContainer,
    });
    this.showMessage = this.groupKnowledges[0];
    setTimeout(() => {
      loader.hide();
    }, 1000);
     if (this.groupKnowledges.length < 1) {
      this.$router.push({ name: "GroupDashboard" });
    }
  },
};
</script>
<style src="@/assets/css/group_dashboard/all_messages.css" scoped></style>
