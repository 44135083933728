var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"initial_page"},[_c('div',{staticClass:"main_page"},[_vm._m(0),_c('div',{staticClass:"form"},[_c('div',{staticClass:"reset_form"},[_c('h3',[_vm._v("Reset Password")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"New Password","vid":"newpassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"New Password","label-for":"input-1"}},[_c('div',{staticClass:"changeForm-group errorIcon_eye"},[_c('b-form-input',{attrs:{"id":"input-1","type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"Enter your new password"},model:{value:(_vm.newpassword),callback:function ($$v) {_vm.newpassword=$$v},expression:"newpassword"}}),_c('eye-button',{staticClass:"toggle-eye-icon",attrs:{"id":"input-1","hideOnDefault":"true","type":"text"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])}}],null,true)}),_c('b-form-group',{attrs:{"label":" ","label-for":""}},[_c('div',[_c('password-strength-bar',{attrs:{"password":_vm.newpassword},on:{"updateScore":_vm.setPasswordScore}})],1)]),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:newpassword","name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"input-2"}},[_c('div',{staticClass:"changeForm-group",class:errors[0] || valid ? 'errorIcon_eye' : ''},[_c('b-form-input',{attrs:{"id":"input-2","type":"text","state":errors[0] ? false : valid ? true : null,"placeholder":"Repeat new password"},model:{value:(_vm.confirmpassword),callback:function ($$v) {_vm.confirmpassword=$$v},expression:"confirmpassword"}}),_c('eye-button',{staticClass:"toggle-eye-icon",attrs:{"id":"input-2","hideOnDefault":"true","type":"text"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])}}],null,true)}),_c('button',{attrs:{"disabled":_vm.verifyLoader}},[(_vm.verifyLoader == true)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.verifyLoader ? "Changing Password..." : "Change Password")+" ")],1)],1)]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"forgot_welcome"},[_c('h1',[_vm._v("Forgot your Password?")]),_c('p',[_vm._v(" Don't Worry! we've got your back. Just follow the instructions and you'll be good :) ")])])}]

export { render, staticRenderFns }