<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="view_user">
        <div class="title">
          <h2>View User</h2>
          <button v-if="groupInfo.is_root" class="add_user" v-b-modal.add_group_user>
            <i class="fas fa-plus"></i>
            <span style="margin-left:5px;">Add User</span>
          </button>
        </div>

        <!-- add group user  -->
          <AddNewUser :userList=getGroupUsers />
        <!-- add group user  -->
        <div class="view_user_tabs">
          <b-tabs>
            <b-tab title="Active User" active>
              <div class="active_user">
                <div class="table_container table-responsive" v-if="this.active_user != 0">
                  <table class="table" v-if="this.active_user != 0">
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Username</td>
                        <td>Phone</td>
                        <td>User Created</td>
                        <td v-if="groupInfo.is_root">Action</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in active_user" :key="index">
                        <td>{{ item.first_name }} {{ item.last_name }}</td>
                        <td>{{ item.login_email }}</td>
                        <td>{{ item.login_phone }}</td>
                        <td>{{ item.created_date }}</td>
                        <td v-if="groupInfo.is_root">
                          <button
                            @click.prevent="deleteUser(item.user_id)"
                            class="user_action"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="no_user" v-if="this.active_user == 0">
                  <p>
                    <i class="far fa-file "></i>
                    <span style="margin-left:10px;"
                      >No Active User Detected</span
                    >
                  </p>
                </div>
              </div>
            </b-tab>

            <b-tab title="Pending User">
              <div class="pending_user">
                <div class="table_container table-responsive">
                  <table class="table" v-if="this.pending_user != 0">
                    <thead>
                      <tr>
                        <td>Name</td>
                        <td>Username</td>
                        <td>Phone</td>
                        <td>User Created</td>
                        <td v-if="groupInfo.is_root">Action</td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, index) in pending_user" :key="index">
                        <td>{{ item.first_name }} {{ item.last_name }}</td>
                        <td>{{ item.login_email }}</td>
                        <td>{{ item.login_phone }}</td>
                        <td>{{ item.created_date }}</td>
                        <td v-if="groupInfo.is_root">
                          <button
                            @click.prevent="deleteUser(item.user_id)"
                            class="user_action"
                          >
                            Delete
                          </button>
                          <button
                            @click.prevent="activateUser(item.user_id)"
                            class="user_action user_action_activate"
                          >
                            Activate
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="no_user" v-if="this.pending_user == 0">
                  <p>
                    <i class="far fa-file "></i>
                    <span style="margin-left:10px;"
                      >No Pending User Detected</span
                    >
                  </p>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import AddNewUser from "@/pages/User/AddUser.vue";
import { mapGetters } from "vuex";

export default {
  components: { AddNewUser },
  data() {
    return {
      active_user: [],
      pending_user: [],
      is_root: false,
    };
  },
computed: {
    ...mapGetters({
      groupInfo: "groupInfo",
    })

  },
  methods: {
    getGroupUsers() {
      let loader = this.$loading.show();
      let url = `group-user/get`;
      Api.get(url)
        .then((res) => {
          this.active_user = res.data.data.active_lists;
          this.pending_user = res.data.data.pending_list;
        })
        .catch((err) => {
          console.log("Something went wrong!");
        })
        .finally(() => {
          loader.hide();
        });
    },
    deleteUser(userId) {
      let url = `group-user/delete/${userId}`;
      this.$store.commit("setDialogClass", "group-app-confirm-dialog");
      this.confirmMessage(
        url,
        "Delete Bank",
        `Do you want to delete this user?`,
        "delete"
      );
    },
    activateUser(userId) {
      let url = `group-user/activate?user_id=${userId}`;
      this.$store.commit("setDialogClass", "group-app-confirm-dialog");
      this.confirmMessage(
        url,
        "Activate User",
        `Do you want to activate user?`,
        "put"
      );
    },
    confirmMessage(url, title, message, method_type) {
      this.$confirm({
        title: title,
        message: message,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api[method_type](url)
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.getGroupUsers();
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
  created() {
    this.getGroupUsers();
  },
};
</script>
<style src="../../assets/css/group_dashboard/view_user.css"></style>
<style scoped>
.user_action_activate {
  margin-left: 1%;
  background: #33c733 !important;
}
.active_user {
  background-color: #fff !important;
}
.pending_user {
  background-color: #fff !important;
}
/* add group user  */
</style>
