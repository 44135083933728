<template>
  <b-modal
    id="paystand_dialog"
    centered
    hide-header
    no-close-on-backdrop
    size="lg"
  >
    <div class="title">
      <h2>Pay Now</h2>
      <!-- <span>payment</span> -->
    </div>
    <img
      @click="$bvModal.hide('paystand_dialog')"
      class="close_cross"
      src="@/assets/images/cross.svg"
      alt="cross"
    />
    <template v-if="step == 1">
      <payment-invoice-list :invoice="invoice" />
    </template>
    <template v-if="step == 2">
      <div class="update_payment" ref="paymentContainer">
        <div class="mbt-1">
          <h6>
            {{ groupInfo.group_name }}
          </h6>
          <h6>
            Payment : <strong> {{ totalSum | currency }}</strong>
          </h6>
        </div>
        <div class="mbt-1">
          <h6>Selected Invoices</h6>
          <ul class="selectedInvoices" v-if="invoiceIds.length > 0">
            <li v-for="invoiceId in invoiceIds" :key="invoiceId">
              {{ invoiceId }}
            </li>
          </ul>
        </div>
        <payment-invoice-bank-details @setSelectedBank="getSelectedBank" />
      </div>

      <!-- <div class="error_message">
        <span>
          Selected account is in blacklist! So, please select another option.
        </span>
      </div> -->
    </template>
    <template #modal-footer>
      <b-button class="btn btn-secondary" @click="cancel()">
        {{ cancelTitle }}
      </b-button>
      <b-button
        class="btn btn-primary"
        :disabled="!nextStep"
        @click.prevent="paymentStep()"
      >
        {{ stepTitle }}
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import PaymentInvoiceBankDetails from "@/pages/Invoice/Payment/PaymentInvoiceBankDetails.vue";
import PaymentInvoiceList from "@/pages/Invoice/Payment/PaymentInvoiceList.vue";
import Api from "@/resource/Api";
import moment from "moment";

export default {
  components: { PaymentInvoiceList, PaymentInvoiceBankDetails },
  props: {
    invoice: {
      required: true,
    },
  },
  data: () => {
    return {
      step: 1,
      stepTitle: "Next",
      cancelTitle: "Cancel",
      nextStep: false,
      form: {
        bank_account: "",
        bank_account_type: "",
        bank_accountname: "",
        bank_name: "",
        bank_routing: "",
        invoice_id: [],
        schedule_date: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      invoiceIds: "invoiceIds",
      groupInfo: "groupInfo",
      unPaidInvoices: "unPaidInvoices",
    }),
    scheduledNextDate() {
      return moment()
        .add(1, "day")
        .format("YYYY-MM-DD");
    },
    totalSum() {
      let total = 0;
      total = this.unPaidInvoices.reduce((total, value) => {
        if (this.invoiceIds.includes(value.invoice_id)) {
          total = total + Number(value.invoice_due_amount);
        }
        return total;
      }, 0);
      return total;
    },
  },
  methods: {
    reset() {
      this.step = 1;
      this.$store.commit("setInvoiceIds", []);
      this.$bvModal.hide("paystand_dialog");
      this.setData();
    },
    payNow() {
      this.form.invoice_id = this.invoiceIds;
      this.form.invoices = [];
      this.form.schedule_date = this.scheduledNextDate;
      const url = "schedule-payment-group-invoice";
      let loader = this.$loading.show({
        container: this.$refs.paymentContainer,
      });
      Api.post(url, this.form)
        .then((res) => {
          const response = res.data.message;
          this.$notify(response);
          setTimeout(() => {
            this.step = 1;
            this.$bvModal.hide("paystand_dialog");
            this.setData();
            this.$nextTick(() => {
              this.$emit("onPaymentSuccess");
            });
          }, 2000);
        })
        .catch((err) => {
          this.nextStep = true;
          // this.$notify("Something went wrong!", "Error", "danger");
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally(() => {
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
    getSelectedBank(bank) {
      if (bank) {
        this.form = {
          bank_account: bank.bank_account_full,
          bank_account_type: bank.account_type ? bank.account_type : "checking",
          bank_accountname: bank.bank_accountname,
          bank_name: bank.bank_name,
          bank_routing: bank.bank_routing,
        };
      } else {
        this.form = {
          bank_account: "",
          bank_account_type: "",
          bank_accountname: "",
          bank_name: "",
          bank_routing: "",
        };
      }
    },
    setData() {
      this.stepTitle = this.step == 2 ? "Pay Now" : "Next";
      this.cancelTitle = this.step == 2 ? "Back" : "Cancel";
      this.nextStep = this.step == 2 ? false : true;
    },
    cancel() {
      if (this.step == 2) {
        this.step = 1;
        this.setData();
      } else {
        this.$bvModal.hide("paystand_dialog");
        this.$store.commit("setInvoiceIds", []);
      }
    },
    paymentStep() {
      this.step = 2;
      if (this.stepTitle == "Pay Now") {
        this.payNow();
      }
      this.setData();
    },
  },
  created() {
    //setting invoiceIds at first empty
    this.$store.commit("setInvoiceIds", []);
  },
  watch: {
    invoiceIds: function(val) {
      if (val.length > 0) {
        this.nextStep = true;
      } else {
        this.nextStep = false;
      }
    },
    form: {
      handler: function(val) {
        if (
          val.bank_account &&
          val.bank_account_type &&
          val.bank_accountname &&
          val.bank_name &&
          val.bank_routing
        ) {
          this.nextStep = true;
        } else {
          this.nextStep = false;
        }
      },
      deep: true,
    },
  },
};
</script>
<style>
.modal {
  position: fixed !important;
}
#paystand_dialog .modal-title,
#next_dialog .modal-title {
  font-size: 20px !important;
  font-weight: bold !important;
  /* line-height:unset !important; */
}
#paystand_dialog .btn.btn-primary,
#next_dialog .btn.btn-primary {
  background-image: linear-gradient(
    #00ccff 0%,
    #1597b8 49%,
    #0567bc 100%
  ) !important;
  border: 0;
  padding: 5px 25px !important;
}
#paystand_dialog .btn.btn-primary:hover,
#next_dialog .btn.btn-primary:hover {
  background-image: linear-gradient(
    #146479 0%,
    #066bc4 100%,
    #2fa3c0 60%
  ) !important;
}
#paystand_dialog .btn.btn-secondary,
#next_dialog .btn.btn-secondary {
  padding: 5px 25px !important;
}
#next_dialog .modal-body {
  padding-bottom: 0;
}
</style>
