<template>
  <b-form-group :class="formGroupClass">
    <div class="upload_section">
      <validation-provider
        :name="labelName"
        :rules="rules"
        v-slot="validationContext"
      >
        <label :id="`label-${labelFor}`" :for="labelFor">
          <span v-html="computedLabel" />
        </label>
        <b-form-file
          id="upload_image"
          browse-text="Choose File"
          accept="image/*"
          :state="getValidationState(validationContext)"
          @change="fileHandler"
          v-model="computedInputData"
          :readonly="readonly"
          :placeholder="computedPlaceholder"
        >
        </b-form-file>
        <b-form-invalid-feedback>
          {{ validationContext.errors[0] }}
        </b-form-invalid-feedback>
      </validation-provider>
    </div>
    <div class="uploaded">
      <img :src="imageSrc" />
    </div>
  </b-form-group>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import imageSrc from "@/assets/images/upload-dummy.png";
export default {
  props: {
    imgSrc: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    vmodel: {
      required: true,
      default: null,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
  },
  components: {
    ValidationProvider,
  },
  data: () => {
    return {
      imageSrc: "",
      file: null,
    };
  },
  computed: {
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `No File Choosen`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function() {
        return this.vmodel;
      },
      set: function() {
        this.$emit("update:vmodel", this.file ? this.file : null);
      },
    },
  },
  methods: {
    fileHandler(e) {
      const file = e.target.files[0];
      this.file = file;
      this.imageSrc = URL.createObjectURL(file);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
  created() {
    this.imageSrc = this.imgSrc ? this.imgSrc : imageSrc;
  },
};
</script>
<style>
.input-required {
  color: red !important;
}
</style>
