<template>
    <div v-if="errors && errorField" class="text-danger">
        <small>{{ errorField[0]}}</small>
    </div>
</template>

<script>
    export default {
        name: "ErrorText",
        props: {
            errors: {
                required: true,
            },
            field: {
                required: true,
                default: ''
            }
        },
        computed: {
            errorField() {
                return this.errors[this.field] ? this.errors[this.field] : this.errors
            }
        }
    }
</script>

<style scoped>

</style>