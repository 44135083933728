<template>
  <div class="support_information">
    <div class="title">
      <h3>Support Information</h3>
      <p>Update Support Details of Group</p>
    </div>
    <ValidationObserver ref="supportInfo" v-slot="{ handleSubmit }" slim>
      <b-form
        @submit.stop.prevent="handleSubmit(updateSupportInfo)"
        class="update-support-form"
        ref="supportInfoContainer"
      >
        <input-text
          :rules="{ required: true }"
          labelFor="support_fname"
          labelName="First Name"
          inputName="support_fname"
          :vmodel.sync="form.support_fname"
        />

        <input-text
          :rules="{ required: true }"
          labelFor="support_lname"
          labelName="Last Name"
          inputName="support_lname"
          :vmodel.sync="form.support_lname"
        />
        <!-- 
        <input-text
          :rules="{ required: true, numeric: true, digits: 10 }"
          labelFor="support_phone1"
          labelName="Support Phone 1"
          inputName="support_phone1"
          :vmodel.sync="form.support_phone1"
          inputType="number"
        /> -->

        <b-form-group>
          <validation-provider
            name="Support Phone 1"
            :rules="{ required: true, numeric: true, digits: 10 }"
            v-slot="validationContext"
          >
            <label
              id="label-support-phone-number-1"
              for="label-support-phone-number-1"
            >
              Support Phone 1 <span style="color:Red">*</span>
            </label>
            <b-form-input
              id="support-phone-number-1"
              type="number"
              :state="getValidationState(validationContext)"
              aria-describedby="input-feedback"
              v-model="form.support_phone1"
              placeholder="Enter Support Phone 1"
              @input="checkClearoutPhone('support_phone1')"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-feedback support-phone-number-1-feedback"
              class="support-phone-number-1-feedback"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            name="Support Phone 2"
            :rules="{ required: true, numeric: true, digits: 10 }"
            v-slot="validationContext"
          >
            <label
              id="label-support-phone-number-2"
              for="label-support-phone-number-2"
            >
              Support Phone 2
            </label>
            <b-form-input
              id="support-phone-number-2"
              type="number"
              :state="getValidationState(validationContext)"
              aria-describedby="input-feedback"
              v-model="form.support_phone2"
              placeholder="Enter Support Phone 2"
              @input="checkClearoutPhone('support_phone2')"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-feedback support-phone-number-2-feedback"
              class="support-phone-number-2-feedback"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <!-- <input-text
          :rules="{ required: false, numeric: true, digits: 10 }"
          labelFor="support_phone2"
          labelName="Support Phone 2"
          inputName="support_phone2"
          :vmodel.sync="form.support_phone2"
          inputType="number"
        /> -->

        <input-text
          :rules="{ required: false, digits: 10 }"
          labelFor="support_800"
          labelName="Support Toll Free"
          inputName="support_800"
          :vmodel.sync="form.support_800"
        />

        <input-text
          :rules="{ required: false, numeric: true, digits: 10 }"
          labelFor="support_fax"
          labelName="Support Fax"
          inputName="support_fax"
          :vmodel.sync="form.support_fax"
          inputType="number"
        />

        <input-text
          :rules="{ required: true }"
          labelFor="support_email"
          labelName="Support Email"
          inputName="support_email"
          :vmodel.sync="form.support_email"
          inputType="email"
        />

        <b-form-group
          id="input-group-8"
          label="Support Image"
          label-for="support_image"
        >
          <div class="upload_section">
            <validation-provider
              name="Support Image"
              :rules="{ size: 2048 }"
              v-slot="validationContext"
            >
              <b-form-file
                id="support_image"
                browse-text="Choose File"
                accept="image/*"
                :state="getValidationState(validationContext)"
                @change="fileHandler"
                v-model="supportImage"
              >
              </b-form-file>
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </div>
          <div class="uploaded">
            <img :src="supportImgSrc" />
          </div>
        </b-form-group>

        <b-form-group class="save_info">
          <button :disabled="submit">
            {{ submit ? "Processing..." : "Update" }}
          </button>
        </b-form-group>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import dummyImage from "@/assets/images/upload-dummy.png";
import CommonValidator from "@/resource/CommonValidator";

export default {
  data: () => {
    return {
      form: {
        support_fname: "",
        support_lname: "",
        support_phone1: "",
        support_phone2: "",
        support_800: "",
        support_fax: "",
        support_email: "",
      },
      supportImage: null,
      supportImgSrc: "",
      submit: false,
      canSubmit: true,
    };
  },
  methods: {
    async checkClearoutPhone(phoneType) {
      let phoneNumber, inputId, feedBackInputClass;
      switch (phoneType) {
        case "support_phone1":
          phoneNumber = this.form.support_phone1;
          inputId = "#support-phone-number-1";
          feedBackInputClass = ".support-phone-number-1-feedback";
          break;
        case "support_phone2":
          phoneNumber = this.form.support_phone2;
          inputId = "#support-phone-number-2";
          feedBackInputClass = ".support-phone-number-2-feedback";
          break;
        default:
          phoneNumber = "";
          inputId = "";
          feedBackInputClass = "";
      }

      const check = await CommonValidator.validatePhoneNumberWithOptions(
        phoneNumber,
        inputId,
        feedBackInputClass
      );
      this.canSubmit = check;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    fileHandler(e) {
      const file = e.target.files[0];
      this.supportImage = file;
      this.supportImgSrc = URL.createObjectURL(file);
    },
    getSupportInfo() {
      let data = this.$store.getters.groupInfo;
      this.form = {
        support_fname: data.support_fname ? data.support_fname : "",
        support_lname: data.support_lname ? data.support_lname : "",
        support_phone1: data.support_phone1 ? data.support_phone1 : "",
        support_phone2: data.support_phone2 ? data.support_phone2 : "",
        support_800: data.support_800 ? data.support_800 : "",
        support_fax: data.support_fax ? data.support_fax : "",
        support_email: data.support_email ? data.support_email : "",
      };
      this.supportImgSrc = this.$store.getters.groupInfo.support_pic
        ? this.$store.getters.groupInfo.support_pic
        : dummyImage;
    },
    async updateSupportInfo() {
      console.log("canSubmit",this.canSubmit)
      if (this.canSubmit) {
        let url = "update-support-info";
        this.submit = true;
        let formData = new FormData();
        let loader = this.$loading.show({
          container: this.$refs.supportInfoContainer,
        });
        formData.append("support_pic", this.supportImage);
        for (const [key, value] of Object.entries(this.form)) {
          if (value) {
            formData.append(key, value);
          }
        }
        Api.post(url, formData)
          .then((res) => {
            let response = res.data.message;
            this.$notify(response);
          })
          .catch((err) => {
            this.$notify(err.response.data.message, "Error", "danger");
          })
          .finally(() => {
            this.$store.dispatch("getGroupInfo");
            setTimeout(() => {
              loader.hide();
              this.submit = false;
            }, 2000);
          });
      }
    },
  },
  created() {
    this.$store.dispatch("getGroupInfo");
    this.getSupportInfo();
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/account_information.css"
  scoped
></style>
