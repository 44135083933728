var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper_group_dashboard"},[_c('div',{staticClass:"group_dashboard"},[_c('div',{staticClass:"admin_message_wrapper"},[_c('breadcrumb',{staticClass:"received-messages-breadcrumb",attrs:{"items":_vm.breadcrumbItems}}),_c('div',{staticClass:"all_messages"},[_c('div',{staticClass:"messages"},[_vm._m(0),_c('div',{staticClass:"column"},[_vm._l((_vm.receivedMessages.records),function(item,index){return _c('div',{key:index,staticClass:"row",class:_vm.showMessage
                  ? _vm.showMessage.mid == item.mid
                    ? 'active_background'
                    : ''
                  : '',on:{"click":function($event){return _vm.showCurrentMessage(item)}}},[_c('div',{staticClass:"message_information"},[_c('img',{attrs:{"src":require("@/assets/images/chatting.svg"),"alt":"chatting"}}),_c('div',[_c('span',{staticClass:"message_title"},[_vm._v(" "+_vm._s(item.sender_name)+" ")]),_c('br'),_c('span',{staticClass:"message_subject"},[_vm._v(" "+_vm._s(_vm._f("truncate")(item.subject,25))+" ")])])]),_c('div',[_c('span',{staticClass:"message_opens"},[_vm._v(" "+_vm._s(item.from)+" ")]),_c('br'),_c('span',{staticClass:"message_date"},[_vm._v(" "+_vm._s(_vm.formattedDate(item.date_created))+" ")])])])}),_c('small',[_c('pagination',{attrs:{"rows":_vm.receivedMessages,"filters":[]},on:{"update:rows":function($event){_vm.receivedMessages=$event}}})],1)],2)]),_c('div',{staticClass:"main_messages_container"},[(_vm.showMessage)?_c('div',{staticClass:"message_description_container"},[_c('div',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.formattedDetailDate)+" ")]),_c('div',{staticClass:"message_description"},[_c('div',{staticClass:"delete-message"},[_c('a',{attrs:{"href":"javscript:void(0);"},on:{"click":function($event){return _vm.deleteMessage(_vm.showMessage.mid)}}},[_c('i',{staticClass:"fa fa-trash"})])]),_c('div',{staticClass:"arrow-left"}),_c('div',{staticClass:"message_title"},[_c('span',[_vm._v("From: "+_vm._s(_vm.showMessage.sender_name))]),_c('span',[_vm._v(_vm._s(_vm.showMessage.from))])]),_c('h2',[_vm._v(_vm._s(_vm.showMessage.subject))]),_c('div',{staticClass:"message_content"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.showMessage.message)}}),_c('br'),(
                    _vm.showMessage.attachments &&
                      _vm.showMessage.attachments.length > 0
                  )?_c('div',[_c('h6',[_vm._v("Attachments:")]),_c('ul',{staticStyle:{"list-style":"none"}},_vm._l((_vm.showMessage.attachments),function(file){return _c('li',{key:file.id},[_c('a',{attrs:{"href":file.file_url,"target":"__blank","download":""}},[_c('i',{staticClass:"fa fa-file"}),_vm._v(" "+_vm._s(file.title)+" ")])])}),0)]):_vm._e()])])]):_vm._e()])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('h2',[_vm._v("Messages")])])}]

export { render, staticRenderFns }