/* eslint-disable */

import axios from "axios";
import Vue from "vue";
import Endpoint from "../resource/EndPoints/Endppoint.vue";

const otherUrl = axios.create({
  baseURL: "",
});

export default class Validator {
  static async validateRoutingNumber(number) {
    let data = await otherUrl.get(Endpoint.VALIDATE_ROUTING_NUMBER + number);
    if (data.data.code == "200") {
      return Validator.messageGenerate(true, "success", data.data.name);
    }
    return Validator.messageGenerate(false, "Invalid routing number");
  }

  static async validatePhoneNumber(number) {
    return await otherUrl
      .get(Endpoint.VALIDATE_PHONE_NUMBER + number)
      .then((response) => {
        return Validator.messageGenerate(
          true,
          response.data.message,
          response.data.data.result.line_type
        );
      })
      .catch((error) => {

        return Validator.messageGenerate(
          false,
          error.response.data.data[0].error_message
        );
      });
  }

  static async validateSSN(number) {
    return await otherUrl
      .post(Endpoint.VALIDATE_SSN, { ssn: number })
      .then((response) => {
        return Validator.messageGenerate(true, "success", "Valid SSN");
      })
      .catch((error) => {
        return Validator.messageGenerate(false, "Invalid SSN");
      });
  }

  static async validateDate(date) {
    try {
      let dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
      if (date.match(dateformat)[0] == date) {
        return Validator.messageGenerate(true, "success", "Valid SSN");
      }
      throw "error";
    } catch {
      return Validator.messageGenerate(false, "Invalid Date");
    }
  }

  static messageGenerate(status, message, data = "") {
    return { status: status, message: message, data: data };
  }

  static async validatePhoneNumberWithOptions(number, inputId, feedBackInputClass) {
    let pattern, app, result
    app = new Vue()
    pattern = new RegExp("^\\d{10}$");
    if (number.match(pattern)) {
      const validator = await this.validatePhoneNumber(number);
      result = Promise.resolve(validator).then((res) => {
        let status, message;
        status = res.status;
        message = res.message;
        if (status) {
          document.querySelector(inputId).classList.remove("is-invalid");
          document.querySelector(inputId).classList.add("is-valid");
          return status
        } else {
          app.$notify(message, "Error", "danger");
          document.querySelector(inputId).classList.remove("is-valid");
          document.querySelector(inputId).classList.add("is-invalid");
          document.querySelector(feedBackInputClass).innerHTML = message;
          return status
        }
      });
    }
    return result
  }
  static async validatePhoneNumberCustom(number) {
    let pattern, app, result
    app = new Vue()
    pattern = new RegExp("^\\d{10}$");
    if (number.match(pattern)) {
      const validator = await this.validatePhoneNumber(number);
      result = Promise.resolve(validator).then((res) => {
        return res.status
      });
    }
    return result
  }

  static async validateEmail(email) {
    return await otherUrl
    .post(Endpoint.VALIDATE_EMAIL, { email: email })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      return false;
    });
  }
}
