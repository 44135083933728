<template>
  <b-modal
    v-if="data"
    id="file-detail"
    centered
    size="lg"
    hide-footer
    hide-header
  >
    <close-modal-icon modalId="file-detail" />
    <div>
      <h4>{{ data.file_title }}</h4>
      <p v-html="data.doc_notes" />
      <a :href="data.doc_url" download class="btn btn-primary pull-right">
        Download
      </a>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: "FileDetail",
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>
