import { render, staticRenderFns } from "./ErrorText.vue?vue&type=template&id=71d020da&scoped=true&"
import script from "./ErrorText.vue?vue&type=script&lang=js&"
export * from "./ErrorText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d020da",
  null
  
)

export default component.exports