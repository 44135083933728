<template>
  <div class="group_profile">
    <div class="profile_detail">
      PHONE NUMBER<br />
      <span>
        {{ phoneNumber }}
        <template v-if="groupInfo.is_phone1_valid">
          <img v-tooltip="'Verified'"  src="@/assets/images/check.svg" width="12px" alt="valid" />
        </template>
        <template v-else>
          <img v-tooltip="'Not Verified'" src="@/assets/images/inactive.svg" width="12px" alt="invalid" />
        </template>
      </span>
    </div>
    <div class="profile_detail right_text">
      NO OF ACTIVE PLANS<br />
      <span>{{ activePlans }}</span>
    </div>
    <div class="profile_image">
      <div class="profile_edit" v-tooltip="'Edit Logo'">
        <img v-b-modal.modal-upload src="@/assets/images/group_info_edit.svg" />
      </div>
      <div class="profile_imageLogo">
        <img :src="groupInfo.group_logo"/>
      </div>
    </div>
    <!-- logo upload guideline modal -->
    <div class="profile_detail cust-62">
      EMAIL<br />
      <span>
        {{ groupInfo.group_email }}
        <template v-if="groupInfo.is_email_valid">
          <img v-tooltip="'Verified'" src="@/assets/images/check.svg" width="12px" alt="valid" />
        </template>
        <template v-tooltip="'Not Verified'" v-else>
          <img src="@/assets/images/inactive.svg" width="12px" alt="invalid" />
        </template>
      </span>
    </div>
    <div class="profile_detail cust-38 right_text">
      NO OF EMPLOYEES<br />
      <span>
        {{ groupInfo.employee_number }}
      </span>
    </div>
    <UploadGroupInfoLogo />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UploadGroupInfoLogo from "./UploadGroupInfoLogo";
import Helper from "@/resource/Helper";
export default {
  components: {
    UploadGroupInfoLogo,
  },
  computed: {
    ...mapGetters({
      groupInfo: "groupInfo",
      activePlans: "groupInfoActivePlans",
    }),
    phoneNumber() {
      return Helper.formatPhoneNumber(this.groupInfo.group_phone);
    },
  },
};
</script>

<style src="@/assets/css/group_dashboard/group_information.css" scoped></style>
