<template>
  <div class="main_messages_container">
    <div class="message_description_container" v-if="message">
      <div class="comment-action">
          <b-button type="button" id="ticket-status-action"><i class="fas fa-ellipsis-v"></i></b-button>
          <b-popover hide target="ticket-status-action">
            <a href="#"><i class="fas fa-circle"></i>&nbsp;&nbsp;Close Ticket</a>
            <!-- <a href="#"><i class="fas fa-circle open"></i>&nbsp;&nbsp;Open Ticket</a> -->
          </b-popover>
            
        </div>
      <div class="date amc-date">
        {{ formattedDetailDate }}
      </div>
      <div class="message_description">
        <div class="arrow-left"></div>
        <h2>
          {{ message.subject }}
        </h2>
        <div class="message_content">
          <p v-html="message.message" />
          <br />
          <div>
            <h4 v-if="message.attachments.length > 0">
              Attachments:
            </h4>
            <ul style="list-style:none">
              <li v-for="file in message.attachments" :key="file.id">
                <a :href="file.file_url" target="__blank" download>
                  <i class="fa fa-file"></i>
                  {{ file.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="ticket-comment-container">
      <div class="ticket-comment">
        <div class="comment-list">
          <img src="#" alt="">
          <div class="comment-body">
            <h4>Suraj Kunwar</h4>
            <p>Hello, I have updated your phone number please try again now.</p>
            <p>Thanks!</p>
          </div>
          <span class="date">6th July, 2021</span>
          <div class="comment-action">
            <b-button type="button" id="popover-button-open"><i class="fas fa-ellipsis-v"></i></b-button>
            <b-popover hide target="popover-button-open">
              <a href="#" >
                <i class="far fa-trash-alt" aria-hidden="true"></i> Delete comment
              </a>
            </b-popover>
            
          </div>
        </div>

        <div class="comment-form">
          <b-form-group
            label="Comment"
            label-for="textarea-formatter"
            class="mb-0"
          >
          <b-form-textarea
            id="textarea-formatter"
            v-model="text1"
            placeholder="Write a comment"
            :formatter="formatter"
          ></b-form-textarea>
          <div class="attach-file">
            <label class="btn btn-default">
              <input type="file" hidden>
              <i class="fas fa-paperclip"></i>
            </label>
          </div>
          </b-form-group>
          <div class="text-right">
            <button type="submit" class="comment-submit">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios"
export default {
  name: "TicketingDetails",
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDetailDate() {
      let date = this.message.date_created;
      if (moment(date, "MM/DD/YYYY HH:mm:ss", true).isValid()) {
        return moment(date, "MM/DD/YYYY HH:mm:ss", true).format("gg MMM , ddd");
      }
      return "N/A";
    },
  },
  methods: {
    deleteMessage(messageId) {
      let url = process.env.VUE_APP_API_URL_V2 +`delete-message?mid=${messageId}`;
      this.$store.commit("setDialogClass", "group-app-warning-dialog");
      this.$confirm({
        title: "Delete Message",
        message: `Do you want to delete this message?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            axios
              .delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$emit("sentMessageList");
                this.$notify(response);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  }
};
</script>
<style src="@/assets/css/group_dashboard/all_messages.css" scoped></style>
<style scoped>
.comment-list {
  background: #dce5ec94;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  margin-bottom: 30px;
}
.comment-list::after {
  content: '';
  position: absolute;
  right: -10px;
  top: 30px;
  width: 0;
	height: 0;
	border-top: 8px solid transparent;
	border-left: 10px solid #dce5ec94;
	border-bottom: 8px solid transparent;
}
.comment-list img {
  width: 40px;
  height: 40px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  background: #DDDDDD;
}
.comment-body {margin-left: 55px;}
.comment-body h4 {
  font-size: 14px;
  font-weight: bold;
  color: #323C47;
  margin-bottom: 12px;
  margin-top: -35px;
}
.comment-body p {
  color: #707070;
  line-height: 1.7;
  font-size: 13px;
  margin-top: 8px;
}
.comment-list .date {
  position: absolute;
  top: 20px;
  right: 40px;
  font-weight: 500;
  font-size: 12px;
  color: #ABACAD;
}
.comment-action {
  position: absolute;
  top: 6px;
  padding: 6px;
  right: 0px;
}
.comment-action a {
  color: #ABACAD;
  font-size: 14px;
}
.btn-secondary {
  background: transparent!important;
  color: #ABACAD;
  border: none;
}
.btn-secondary:hover,.btn-secondary:focus-within,.btn-secondary:focus {
  color: #ABACAD;
  box-shadow: none;
}
.popover-body a {
  color: #323C47;
  font-size: 14px;
}
.bs-popover-left .arrow {
  display: none!important;
}
.popover-body a:hover {
  text-decoration: none;
}
.popover-body a {
  font-weight: 500;
  color: #707070;
}
.popover-body svg {
  color: #EA584E;
  height: 16px;
  width: 16px;
  vertical-align: text-bottom;
}
.popover-body svg.open {
  color: #3BB54A;
  height: 16px;
  width: 16px;
  vertical-align: text-bottom;
}
.comment-submit  {
  background-image: linear-gradient( #00CCFF 0%, #1597B8 49%, #0567BC 100%)!important;
  color: #fff;
  border: 1px solid #03c3ec;
  display: inline-block;
  padding: 8px 40px;
  text-align: center;
  border-radius: 60px;
  font-size: 14px;
  margin-top: 15px;
}
.comment-form textarea {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #DDDDDD;
  border-bottom: none;
  min-height: 100px;
}
.attach-file {
  padding: 3px;
  position: relative;
  background: #dce5ec94;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.attach-file label {
  margin-bottom: 0;
  height: 35px;
  width: 37px;
  border-radius: 50px;
  margin-left: 3px;
}
.comment-form svg {
  width: 18px;
  height: 18px;
  margin-left: -4px;
  margin-top: -4px;
  color: #707070;
}
.main_messages_container {
  padding-bottom: 80px;
}
</style>
