<template>
  
      <div class="all_messages">
        <div class="messages">
          <div class="column">
            <div
              v-for="(item, index) in rows.records"
              :key="index"
              @click="showMessage = item"
              class="row"
              :class="showMessage ? (showMessage.id == item.id ? 'active_background' : '') : ''"
            >
              <div class="message_information">
                <i
                  style="font-size:25px;color:#29BCFF;margin-right:10px;"
                  class="fas fa-newspaper"
                ></i>
                <div>
                  <span class="message_title">
                    {{ item.title | truncate(30) }}</span
                  >
                  <br />
                  <span class="message_subject">
                    {{ item.published_date }}
                  </span>
                </div>
              </div>
              <div>
                <br />
                <!-- <span class="message_date">{{ item.date }}</span> -->
              </div>
            </div>
           <pagination :rows.sync="rows" :showPageRecord="false" />
          </div> 
          
        </div>

        <div class="main_messages_container">
          <div class="message_description_container" v-if="showMessage">
            <div class="date">{{ formattedDate }}</div>
            <div class="message_description">
              <!-- <div class="arrow-left"></div> -->
              <!-- <div class="message_title message_title_rep"> -->
                <!-- <span>Rep: {{showMessage.count }}</span>
                <span>Open: {{showMessage.open }}</span> -->
              <!-- </div> -->
              <h2 class="show-message">{{ showMessage.title }}</h2>
              <div class="message_content">
                <p v-html="showMessage.description"></p>
                <br />
                <div class="pt-3" v-if="showMessage.files && showMessage.files.length > 0">
                  <h6>Attachments:</h6>
                  <ul class="pl-4" style="list-style:none">
                    <li class="mb-2" v-for="file in showMessage.files" :key="file.id">
                      <a
                        :href="file.path"
                        target="__blank"
                        download
                            class="document-link"
                      >
                        <i class="fa fa-file"></i>
                        {{ file.filename }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>
import moment from "moment";
import { apiBaseUrlV2 } from "@/resource/EndPoints/endpoints";
import axios from "axios";
import Api from "@/resource/Api";
export default {
  name: "KnowledgeCenterListRep",
  data: () => {
    return {
      rows: [],
      showMessage: null,
      groupKnowledges: [],
      breadcrumbItems: [{ title: "Knowledge Center", active: true }],
    };
  },
  methods: {
   getKnowledges() {
    
       let loader = this.$loading.show();
       let url = `${apiBaseUrlV2}knowledge-feed/list`;
       axios
        .get(url)
        .then((res) => {
          this.rows = res.data;
          this.groupKnowledges = res.data.records;
          this.showMessage = this.groupKnowledges[0];
          this.currentItemId = this.showMessage.id
        })
        .catch(() => {
          this.groupKnowledges = [];
          this.showMessage = null;
          this.currentItemId = null
        })
        .finally(() => {
          loader.hide();
          if (this.groupKnowledges.length < 1) {
           /* this.$router.push({ name: "Dashboard" });*/
          }
        });
    },
  },
  computed: {
    formattedDate() {
      return moment(this.showMessage.published_date).format("gg MMM , ddd");
    },
  },
  mounted() {
    this.getKnowledges();
  },
};
</script>
<style scoped>
@import '../../assets/css/group_dashboard/all_messages.css';
</style>
