<template>
<div>
    <span class="password_info">Al least 6 Characters: One Uppercase Letter, One Lowercase Letter, One Number
      and One Special Character</span>
  <div class="indicator">
    <span style="display:none">{{ password }}</span>
    <span :class="[(passwordScore>= 1 && passwordScore<= 64) ? 'red-text':(passwordScore>= 65) ? 'green-text':'']" class="very weak"></span>
    <span :class="[(passwordScore>= 20 && passwordScore<= 64) ? 'red-text':(passwordScore>= 65) ? 'green-text':'']" class="weak"></span>
    <span  :class="[(passwordScore>= 40 && passwordScore<= 64) ? 'red-text':(passwordScore>= 65) ? 'green-text':'']" class="medium"></span>
    <span :class="[(passwordScore>= 65) ? 'green-text':'']" class="strong"></span>
    <span :class="[(passwordScore>= 80) ? 'green-text':'']" class="very strong"></span>
  </div>
  <p class="strength_message" :class="[(passwordScore>= 65) ? 'strong_password':'weak_password']">{{scoreStrengthText}}</p>
  </div>
</template>
<script>
/*eslint-disable */
export default {
  name: "PasswordStrength",
  props: { password: String },
  data() {
    return {
        passwordScore: 0,
        scoreStrengthText : ''
    }
  },
  mounted() {
    String.prototype.strReverse = function() {
      let newstring = "";
      for (var s = 0; s < this.length; s++) {
        newstring = this.charAt(s) + newstring;
      }
      return newstring;
    };
  },
  updated() {
    let score = this.checkPassword(this.password);
     this.passwordScore = score;
    //  console.log("password",this.password)
    //  console.log('score',score)
    if (score >= 1 && score <= 19) {
      this.scoreStrengthText = "Very Weak";
    } else if (score >= 20 && score <= 39) {
      this.scoreStrengthText = "Weak";
    } else if (score >= 40 && score <= 64) {
      this.scoreStrengthText = "Medium";
    } else if (score >= 65 && score <= 79) {
      this.scoreStrengthText = "Strong";
    } else if (score >= 80) {
      this.scoreStrengthText = "Very Strong";
    } else {
       this.scoreStrengthText = "";
    }
    this.$emit('updateScore',score)
  },
  methods: {
    checkPassword() {
      var pwd = this.password;
      var nScore = 0;
      var nLength = 0;
      var nAlphaUC = 0;
      var nAlphaLC = 0;
      var nNumber = 0;
      var nSymbol = 0;
      var nMidChar = 0;
      var nRequirements = 0;
      var nAlphasOnly = 0;
      var nNumbersOnly = 0;
      var nRepChar = 0;
      var nConsecAlphaUC = 0;
      var nConsecAlphaLC = 0;
      var nConsecNumber = 0;
      var nConsecSymbol = 0;
      var nConsecCharType = 0;
      var nSeqAlpha = 0;
      var nSeqNumber = 0;
      var nSeqChar = 0;
      var nMultLength = 4;
      var nMultAlphaUC = 2;
      var nMultAlphaLC = 2;
      var nMultNumber = 4;
      var nMultSymbol = 6;
      var nMultMidChar = 2;
      var nMultRequirements = 2;
      var nMultConsecAlphaUC = 2;
      var nMultConsecAlphaLC = 2;
      var nMultConsecNumber = 2;
      var nMultSeqAlpha = 3;
      var nMultSeqNumber = 3;
      var nTmpAlphaUC = "";
      var nTmpAlphaLC = "";
      var nTmpNumber = "";
      var nTmpSymbol = "";
      var sAlphas = "abcdefghijklmnopqrstuvwxyz";
      var sNumerics = "01234567890";
      /* var sComplexity = saStrength[index.TOOSHORT]; */
      var sColor = "#eee";
      var nMinPwdLen = 6;
      if (pwd) {
        nScore = parseInt(pwd.length * nMultLength);
        nLength = pwd.length;
        var arrPwd = pwd.replace(/\s+/g, "").split(/\s*/);
        var arrPwdLen = arrPwd.length;
        // console.log("pwdLenght",arrPwdLen)
        /* Loop through password to check for Symbol, Numeric, Lowercase and Uppercase pattern matches */
        for (var a = 0; a < arrPwdLen; a++) {
          if (arrPwd[a].match(new RegExp(/[A-Z]/g))) {
            if (nTmpAlphaUC !== "") {
              if (nTmpAlphaUC + 1 == a) {
                nConsecAlphaUC++;
                nConsecCharType++;
              }
            }
            nTmpAlphaUC = a;
            nAlphaUC++;
          } else if (arrPwd[a].match(new RegExp(/[a-z]/g))) {
            if (nTmpAlphaLC !== "") {
              if (nTmpAlphaLC + 1 == a) {
                nConsecAlphaLC++;
                nConsecCharType++;
              }
            }
            nTmpAlphaLC = a;
            nAlphaLC++;
          } else if (arrPwd[a].match(new RegExp(/[0-9]/g))) {
            if (a > 0 && a < arrPwdLen - 1) {
              nMidChar++;
            }
            if (nTmpNumber !== "") {
              if (nTmpNumber + 1 == a) {
                nConsecNumber++;
                nConsecCharType++;
              }
            }
            nTmpNumber = a;
            nNumber++;
          } else if (arrPwd[a].match(new RegExp(/[^a-zA-Z0-9_]/g))) {
            if (a > 0 && a < arrPwdLen - 1) {
              nMidChar++;
            }

            if (nTmpSymbol !== "") {
              if (nTmpSymbol + 1 == a) {
                nConsecSymbol++;
                nConsecCharType++;
              }
            }
            nTmpSymbol = a;
            nSymbol++;
          }

          /* Internal loop through password to check for repeated characters */
          for (var b = 0; b < arrPwdLen; b++) {
            if (arrPwd[a].toLowerCase() == arrPwd[b].toLowerCase() && a != b) {
              nRepChar++;
            }
          }
        }

        /* Check for sequential alpha string patterns (forward and reverse) */
        for (var s = 0; s < 23; s++) {
          var sFwd = sAlphas.substring(s, parseInt(s + 3));
          var sRev = sFwd.strReverse();
          if (
            pwd.toLowerCase().indexOf(sFwd) != -1 ||
            pwd.toLowerCase().indexOf(sRev) != -1
          ) {
            nSeqAlpha++;
            nSeqChar++;
          }
        }

        /* Check for sequential numeric string patterns (forward and reverse) */
        for (var s = 0; s < 8; s++) {
          var sFwd = sNumerics.substring(s, parseInt(s + 3));
          var sRev = sFwd.strReverse();
          if (
            pwd.toLowerCase().indexOf(sFwd) != -1 ||
            pwd.toLowerCase().indexOf(sRev) != -1
          ) {
            nSeqNumber++;
            nSeqChar++;
          }
        }

        /* Modify overall score value based on usage vs requirements */

        /* General point assignment */
        if (nAlphaUC > 0 && nAlphaUC < nLength) {
          nScore = parseInt(nScore + (nLength - nAlphaUC) * nMultAlphaUC);
        }
        if (nAlphaLC > 0 && nAlphaLC < nLength) {
          nScore = parseInt(nScore + (nLength - nAlphaLC) * nMultAlphaLC);
        }
        if (nNumber > 0 && nNumber < nLength) {
          nScore = parseInt(nScore + nNumber * nMultNumber);
        }
        if (nSymbol > 0) {
          nScore = parseInt(nScore + nSymbol * nMultSymbol);
        }
        if (nMidChar > 0) {
          nScore = parseInt(nScore + nMidChar * nMultMidChar);
        }

        /* Point deductions for poor practices */
        if ((nAlphaLC > 0 || nAlphaUC > 0) && nSymbol === 0 && nNumber === 0) {
          // Only Letters
          nAlphasOnly = nLength;
          nScore = parseInt(nScore - nAlphasOnly);
        }
        if (nAlphaLC === 0 && nAlphaUC === 0 && nSymbol === 0 && nNumber > 0) {
          // Only Numbers
          nNumbersOnly = nLength;
          nScore = parseInt(nScore - nNumbersOnly);
        }
        if (nRepChar > 0) {
          // Same character exists more than once
          nScore = parseInt(nScore - nRepChar);
        }
        if (nConsecAlphaUC > 0) {
          // Consecutive Uppercase Letters exist
          nScore = parseInt(nScore - nConsecAlphaUC * nMultConsecAlphaUC);
        }
        if (nConsecAlphaLC > 0) {
          // Consecutive Lowercase Letters exist
          nScore = parseInt(nScore - nConsecAlphaLC * nMultConsecAlphaLC);
        }
        if (nConsecNumber > 0) {
          // Consecutive Numbers exist
          nScore = parseInt(nScore - nConsecNumber * nMultConsecNumber);
        }
        if (nSeqAlpha > 0) {
          // Sequential alpha strings exist (3 characters or more)
          nScore = parseInt(nScore - nSeqAlpha * nMultSeqAlpha);
        }
        if (nSeqNumber > 0) {
          // Sequential numeric strings exist (3 characters or more)
          nScore = parseInt(nScore - nSeqNumber * nMultSeqNumber);
        }

        /* Determine if mandatory requirements have been met */
        if (nLength >= nMinPwdLen) {
          nRequirements = 0;
          var nMinReqChars = 4;
          if (nAlphaUC >= 1) {
            nRequirements++;
          }
          if (nAlphaLC >= 1) {
            nRequirements++;
          }
          if (nNumber >= 1) {
            nRequirements++;
          }
          if (nSymbol >= 1) {
            nRequirements++;
          }
          // console.log("nScore",nScore)
       
          if (nRequirements >= nMinReqChars) {
            nScore = parseInt(nScore + nRequirements * nMultRequirements);
          }else{
            if(nScore > 64){
              nScore = nScore -(nRequirements*10)
            }
          }

        }
        //again determining the password strend if upperCase,lowerCase , minLength ,Symbol and Number
        return nScore;
      //document.querySelector('.score').innerText = nScore;
      }
    },
  },
};
</script>

<style scoped>
.indicator {
  height: 4px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* display: none; */
}
.indicator span {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 52px;
  background: lightgrey;
  border-radius: 5px;
}

.indicator span.active:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.green-text {
  background: #009e68 !important;
}
.red-text {
  background: #f04d4d !important
}
.strength_message {
  font-size: 13px;
}
.strong_password {
  color: #009e68 !important;
}
.weak_password {
  color: #f04d4d !important
}
.password_info {
  color: #5E5E5E;
  font-size: 12px;
}
</style>
