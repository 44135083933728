<template>
  <div class="billing_information">
    <div class="title">
      <h3>Billing Contact Information</h3>
      <p>Update Billing Contact Information of Group</p>
    </div>
    <ValidationObserver ref="billingInfo" v-slot="{ handleSubmit }" slim>
      <b-form
        @submit.stop.prevent="handleSubmit(updateBillingInfo)"
        class="update-group-form"
        ref="billingInfoContainer"
      >
        <input-text
          :rules="{ required: true }"
          labelFor="billing_first_name"
          labelName="Billilng First Name"
          inputName="billing_first_name"
          :vmodel.sync="form.billing_first_name"
        />
        <input-text
          :rules="{ required: true }"
          labelFor="billing_last_name"
          labelName="Billilng Last Name"
          inputName="billing_last_name"
          :vmodel.sync="form.billing_last_name"
        />
        <!-- <input-text
          :rules="{ required: true, numeric: true, digits: 10 }"
          labelFor="billing-phone-number"
          labelName="Billilng Phone"
          inputName="billing_phone"
          type="number"
          :vmodel.sync="form.billing_phone"
          @keyup="checkClearoutPhone"
        /> -->

        <b-form-group>
          <validation-provider
            name="Billing Phone"
            :rules="{ required: true, numeric: true, digits: 10 }"
            v-slot="validationContext"
          >
            <label
              id="label-billing-phone-number"
              for="label-billing-phone-number"
            >
              Billing Phone<span style="color:Red">*</span>
            </label>
            <b-form-input
              id="billing-phone-number"
              type="number"
              :state="getValidationState(validationContext)"
              aria-describedby="input-feedback"
              v-model="form.billing_phone"
              placeholder="Enter Billing Phone"
              @input="checkClearoutPhone"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-feedback billing-phone-number-feedback"
              class="billing-phone-number-feedback"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <input-text
          :rules="{ required: false, numeric: true, digits: 10 }"
          labelFor="billing_fax"
          labelName="Billing Fax"
          inputName="billing_fax"
          :vmodel.sync="form.billing_fax"
          inputType="number"
        />

        <input-text
          :rules="{ required: true, email: true }"
          labelFor="billing_email"
          labelName="Billing Email"
          inputName="billing_email"
          :vmodel.sync="form.billing_email"
          inputType="email"
        />
        <div class="clearfix"></div>
        <b-form-group class="save_info">
          <button
            :disabled="submit == false && validPhone == false"
            :class="submit == false && validPhone == false ? 'disable' : ''"
          >
            {{ submit ? "Processing..." : "Update" }}
          </button>
        </b-form-group>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import CommonValidator from "@/resource/CommonValidator";
export default {
  data: () => {
    return {
      form: {
        group_id: "",
        billing_first_name: "",
        billing_last_name: "",
        billing_phone: "",
        billing_fax: "",
        billing_email: "",
      },
      submit: false,
      validPhone: true,
    };
  },
  methods: {
    async checkClearoutPhone() {
      let inputId, feedBackInputClass;
      inputId = "#billing-phone-number";
      feedBackInputClass = ".billing-phone-number-feedback";
      const check = await CommonValidator.validatePhoneNumberWithOptions(
        this.form.billing_phone,
        inputId,
        feedBackInputClass
      );
      this.validPhone = check;
      return check;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getBillingInfo() {
      const url = "get-billing-info";
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.form = {
            group_id: data.group_id ? data.group_id : "",
            billing_first_name: data.billing_first_name
              ? data.billing_first_name
              : "",
            billing_last_name: data.billing_last_name
              ? data.billing_last_name
              : "",
            billing_phone: data.billing_phone ? data.billing_phone : "",
            billing_fax: data.billing_fax ? data.billing_fax : "",
            billing_email: data.billing_email ? data.billing_email : "",
          };
        })
        .catch((err) => {
          console.log("something went wrong!");
        });
    },
    async updateBillingInfo() {
      if (await this.checkClearoutPhone()) {
        if (this.validPhone) {
          const url = "update-billing-info";
          this.submit = true;
          let formData = this.form;
          let loader = this.$loading.show({
            container: this.$refs.billingInfoContainer,
          });
          Api.put(url, formData)
            .then((res) => {
              let response = res.data.message;
              this.$notify(response);
            })
            .catch((err) => {
              this.$notify(err.response.data.message, "Error", "danger");
            })
            .finally(() => {
              this.getBillingInfo();
              setTimeout(() => {
                loader.hide();
                this.submit = false;
              }, 2000);
            });
        }
      }
    },
  },
  created() {
    this.getBillingInfo();
  },
};
</script>
<style src="@/assets/css/group_dashboard/account_information.css"></style>
