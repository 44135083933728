<template>
    <a :href="link" target="_blank" class="action_container" download>
      <span class="action_icon">
        <img src="@/assets/images/download.svg" />
      </span>
      Download
    </a>
</template>
<script>
export default {
  name: "DownloadButton",
  props: {
    link: {
      type: String,
      required: true,
    },
  },
};
</script>
<style scoped>
 a, a:hover {
    text-decoration: none;
    color: #000;
}

.action_container {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    cursor: pointer;
}

.action_icon {
  background: transparent
    linear-gradient(180deg, #00ccff 0%, #1597b8 49%, #0567bc 100%);
  border-radius: 27px;
  padding: 7px;
  width: 20px;
  height: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
</style>
