<template>
  <div class="main_messages_container">
    <div class="message_description_container" v-if="message">
      <div class="msg-user-list-wrap">
        <div class="msg-user-list-action">
          <a href="#" v-b-modal.modal-1 v-b-tooltip.hover title="View All members">
            <i class="fas fa-list-ul"></i>
          </a>
          <a href="javscript:void(0);" @click="deleteMessage(message.mid)" v-b-tooltip.hover title="Delete message">
            <i class="far fa-trash-alt" aria-hidden="true"></i>
          </a>
          <div>
            <b-modal id="modal-1" title="BootstrapVue" hide-footer hide-header>
              <div class="msg-user-list-popup-wrap">
                <div class="msg-user-list-popup" v-for="(item, index) in sentUserLists" :key="index">
                  <div class="img"><img :src="item.image ?  item.image : dummyImage " alt=""></div>
                  <span>{{item.name}}</span>
                  <p>{{item.email}}</p>
                </div>
                
              </div>
            </b-modal>
          </div>
        </div>
        <div class="msg-user-list" v-for="(item, index) in firstFiveUsers" :key="index">
          <div class="img"><img :src="item.image ?  item.image : dummyImage" alt=""></div>
          <span>{{item.name}}</span>
         
        </div>
      </div>
      <div class="date amc-date">
        {{ formattedDetailDate }}
      </div>
      <div class="seen">Seen: {{message.message_seen_count}}</div>
      <div class="message_description">
        <div class="arrow-left"></div>
        <h2>
          {{ message.subject }}
        </h2>
        <div class="message_content">
          <p v-html="message.message" />
          <br />
          <div>
            <h4 v-if="message.attachments.length > 0">
              Attachments:
            </h4>
            <ul style="list-style:none">
              <li v-for="file in message.attachments" :key="file.id">
                <a :href="file.file_url" target="__blank" download>
                  <i class="fa fa-file"></i>
                  {{ file.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios"
import Api from "@/resource/Api";

export default {
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data (){
      return {
        sentUserLists : [],
        firstFiveUsers : [],
        dummyImage : require("../../assets/images/dummy.png")
      }
  },
  computed: {
    formattedDetailDate() {
      let date = this.message.date_created;
      if (moment(date, "MM/DD/YYYY HH:mm:ss", true).isValid()) {
        return moment(date, "MM/DD/YYYY HH:mm:ss", true).format("gg MMM , ddd");
      }
      return "N/A";
    },
  },
  mounted() {
    this.getSentUserLists()
  },
  methods: {
    deleteMessage(messageId) {
      let url = process.env.VUE_APP_API_URL_V2 +`delete-message?mid=${messageId}`;
      this.$store.commit("setDialogClass", "group-app-warning-dialog");
      this.$confirm({
        title: "Delete Message",
        message: `Do you want to delete this message?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            axios
              .delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$emit("sentMessageList");
                this.$notify(response);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  getSentUserLists() {
      Api.get(`get-message-sent-users?mid=`+this.message.mid)
        .then((res) => {
          this.sentUserLists = res.data.data;
          this.firstFiveUsers = res.data.data.slice(0, 5);
        })
        .catch((err) => {
          this.sentUserLists = [];
          this.firstFiveUsers = []
          console.log("error", err);
        })
        .finally(() => {
        });
    },
  },
  watch: {
    message: function() {
      this.getSentUserLists()
    },
  },
};
</script>
<style src="@/assets/css/group_dashboard/all_messages.css" scoped></style>
