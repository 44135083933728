<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="display_setting_container">
        <h2>Display Settings</h2>
        <div class="display_setting">
          <div class="setting">
            <h4>Settings</h4>
            <ValidationObserver
              ref="displaySettingObserver"
              v-slot="{ handleSubmit }"
              slim
            >
              <b-form
                @submit.stop.prevent="handleSubmit(updateInfo)"
                ref="displaySettingContainer"
              >
                <input-text
                  :rules="{ required: true, email: true }"
                  labelFor="email"
                  labelName="Display Email"
                  inputName="email"
                  :vmodel.sync="form.display_email"
                  inputTypes="email"
                />

                <input-text
                  :rules="{ required: true, numeric: true, digits: 10 }"
                  labelFor="phone"
                  labelName="Display Phone"
                  inputName="phone"
                  :vmodel.sync="form.display_phone"
                  inputTypes="number"
                  @input.native="checkClearoutPhone"
                />

                <input-textarea
                  :rules="{ max: 150 }"
                  labelFor="tagline"
                  labelName="Tag Line"
                  inputName="tagline"
                  :vmodel.sync="form.tagline"
                  :rows="2"
                  :cols="10"
                />

                <input-textarea
                  :rules="{ max: 150 }"
                  labelFor="gweb_aboutus"
                  labelName="Bio"
                  inputName="gweb_aboutus"
                  :vmodel.sync="form.gweb_aboutus"
                  :rows="5"
                  :cols="10"
                />

                <button
                  type="submit"
                  :disabled="submit == false && validPhone == false"
                  class="save"
                  :class="
                    submit == false && validPhone == false ? 'disable' : ''
                  "
                >
                  {{ submit ? "Saving..." : "Save" }}
                </button>
              </b-form>
            </ValidationObserver>
          </div>
          <div class="see_information">
            <h4>Where can I see this information</h4>
            <div class="information">
              <img
                :src="displayImage ? displayImage : defaultDisplayImage"
                alt="Information"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import CommonValidator from "@/resource/CommonValidator";
import defaultDisplayImage from "@/assets/images/benadvance.jpg";
export default {
  name: "DisplaySetting",
  data: () => {
    return {
      form: {
        display_email: "",
        display_phone: "",
        tagline: "",
        gweb_aboutus:""
      },
      displayImage: "",
      defaultDisplayImage,
      submit: false,
      validPhone: true,
    };
  },
  methods: {
    async checkClearoutPhone() {
      let inputId, feedBackInputClass;
      inputId = "#phone";
      feedBackInputClass = ".phone-feedback";
      const check = await CommonValidator.validatePhoneNumberWithOptions(
        this.form.display_phone,
        inputId,
        feedBackInputClass
      );
      this.validPhone = check;
      return check;
    },
    async updateInfo() {
      if (await this.checkClearoutPhone()) {
        if (this.validPhone) {
          let url = "display-setting/update";
          let loader = this.$loading.show({
            container: this.$refs.displaySettingContainer,
          });
          Api.put(url, this.form)
            .then((res) => {
              let response = res.data.message;
              this.$notify(response);
            })
            .catch((err) => {
              this.$notify(err.response.data.message, "Error", "danger");
            })
            .finally(() => {
              loader.hide();
            });
        }
      }
    },
    getSettings() {
      let [url, loader] = ["display-setting/get", this.$loading.show()];
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.displayImage = data.display_image
            ? data.display_image
            : defaultDisplayImage;
          this.form = {
            display_email: data.display_email ? data.display_email : "",
            display_phone: data.display_phone ? data.display_phone : "",
            tagline: data.tagline ? data.tagline : "",
            gweb_aboutus: data.gweb_aboutus ? data.gweb_aboutus : "",
          };
        })
        .catch((err) => {
          console.log("Something is wrong.");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getSettings();
  },
};
</script>
<style src="@/assets/css/group_dashboard/display_setting.css" scoped>
</style>
<style>
.display_setting .form-group label {
  font-size: 14px;
  color: #254050;
}
.display_setting .custom-control-label::before {
  top: 2px;
}
.display_setting .custom-control-label::after {
  top: 2px;
}
</style>