<template>
  <b-modal
    id="payment_history"
    centered
    title="Payment History"
    no-close-on-backdrop
    size="lg"
  >
    <template>
      <b-form>
        <b-form-group label="From:" label-for="input-2">
          <date-picker
            v-model="fromDate"
            class="invoicePicker_download"
            type="date"
            placeholder="Start Date"
            :disabled-date="(date) => { return disabledAfterCurrentDate(date,'start') }"
          ></date-picker>
        </b-form-group>
        <b-form-group label="To:" label-for="input-2">
          <date-picker
            v-model="toDate"
            class="invoicePicker_download"
            type="date"
            placeholder="End Date"
            :disabled-date="(date) => { return disabledAfterCurrentDate(date,'end') }"
          ></date-picker>
        </b-form-group>
      </b-form>
    </template>

    <template #modal-footer>
      <b-button class="btn btn-secondary" @click="cancel()">
        {{ cancelTitle }}
      </b-button>
      <b-button class="btn btn-primary" @click.prevent="download()">
        Download
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from "vuex";
import Api from "@/resource/Api";
import moment from "moment";

export default {
  data: () => {
    return {
      cancelTitle: "Cancel",
      fromDate: "",
      toDate: "",
    };
  },
  computed: {
    ...mapGetters({
      groupInfo: "groupInfo",
    }),
  },
  methods: {
    reset() {
      this.$bvModal.hide("paystand_dialog");
    },
    download() {
      Api.get(
        "generate-invoice-payment-history?from_date=" +
          this.formattedDate(this.fromDate) +
          "&to_date=" +
          this.formattedDate(this.toDate)
      )
        .then((res) => {
          window.open(res.data.data.file_url, "_blank").focus();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally(() => {});
    },
    cancel() {
      this.$bvModal.hide("payment_history");
    },
    formattedDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    disabledAfterCurrentDate(date,type) {
      const formattedDate = new Date(new Date().setHours(0, 0, 0, 0));
      return date > formattedDate.setDate(formattedDate.getDate() - (+(type == 'start')));
    },
    
  },
};
</script>
<style scoped>
.modal {
  position: fixed !important;
}
.invoicePicker_download {
  width: 100% !important;
}
.modal-body .form-group {
  width: 50%;
  display: inline-block;
}
</style>
