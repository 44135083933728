<template>
  <div>
    <h4>Beneficiary Information</h4>
    <div class="beneficiary-card">
      <div class="light-bg table_content">
        <table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Relation</td>
              <td>Type</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in beneficiaryInfo" :key="index">
              <td>{{ item.first_name }} {{ item.last_name }}</td>
              <td>{{ item.relationship }}</td>
              <td>
                {{ item.is_contigent ? 'Contingent' : 'Beneficiary' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    beneficiaryInfo: {
      required: true,
    },
  },
}
</script>
<style
  src="@/assets/css/group_dashboard/beneficiary_info_table.css"
  scoped
></style>
