const state = {
    regFormUrl: "register/representative-info",
    regTitle: "Broker Information",
    regTab: "broker-info",
    regTempId: null,
    domainIds: [],
    regGroupType:"",
    regGroupContribution:true
}

const getters = {
    regTempId: state => state.regTempId,
    domainIds: state=>state.domainIds,
    regFormUrl: state => state.regFormUrl,
    regTab: state => state.regTab,
    regTitle: state => state.regTitle,
    regTabNumber:state=>state.regTabNumber,
    regGroupType:state=>state.regGroupType,
    regGroupContribution:state=>state.regGroupContribution
}

const actions = {}

const mutations = {
    setRegGroupContribution(state,payload){
        state.regGroupContribution = payload;
    },
    setRegGroupType(state,title){
        state.regGroupType = title
    },
    setRegFormUrl(state, url) {
        state.regFormUrl = url;
    },
    setRegTitle(state, regTitle) {
        state.regTitle = regTitle;
    },
    setRegTab(state, tab) {
        state.regTab = tab;
    },
    setRegTempId(state, regTempId) {
        state.regTempId = regTempId;
    },
    setDomainIds(state,domainIds){
        state.domainIds=domainIds
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}