import axios from "axios";

const state = {
    authenticated: false,
    groupEmail: "",
    accessToken: "",
    groupId: "",
    OTPCode: "",
    profilePic: "",
    deviceId: "",
    mainGroupId: "",
    groupCensusId: ""
}

const getters = {
    deviceId: state => state.deviceId,
    groupEmail: state => state.groupEmail,
    authenticated: state => state.authenticated,
    accessToken: state => state.accessToken,
    OTPCode: state => state.OTPCode,
    mainGroupId: state => state.mainGroupId,
    groupId: state => state.groupId,
    groupCensusId: state => state.groupCensusId,
}

const actions = {}

const mutations = {
    setAuthentication(state, status) {
        state.authenticated = status;
    },
    setGroupEmail(state, email) {
        state.groupEmail = email;
    },
    setAccessToken(state, token) {
        state.accessToken = token;
        axios.defaults.headers["Authorization"] =
            token != "" ? "Bearer " + token : "";
    },
    setGroupId(state, groupId) {
        state.groupId = groupId;
    },
    setOtpCode(state, otpCode) {
        state.OTPCode = otpCode;
    },
    setProfilePic(state, profilePic) {
        state.profilePic = profilePic;
    },
    setDeviceId(state, deviceId) {
        state.deviceId = deviceId;
    },
    setMainGroupId(state, mainGroupId) {
        state.mainGroupId = mainGroupId;
    },
    setGroupCensusId(state, groupCensusId) {
        state.groupCensusId = groupCensusId
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
