<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="welcome_status_container">
        <welcome></welcome>
        <status-info></status-info>
      </div>
      <benefit-store></benefit-store>
      <invoice v-if="checkIfEmployer"></invoice>
      <group-information></group-information>
      <banking v-if="checkIfEmployer"></banking>
      <rep-information></rep-information>
      <received-messages></received-messages>
      <knowledge-center></knowledge-center>
    </div>
  </div>
</template>

<script>
import Welcome from "./Welcome";
import StatusInfo from "./StatusInfo";
import BenefitStore from "./BenefitStore";
import Invoice from "./Invoice";
import Banking from "./Banking";
import RepInformation from "./RepInformation";
import ReceivedMessages from "./ReceivedMessages";
import GroupInformation from "./GroupInformation";
import KnowledgeCenter from "./KnowledgeCenter";
import Helper from "@/resource/Helper"
import { mapGetters } from "vuex";

export default {
  components: {
    Welcome,
    StatusInfo,
    BenefitStore,
    Invoice,
    Banking,
    RepInformation,
    ReceivedMessages,
    GroupInformation,
    KnowledgeCenter,
  },
  created() {
    //dispatching for some updates
    Helper.dashboardGetters()
  },
  computed: {
    ...mapGetters({ groupInfo: "groupInfo" }),
    checkIfEmployer() {
      return this.groupInfo.group_type == 'employer'
    },
  },
};
</script>

<style src="@/assets/css/group_dashboard/main_group_dashboard.css"></style>
