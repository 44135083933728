<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="view_files">
        <breadcrumb :items="breadcrumbItems" />
        <div class="title">
          <h2>View Files</h2>
          <button class="upload_file" v-b-modal.upload_file>
            <img src="@/assets/images/upload_icon.svg" width="20" height="20" />
            <span style="margin-left: 10px">Upload File</span>
          </button>
        </div>

        <div class="table_container">
          <div class="file_detail" style="overflow-x: auto">
            <table>
              <thead>
                <tr>
                  <td width="10%">File Name</td>
                  <td width="10%">Type</td>
                  <td width="30%">File Notes</td>
                  <td width="20%">Data / Time Uploaded</td>
                  <td width="30%">Actions</td>
                </tr>
              </thead>

              <tbody>
                <tr v-for="item in rows" :key="item.doc_id">
                  <td class="file_name">{{ item.file_title ? item.file_title : '-' }}</td>
                  <td>{{ item.doc_type ? item.doc_type : '-' }}</td>
                  <td>
                    <div :id="'file-' + item.doc_id">
                      <template v-if="showFileId == item.doc_id">
                        {{ item.doc_notes }}
                      </template>
                      <template v-else>
                        {{ item.doc_notes | truncate(100) }}
                      </template>
                    </div>

                    <div v-if="item.doc_notes.length >= 100">
                      <a
                        href="javascript:void(0)"
                        class="float-right toogle-note"
                        @click="toggleNote(item.doc_id)"
                      >
                        {{
                          showFileId == item.doc_id ? "Show Less" : "Show More"
                        }}
                      </a>
                    </div>
                  </td>
                  <td>
                    {{
                      item.doc_date
                        | date_format(
                          "YYYY/MM/DD HH:mm:ss",
                          "MMMM Do YYYY, h:mm:ss a"
                        )
                    }}
                  </td>
                  <td class="file_buttons">
                    <download-button :link="item.doc_url" />
                    <delete-button
                      @click.native.prevent="deleteFile(item.doc_id)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- <template v-if="rows.records && rows.records.length < 1">
            <no-content />
          </template>
          <div class="overflow-auto">
            <pagination :rows.sync="rows" :filters="filterData" />
          </div> -->
        </div>
        <add-file @onFileAdded="getFiles" />
        <file-detail :data="row" />
      </div>
    </div>
  </div>
</template>

<script>
import AddFile from "./AddFile.vue";
import FileDetail from "./FileDetail.vue";
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  components: { AddFile, FileDetail },
  name: "FileList",
  data() {
    return {
      rows: [],
      breadcrumbItems: [{ title: "Files", active: true }],
      row: {},
      showFileId: "",
    };
  },
  methods: {
    toggleNote(rowId) {
      if (this.showFileId == rowId) {
        this.showFileId = "";
      } else {
        this.showFileId = rowId;
      }
    },
    detail(item) {
      this.row = item;
      this.$bvModal.show("file-detail");
    },
    getFiles(filters = []) {
      let url;
      let loader = this.$loading.show();

      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `get-doc-lists?${filterUrl}`;
      } else {
        url = `get-doc-lists`;
      }
      Api.get(url)
        .then((res) => {
          // this.rows = res.data;
          this.rows = res.data.data;
        })
        .catch((error) => {
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    deleteFile(fileId) {
      let url = `delete-doc/${fileId}`;
      this.$store.commit("setDialogClass", "group-app-warning-dialog");
      this.$confirm({
        title: "Delete File",
        message: `Do you want to delete this file?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.getFiles();
                this.$notify(response);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
  mounted() {
    this.getFiles();
  },
};
</script>
<style src="@/assets/css/group_dashboard/view_files.css"></style>
<style>
/* @media only screen and (min-width: 992px){
.modal-lg {
    max-width: 600px !important;
}
} */

.toogle-note {
  color: black;
  text-decoration: none;
}
</style>
