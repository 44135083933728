<template>
  <div class="column">
    <div
      v-for="(item, index) in messages.records"
      :key="index"
      class="row"
      @click.prevent="setMessage(item)"
      :class="currentItemId == item.mid ? 'active_background' : null"
    >
      <img src="@/assets/images/default_profile.jpg" alt="profile" />
      <div class="message_titleBlock">
        <span class="message_title">{{ item.subject }}</span>
        <br />
        <span class="message_subject">
          {{ formattedDate(item.date_created) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      currentItemId: "",
    };
  },
  methods: {
    formattedDate(date) {
      if (moment(date, "MM/DD/YYYY HH:mm:ss", true).isValid()) {
        return moment(date, "MM/DD/YYYY HH:mm:ss", true).format("MM-DD-YYYY");
      }
      return "N/A";
    },
    setMessage(item) {
      this.currentItemId = item.mid;
      this.$emit("onClickedItem", item);
    },
  },
  watch: {
    messages: function(newData, oldData) {
      if (newData != oldData) {
        this.currentItemId = newData.records.length > 0 ? newData.records[0].mid : null;
      }
    },
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/admin_message_center.css"
  scoped
></style>
