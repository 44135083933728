const state = {
  dialogClass: "group-app-confirm-dialog",
};

const getters = {
  dialogClass: state => state.dialogClass,
};

const actions = {
  getDialogClass({ commit }, val) {
      if(val){
        commit("setDialogClass", val);
      }
  },
};

const mutations = {
  setDialogClass(state, className) {
    state.dialogClass = className;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
