var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper_group_dashboard"},[_c('div',{staticClass:"group_dashboard"},[_c('div',{staticClass:"change_password_wrapper"},[_c('div',{staticClass:"change_password"},[_vm._m(0),_c('div',{staticClass:"change_password_form"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Current Password","vid":"old_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_c('span',[_vm._v(" Current Password "),_c('span',{staticClass:"input-required"},[_vm._v("*")])])]),_c('div',{staticClass:"changeForm-group",class:errors[0] || valid ? 'errorIcon_eye' : ''},[_c('b-form-input',{attrs:{"id":"old_password","type":"password","state":errors[0] ? false : valid ? true : null,"placeholder":"Enter Current Password"},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}}),_c('eye-button',{staticClass:"toggleSSN toggle-eye-icon",attrs:{"id":"old_password","type":"text"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"New Password","vid":"new_password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_c('span',[_vm._v(" New Password "),_c('span',{staticClass:"input-required"},[_vm._v("*")])])]),_c('div',{staticClass:"changeForm-group",class:errors[0] || valid ? 'errorIcon_eye' : ''},[_c('b-form-input',{attrs:{"id":"new_password","type":"password","state":errors[0] ? false : valid ? true : null,"placeholder":"Enter New Password"},model:{value:(_vm.form.new_password),callback:function ($$v) {_vm.$set(_vm.form, "new_password", $$v)},expression:"form.new_password"}}),_c('eye-button',{staticClass:"toggleSSN toggle-eye-icon",attrs:{"id":"new_password","type":"text"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])}}],null,true)}),_c('div',[_c('password-strength-bar',{attrs:{"password":_vm.form.new_password}})],1),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:new_password","name":"Confirm Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_c('span',[_vm._v(" Confirm Password "),_c('span',{staticClass:"input-required"},[_vm._v("*")])])]),_c('div',{staticClass:"changeForm-group errorIcon_eye"},[_c('b-form-input',{attrs:{"type":"password","id":"confirm_password","state":errors[0] ? false : valid ? true : null,"placeholder":"Enter Confirmation Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('eye-button',{staticClass:"toggle-eye-icon",attrs:{"id":"confirm_password","hideOnDefault":"true","type":"text"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)])}}],null,true)}),_c('div',[(_vm.errorText)?_c('span',{staticClass:"password_error"},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()]),_c('div',{staticClass:"submit_password"},[_c('button',{attrs:{"disabled":_vm.submit}},[_vm._v(" "+_vm._s(_vm.submit ? "Changing..." : "Submit")+" ")])])],1)]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"key_icon"},[_c('i',{staticClass:"fas fa-key"})]),_c('div',{staticClass:"title_text"},[_c('h2',[_vm._v("Change Password")]),_c('span',[_vm._v("Please confirm your current password")])])])}]

export { render, staticRenderFns }