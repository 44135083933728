<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="welcome">
        <h1>Welcome</h1>
        <p>
          Register to create your online access to your group dashboard.
         (Please note online account access is not available until your start
          date or thereafter)
        </p>
      </div>

      <div class="form">
        <div class="top-button">
          <router-link :to="{ name: 'Login' }">
            <button class="login_inactive">Log In</button></router-link
          >
          <button class="sign_up_active">Register</button>
        </div>
        <div class="signup_form">
          <div class="signup_head">
            <h3>Register</h3>
            <span
              >To confirm and register your account, please complete the fields
              below.</span
            >
          </div>
          <b-form-group label="Last Name" label-for="input-1">
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="lastname"
              placeholder="Enter your lastname"
            ></b-form-input>
            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["lastname"] }}
            </div>
          </b-form-group>

          <b-form-group label="Last 4 Digits of SSN" label-for="input-2">
            <b-form-input
              id="input-2"
              type="text"
              v-model="cssn4"
              maxlength="4"
              required
              placeholder="0123"
              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            ></b-form-input>
            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["cssn"] }}
            </div>
          </b-form-group>
          <b-form-group label="Date of Birth" label-for="input-3">
            <div class="date_of_birth">
              <b-form-select :options="months" v-model="selected_month">
              </b-form-select>
              <b-form-select :options="days" v-model="selected_day">
              </b-form-select>
              <b-form-select :options="years" v-model="selected_year">
              </b-form-select>
            </div>
            <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["dob"] }}
            </div>
          </b-form-group>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
          <button @click="verifyMember" :disabled="verifyLoader">
            <b-spinner v-if="verifyLoader == true" small></b-spinner>
            Continue
          </button>
          <router-link :to="{ name: 'Login' }"
            ><p>Already have an <b>Account?</b></p></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import helper from "../../resource/Helper";
// import axios from "axios";
export default {
  data() {
    return {
      selected_month: null,
      selected_day: null,
      selected_year: null,
      cssn4: null,
      lastname: null,
      verifyLoader: false,
      formErrorFlag: false,
      formErrors: [],
      months: [
        { value: null, text: "Month", disabled: true },
        { value: "01", text: "Jan" },
        { value: "02", text: "Feb" },
        { value: "03", text: "Mar" },
        { value: "04", text: "Apr" },
        { value: "05", text: "May" },
        { value: "06", text: "Jun" },
        { value: "07", text: "Jul" },
        { value: "08", text: "Aug" },
        { value: "09", text: "Sep" },
        { value: "10", text: "Oct" },
        { value: "11", text: "Nov" },
        { value: "12", text: "Dec" },
      ],
      days: [
        { value: null, text: "Day", disabled: true },
        { value: "01", text: "01" },
        { value: "02", text: "02" },
        { value: "03", text: "03" },
        { value: "04", text: "04" },
        { value: "05", text: "05" },
        { value: "06", text: "06" },
        { value: "07", text: "07" },
        { value: "08", text: "08" },
        { value: "09", text: "09" },
        { value: "10", text: "10" },
        { value: "11", text: "11" },
        { value: "12", text: "12" },
        { value: "13", text: "13" },
        { value: "14", text: "14" },
        { value: "15", text: "15" },
        { value: "16", text: "16" },
        { value: "17", text: "17" },
        { value: "18", text: "18" },
        { value: "19", text: "19" },
        { value: "20", text: "20" },
        { value: "21", text: "21" },
        { value: "22", text: "22" },
        { value: "23", text: "23" },
        { value: "24", text: "24" },
        { value: "25", text: "25" },
        { value: "26", text: "26" },
        { value: "27", text: "27" },
        { value: "28", text: "28" },
        { value: "29", text: "29" },
        { value: "30", text: "30" },
        { value: "31", text: "31" },
        { value: "32", text: "32" },
      ],
      years: [],
    };
  },
  // mounted() {
  //   let yearList = [{ value: null, text: "Year", disabled: true }];
  //   let year = new Date().getFullYear();
  //   for (let i = year - 100; i <= year; i++) {
  //     yearList.push({ value: i, text: i });
  //   }
  //   this.years = yearList;
  // },
  // methods: {
  //   verifyMember() {
  //     let [app, inputError] = [this, false];
  //     app.formErrorFlag = false;
  //     app.formErrors = [];
  //     let dob =
  //       app.selected_year + "-" + app.selected_month + "-" + app.selected_day;
  //     if (helper.validateDate(dob) == false) {
  //       inputError = true;
  //       app.formErrors["dob"] = "Please enter valid date of birth.";
  //       app.formErrorFlag = true;
  //     }
  //     if (!app.cssn4 || app.cssn4.toString().length != 4) {
  //       inputError = true;
  //       app.formErrors["cssn"] = "Please valid enter ssn number.";
  //       app.formErrorFlag = true;
  //     }
  //     if (!app.lastname) {
  //       inputError = true;
  //       app.formErrors["lastname"] = "Please enter lastname.";
  //       app.formErrorFlag = true;
  //     }
  //     if (inputError) return;
  //     app.verifyLoader = true;

  //     axios
  //       .post("/auth/verify-member-signup", {
  //         cssn4: app.cssn4,
  //         last_name: app.lastname,
  //         dob: dob,
  //       })
  //       .then(function (response) {
  //         if (response.data.status == "success") {
  //           app.$store.commit("setMemberEmail", response.data.data.cemail);
  //           app.$store.commit("setMemberId", response.data.data.userid);
  //           helper.infoMessage(
  //             app,
  //             response.data.status,
  //             response.data.message
  //           );
  //           // helper.delayRedirect('SignUpContinue',0,'FromSignUp')
  //           app.$router.push({
  //             name: 'SignUpContinue',
  //             params: {
  //               items: 'FromSignUp',
  //             },
  //           });
  //         }
  //       })
  //       .catch(function (error) {
  //         if (error.response.data.status == "error") {
  //           // if (error.response.data.message == "Your device is not verified") {
  //           // helper.infoMessage(app,error.response.data.status,error.response.data.message)
  //           //app.sendOtpCode(app.input.username);
  //           //  } else {
  //           helper.infoMessage(
  //             app,
  //             error.response.data.status,
  //             error.response.data.message
  //           );
  //           app.formErrorFlag = true;
  //           app.formErrors["error"] = error.response.data.message;
  //           app.loginLoader = false;
  //           app.verifyLoader = false;
  //           //  }
  //         }
  //       });
  //   },
  // },
};
</script>
<style>
@import "../../../assets/css/group_dashboard/signup.css";
</style>
