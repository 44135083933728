<template>
  <div class="table_container">
    <table>
      <thead>
        <tr>
          <td>Invoice Id</td>
          <td>Date</td>
          <td>Totals</td>
          <td>Status</td>
          <td>Payments</td>
          <td>Action</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in rows" :key="index">
          <td>
            <strong>{{ item.invoice_id }}</strong>
          </td>
          <td>
            Issue Date: {{ item.invoice_date | date_format }}<br />
            Due Date:
            {{ item.invoice_due_date | date_format }}
            <br />
            From Date:
            {{ item.invoice_start_date | date_format }}
            <br />
            To Date: {{ item.invoice_end_date | date_format }}
          </td>
          <td>
            Monthly Premium Amount: {{ item.invoice_total | currency }}
            <br />
            Overall Total: {{ item.invoice_due_amount | currency }}
          </td>
          <td>
            <!-- <template
              v-if="item.onetime_fl && item.invoice_payment_status == 'UNPAID'"
            >
              PROCESSING
            </template>
            <template v-else>
              {{ item.invoice_payment_status }}
            </template> -->
            {{ item.invoice_payment_status }}
          </td>
         <td>
              <div class="payment-list" v-for="(billingData, billingIndex) in item.payments" :key="billingIndex">
                <span>{{ "Payment Date: " + billingData.payment_date }} </span>
                <span>{{ "Payment Method: " + billingData.method }} </span>
                <span>Payment Status:<strong>
                    {{ billingData.payment_status }}</strong>
                </span>
                <span>{{ "Payment Type: " + billingData.payment_type }} </span>
                <span v-for="(
                    additionalData, additionalIndex
                  ) in billingData.additional_data" :key="additionalIndex">
                  {{ additionalData.key + ": " }} {{ additionalData.value }}
                </span>
                <span v-if="billingIndex + 1 != item.payments.length" style="border-top: 2px dotted black; width: 76%"></span>
              </div>
            </td>
          <td>
            <download-button :link="item.file" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
export default {
  props: {
    rows: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.payment-list span { display:block !important}
</style>
