<template>
  <div class="top">
    <div class="welcome_text">
      <p>
        Welcome<br />
        <span> {{groupInfo.group_name}} </span>
        <br />
        Your Employer / Group is 
        <strong>{{groupInfo.group_type}}</strong>
      </p>
    </div>
    <!-- <div class="reminder">
      <div class="error">
        <i class="fa fa-times-circle"></i>
        <p>
          Error! Mobile Number Invalid, Please add<br />
          Valid Mobile Number. Click here
        </p>
      </div>
      <div class="alert">
        <i class="fa fa-times-circle"></i>
        <p>
          Alert! You have the same email address<br />
          associates with more than one Rep account.
        </p>
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
   computed: {
    ...mapGetters({  groupInfo: "groupInfo" }),
  },
}
</script>
<style src="@/assets/css/group_dashboard/welcome.css" scoped>
</style>