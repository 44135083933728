<template>
  <div>
    <!-- add address information modal -->
    <b-modal
      id="add_address_info"
      centered
      hide-footer
      no-close-on-backdrop
      hide-header
      size="lg"
    >
      <img
        @click="$bvModal.hide('add_address_info')"
        class="close_cross"
        src="@/assets/images/cross.svg"
        alt="cross"
      />
      <div class="title">
        <h2>Add Address Information</h2>
        <span>Please enter your new address information</span>
      </div>

      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(formSubmit)"
          class="add-group-address-form"
          ref="addGroupAddressContainer"
        >
          <input-text
            :rules="{ required: true }"
            labelFor="address_1"
            labelName="Address Line 1"
            inputName="address_1"
            :vmodel.sync="form.address1"
          />

          <input-text
            :rules="{ required: false }"
            labelFor="address_2"
            labelName="Address Line 2"
            inputName="address_2"
            :vmodel.sync="form.address2"
          />

          <input-text
            :rules="{ required: true }"
            labelFor="city"
            labelName="City"
            inputName="city"
            :vmodel.sync="form.city"
          />

          <input-text
            :rules="{ required: true, digits: 5 }"
            labelFor="zip"
            labelName="Zip"
            inputName="zip"
            :vmodel.sync="form.zip"
            type="number"
          />

          <input-select
            :rules="{ required: true }"
            labelFor="state"
            labelName="State"
            inputName="state"
            :vmodel.sync="form.state"
            :options="states"
            defaultOption="Select State"
          />
          <input-select
            :rules="{ required: true }"
            labelFor="address_type"
            labelName="Type of Address"
            inputName="address_type"
            :vmodel.sync="form.type"
            :options="addressTypes"
            defaultOption="Select Address Type"
          />

          <b-form-group>
            <onoff-toggle
              v-model="form.is_primary"
              onColor="#29BCFF"
              offColor="#CCCCCC"
              :shadow="true"
              width="33"
              height="18"
              theme="ios"
            />
            <span class="set_primary">Set as Primary</span>
          </b-form-group>
          <b-form-group>
            <b-form-checkbox
              v-model="continue_with_address_validation"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              Continue without address validation
            </b-form-checkbox>
          </b-form-group>
          <b-form-group class="submit">
            <button :disabled="submit">
              {{ submit ? "Processing..." : "Submit" }}
            </button>
          </b-form-group>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <!-- add address information modal -->
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import { validateAddressUsps } from "@/resource/EndPoints/endpoints";

import axios from "axios";
export default {
  data: () => {
    return {
      form: {
        address1: "",
        address2: "",
        city: "",
        state: null,
        zip: "",
        type: null,
        usps_verified: false,
        is_primary: false,
      },
      addressTypes: Helper.getAddressTypes(),
      states: Helper.getStates(),
      submit: false,
      continue_with_address_validation: false,
    };
  },
  methods: {
    resetForm() {
      this.form = {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        type: "",
        usps_verified: false,
        is_primary: false,
      };
    },
    uspsValidate() {
      this.submit = true;
      const uspsAxios = axios.create();
      uspsAxios
        .post(validateAddressUsps, this.form)
        .then((res) => {
          this.form.usps_verified = true;
          this.addAddress();
        })
        .catch((err) => {
          this.$notify(
            err.response.data.data[0].error_message,
            "Error",
            "danger"
          );
        })
        .finally(() => {
          setTimeout(() => {
            this.submit = false;
          }, 2000);
        });
    },
    addAddress() {
      let url = "add-address";
      this.submit = true;
      let loader = this.$loading.show({
        container: this.$refs.addGroupAddressContainer,
      });
      Api.post(url, this.form)
        .then((res) => {
          let response = res.data.message;
          this.$store.dispatch("getGroupAddresses");
          this.$notify(response);
          setTimeout(() => {
            this.resetForm();
            this.$bvModal.hide("add_address_info");
          }, 2000);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally(() => {
          setTimeout(() => {
            loader.hide();
            this.submit = false;
          }, 2000);
        });
    },
    formSubmit() {
      if (this.continue_with_address_validation === false) {
        this.uspsValidate();
      } else {
        this.addAddress();
      }
    },
  },
};
</script>
<style src="@/assets/css/group_dashboard/group_information.css" scoped></style>
<style>
/* .add-group-address-form input,
.add-group-address-form .invalid-feedback {
  width: 250px !important;
}
.add-group-address-form select {
  width: 250px !important;
  display: block !important;
} */
.set_primary {
  padding: 5px;
}
</style>
