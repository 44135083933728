<template>
  <div class="bank_details">
    <h6>Select Bank Details </h6>

    <div class="bankDetails_table" style="overflow-x:auto;">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Account Name</th>
            <th>Routing Number</th>
            <th>Bank Name</th>
            <th>Account Number</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(bank, index) in groupBanks" :key="index">
            <td>
              <b-form-radio
                @change="selectBankHandler(bank)"
                v-model="selectedBank"
                :value="bank"
              />
            </td>
            <td>
              <span>{{ bank.bank_accountname }}</span>
            </td>
            <td>
              <span>{{ bank.bank_routing }}</span>
            </td>
            <td>
              <span>{{ bank.bank_name }}</span>
            </td>
            <td>
              <span>{{ bank.bank_account }}</span>
            </td>
            <td>
              <span>
                {{ bank.account_type ? bank.account_type : "--" }}
              </span>
            </td>
          </tr>
          <tr>
            <td>
              <b-form-radio
                name="some-radios"
                v-model="newBank"
                @change="newBankHandler"
                :value="false"
              />
            </td>
            <td>
              <input
                type="text"
                v-model="form.bank_accountname"
                name="Bank Account Name"
                placeholder="Account Name"
                :disabled="disableAddBank"
                :autofocus="true"
              />
            </td>
            <td>
              <input
                type="number"
                v-model="form.bank_routing"
                name="Routing Number"
                placeholder="Routing Number"
                class="number-input"
                maxlength="9"
                :disabled="disableAddBank"
                @input="checkRouting"
              />
            </td>
            <td>
              <input
                type="text"
                v-model="form.bank_name"
                name="Bank Name"
                placeholder="Bank Name"
              />
            </td>
            <td>
              <input
                type="number"
                v-model="form.bank_account_full"
                class="number-input"
                name="Account Number"
                placeholder="Account Number"
                maxlength="16"
                :disabled="disableAddBank"
              />
            </td>
            <td>
              <select v-model="form.account_type" :disabled="disableAddBank">
                <option value="">Select Any</option>
                <option value="savings">Saving</option>
                <option value="checking">Checking </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="new-bank-note p-3" v-if="!selectedBank">
      <p class="strong warning">Note : Fill the details for new bank.</p>
      <p class="strong warning" style="color:red" v-if="invalidBankRoutingText">
        {{ invalidBankRoutingText }}
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { validateRoutingNumberUrl } from "@/resource/EndPoints/endpoints";
import axios from "axios";

export default {
  data: () => {
    return {
      selectedBank: null,
      newBank: null,
      disableAddBank: true,
      form: {
        bank_account_full: "",
        account_type: "",
        bank_accountname: "",
        bank_name: "",
        bank_routing: "",
      },
      invalidBankRoutingText: "",
    };
  },
  computed: {
    ...mapGetters({ groupBanks: "groupBanks" }),
  },
  methods: {
    checkRouting() {
      let url, pattern, routingNumber;
      routingNumber = this.form.bank_routing;
      url = `${validateRoutingNumberUrl}${routingNumber}`;
      pattern = new RegExp("^\\d{9}$");
      this.form.bank_name = "";
      if (routingNumber.match(pattern)) {
        const otherUrl = axios.create({});
        otherUrl
          .get(url)
          .then((res) => {
            this.form.bank_name = "";
            if (res.data.statusCode == 200) {
              this.form.bank_name = res.data.data.result.bank_name;
              this.invalidBankRoutingText = "";
            } else if (res.data.code == 404) {
              this.form.bank_name = "";
              this.invalidBankRoutingText = "Invalid Routing Number.";
            }
          })
          .catch((err) => {
            this.form.bank_name = "";
            this.$notify("Bank not found", "Error", "danger");
            this.invalidBankRoutingText = "Invalid Routing Number.";
          });
      }
    },
    newBankHandler() {
      this.selectedBank = null;
      this.disableAddBank = false;
      this.$emit("setSelectedBank", null);
    },
    selectBankHandler(bank) {
      this.newBank = null;
      this.disableAddBank = true;
      this.form = {
        bank_account: "",
        account_type: "",
        bank_accountname: "",
        bank_name: "",
        bank_routing: "",
      };
      this.selectedBank = bank;
      this.$emit("setSelectedBank", bank);
    },
    defaultPrimayBank() {
      let primaryBank;
      primaryBank = this.groupBanks.filter((item) => {
        if (item.is_primary) {
          return item;
        }
      });
      this.selectBankHandler(primaryBank[0]);
    },
  },
  mounted() {
    this.defaultPrimayBank();
  },
  watch: {
    form: {
      handler: function(val) {
        if (
          val.bank_account_full &&
          val.account_type &&
          val.bank_accountname &&
          val.bank_name &&
          val.bank_routing
        ) {
          this.$emit("setSelectedBank", val);
        }
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.number-input {
  background: transparent;
  border: 0px;
  outline: none;
}
</style>
