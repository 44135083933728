<template>
  <div class="messages_container">
    <div>
      <div class="messages message-wrap">
        <div class="message-section">
          <div class="title">
            <h2>Messages</h2>
            <template v-if="receivedMessages.length > 0">
              <router-link
                class="link-hover"
                :to="{ name: 'ReceivedMessageList' }"
              >
                <span class="view-all">View All</span>
              </router-link>
            </template>
          </div>
          <div class="column column-msg" v-if="receivedMessages.length > 0">
            <div
              class="row"
              v-for="(item, index) in receivedMessages.slice(0, 10)"
              :key="index"
            >
              <div class="message_information" @click="detail(item)">
                <img src="@/assets/images/chatting.svg" alt="chatting" />
                <div>
                  <span class="message_title"> {{ item.sender_name }} </span>
                  <br />
                  <span class="message_subject">
                    {{ item.subject | truncate(20) }}
                  </span>
                </div>
              </div>
              <div>
                <span class="message_opens">
                  {{ item.from }}
                </span>
                <br />
                <span class="message_date">
                  {{ formattedDate(item.date_created) }}
                </span>
              </div>
            </div>
          </div>
          <div class="column column-msg" v-else>
            <no-content text="No messages found." />
          </div>
        </div>
        <div class="personalize-list">
          <div class="title">
            <h2>Personalize Sites</h2>
            <custom-button
              iconClass="fas fa-edit"
              @onClicked="editSite"
              title="edit"
            />
          </div>
          <vue-scroll :ops="personalScroll">
            <div
              class="
                dashpersonalized-card dashpersonalized-body
                personalize-body
              "
            >
              <ul class="h-100">
                <li
                  v-for="(site, key) in sites"
                  :key="key"
                  @click="activeList = key"
                  :class="activeList == key ? 'active' : ''"
                >
                  <div class="d-flex">
                    <div class="personalize-logo">
                      <img :src="site.image" alt="" class="img-fluid" />
                    </div>
                    <div class="personalize-content">
                      <h5>{{ site.name }}</h5>
                      <span class="url-site">
                        {{ site.domain }}
                      </span>
                    </div>
                  </div>
                  <div class="click-toggler link-btn mt-3">
                    <a
                      class="visit-portal attachment"
                      target="_blank"
                      :href="site.domain"
                    >
                      Visit Portal
                    </a>
                    <a
                      class="send-email attachment"
                      @click="sendPersonalizedEmail(site.domain)"
                    >
                      <span> Send Email </span>
                    </a>
                    <a
                      href="JavaScript:void(0)"
                      class="copy-link"
                      @click="copyLink(site.domain)"
                    >
                      <span class="code"> Copy Link </span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </vue-scroll>
        </div>
      </div>
      <received-message-detail :data="receivedMessageDetail" />
      <personalize-emailsend
          :selected-link="selectedLink"
          @onCloseModal="selectedLink = ''"
        />
        <personalized-site-edit
          :web-access-code.sync="webAccessCode"
          :updateUrl="updateLandingPageUrl"
          @onSuccess="getSites"
        />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ReceivedMessageDetail from "../ReceivedMessage/ReceivedMessageDetail.vue";
import PersonalizeEmailsend from "../../components/common/PersonalizeEmailsend.vue";
import PersonalizedSiteEdit from "../../components/common/PersonalizedSiteEdit.vue";
import CustomButton from '../../components/ui/CustomButton.vue';
import axios from "axios";

export default {
  components: {
    ReceivedMessageDetail,
    PersonalizeEmailsend,
    PersonalizedSiteEdit,
    CustomButton,
  },
  data: () => {
    return {
      receivedMessages: [],
      receivedMessageDetail: {},

      personalScroll: {
          vuescroll: {
            mode: "native",
            wheelScrollDuration: "700",
          },
          scrollPanel: {
            maxHeight: 480,
            minHeight: 480,
          },
          bar: {
            background: "#ddd",
          },
        },
        sites: [],
        webAccessCode: "",
        activeList: null,
        row: null,
        selectedLink: "",
        updateLandingPageUrl: "update-personalized-site",
    };
  },
  methods: {
     sendPersonalizedEmail(link) {
        this.selectedLink = link;
        this.$bvModal.show("personalizedSiteSendEmail");
      },
      getSites() {
        let url = process.env.VUE_APP_API_URL_V2 + `get-personalized-site`;
        axios
          .get(url)
          .then((res) => {
            let data = res.data.data;
            this.sites = data.sites;
            this.webAccessCode = data.web_access_code;
          })
          .catch(() => {
            this.sites = [];
            this.webAccessCode = "";
          });
      },
      copyLink(text) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            this.$notify("Link Copied Successfully.");
          })
          .catch(() => {
            this.$notify("Failed to copy link.", "error");
          });
      },
      openModal(row, modalId) {
        this.row = row;
        this.$bvModal.show(modalId);
      },
      editSite() {
        this.$bvModal.show("updatePersonalizedSite");
      },
    detail(item) {
      this.receivedMessageDetail = item;
      this.$bvModal.show("received-message-detail");
    },
    formattedDate(date) {
      if (moment(date, "YYYY/MM/DD HH:mm:ss", true).isValid()) {
        return moment(date, "YYYY/MM/DD HH:mm:ss", true).format("MM-DD-YYYY");
      }
      return "N/A";
    },
    getReceivedMessages() {
      //listing only 10 items
      let url =
        process.env.VUE_APP_API_URL_V2 +
        `get-received-message-list?page_size=10`;
      axios.get(url).then((res) => {
        this.receivedMessages = res.data.records;
      });
    },
  },
  created() {
    this.getReceivedMessages();
    this.getSites();
  },
};
</script>
<style src="@/assets/css/group_dashboard/messages.css" scoped></style>
