<template>
  <div class="compose-ticket-wrap compose_message">
    
    <b-form @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-group id="input-group-1" label="Subject *" label-for="input-5">
        <b-form-input
          id="input-5"
          v-model="form.name"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" label="Category" label-for="input-6">
        <b-form-select v-model="selected" :options="options" size="sm"
          id="input-6"
          required
        >
        <b-form-select-option :value="null">Choose Category</b-form-select-option>
        <b-form-select-option value="Account">Account</b-form-select-option>
        <b-form-select-option value="Billing">Billing</b-form-select-option>
        </b-form-select>
      </b-form-group>
      

      <b-form-group
          label="Detail *"
          id="input-group-1"
          label-for="input-6"
          class="col-md-12"
        >
        <b-form-textarea
          id="input-6"
          v-model="text1"
          placeholder="Write a message"
          :formatter="formatter"
        ></b-form-textarea>
        <div class="attach-file">
            <label class="btn btn-default">
            <input type="file" hidden>
            <i class="fas fa-paperclip"></i>
          </label>
        </div>
      </b-form-group>

      <div class="ticket-form-action" id="input-group-2">
        <b-button type="submit" class="ticket-submit-btn">Submit</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
export default {
  name: "TicketingCompose",
  data() {
      return {
        form: {
          email: '',
          name: '',
        },
        show: true,
        selected: null,
      }
    },
}
</script>

<style
  src="@/assets/css/group_dashboard/admin_message_center.css"
  scoped
></style>
<style scoped>
.form-group input {
  border: 1px solid #E8EAEB!important;
  background: #f7fafc;
}
.ticket-form-action {
  display: block;
  width: 100%;
  margin-left: 25px;
  margin-top: 25px;
}
#input-group-1 textarea {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #DDDDDD;
  border-bottom: none;
  min-height: 100px;
}
.attach-file {
  padding: 3px;
  position: relative;
  background: #dce5ec94;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.attach-file label {
  margin-bottom: 0;
  height: 35px;
  width: 37px;
  border-radius: 50px;
  margin-left: 3px;
}
.attach-file svg {
  width: 18px;
  height: 18px;
  margin-left: -4px;
  margin-top: 1px;
  color: #707070;
}
.ticket-submit-btn {
  background-image: linear-gradient( #00CCFF 0%, #1597B8 49%, #0567BC 100%)!important;
  color: #fff;
  border: 1px solid #03c3ec;
  padding: 8px 40px;
  text-align: center;
  border-radius: 60px;
  font-size: 14px;
  margin-top: 5px;
}
.compose_message {
  padding-top: 30px!important;
}
@media (min-width: 1205px) {
  .ticket-form-action {
  margin-left: 40px;
}
}
</style>
