<template>
  <img
    @click="closeModal"
    class="close_cross"
    src="@/assets/images/cross.svg"
    alt="cross"
  />
</template>
<script>
export default {
  name: "CloseModalIcon",
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
