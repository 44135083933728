<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="admin_message_wrapper">
        <breadcrumb
          :items="breadcrumbItems"
          class="received-messages-breadcrumb"
        />
        <div class="all_messages">
          <div class="messages">
            <div class="title">
              <h2>Messages</h2>
            </div>
            <div class="column">
              <div
                v-for="(item, index) in receivedMessages.records"
                :key="index"
                @click="showCurrentMessage(item)"
                class="row"
                :class="
                  showMessage
                    ? showMessage.mid == item.mid
                      ? 'active_background'
                      : ''
                    : ''
                "
              >
                <div class="message_information">
                  <img src="@/assets/images/chatting.svg" alt="chatting" />
                  <div>
                    <span class="message_title"> {{ item.sender_name }} </span>
                    <br />
                    <span class="message_subject">
                      {{ item.subject | truncate(25) }}
                    </span>
                  </div>
                </div>
                <div>
                  <span class="message_opens">
                    {{ item.from }}
                  </span>
                  <br />
                  <span class="message_date">
                    {{ formattedDate(item.date_created) }}
                  </span>
                </div>
              </div>
              <small>
                <pagination :rows.sync="receivedMessages" :filters="[]" />
              </small>
            </div>
          </div>

          <div class="main_messages_container">
            <div class="message_description_container" v-if="showMessage">
              <div class="date">
                {{ formattedDetailDate }}
              </div>
              <div class="message_description">
                <div class="delete-message">
                  <a
                    href="javscript:void(0);"
                    @click="deleteMessage(showMessage.mid)"
                  >
                    <i class="fa fa-trash" />
                  </a>
                </div>
                <div class="arrow-left"></div>
                <div class="message_title">
                  <span>From: {{ showMessage.sender_name }}</span>
                  <span>{{ showMessage.from }}</span>
                </div>
                <h2>{{ showMessage.subject }}</h2>
                <div class="message_content">
                  <p v-html="showMessage.message" />
                  <br />
                  <div
                    v-if="
                      showMessage.attachments &&
                        showMessage.attachments.length > 0
                    "
                  >
                    <h6>Attachments:</h6>
                    <ul style="list-style:none">
                      <li
                        v-for="file in showMessage.attachments"
                        :key="file.id"
                      >
                        <a :href="file.file_url" target="__blank" download>
                          <i class="fa fa-file"></i>
                          {{ file.title }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Api from "@/resource/Api";
import axios from "axios";

export default {
  data() {
    return {
      showMessage: null,
      receivedMessages: {},
      breadcrumbItems: [{ title: "All Received Messages", active: true }],
    };
  },
  computed: {
    formattedDetailDate() {
      let date = this.showMessage.date_created;
      if (moment(date, "YYYY/MM/DD HH:mm:ss", true).isValid()) {
        return moment(date, "YYYY/MM/DD HH:mm:ss", true).format("gg MMM , ddd");
      }
      return "N/A";
    },
  },
  methods: {
    formattedDate(date) {
      if (moment(date, "YYYY/MM/DD HH:mm:ss", true).isValid()) {
        return moment(date, "YYYY/MM/DD HH:mm:ss", true).format("MM-DD-YYYY");
      }
      return "N/A";
    },
    showCurrentMessage(item) {
      this.showMessage = item;
      let url =
        process.env.VUE_APP_API_URL_V2 +
        `update-message-seen-status?mid=` +
        item.mid;
      axios.put(url).then((res) => {});
    },
    getReceivedMessages() {
      let url =
        process.env.VUE_APP_API_URL_V2 +
        `get-received-message-list?page_size=10`;
      let loader = this.$loading.show();
      axios
        .get(url)
        .then((res) => {
          this.receivedMessages = res.data;
          this.showMessage = this.receivedMessages.records[0];

          if (this.receivedMessages.records.length < 1) {
            this.$router.push({ name: "GroupDashboard" });
          }
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          loader.hide();
        });
    },
    deleteMessage(messageId) {
      let url =
        process.env.VUE_APP_API_URL_V2 +
        `delete-received-message?mid=${messageId}&from=Rep`;
      this.$store.commit("setDialogClass", "group-app-warning-dialog");
      this.$confirm({
        title: "Delete Message",
        message: `Do you want to delete this message?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            axios
              .delete(url)
              .then((res) => {
                let response = res.data.message;
                this.getReceivedMessages();
                this.$notify(response);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
  created() {
    this.getReceivedMessages();
  },
  watch: {
    receivedMessages: function(newData, oldData) {
      if (newData != oldData) {
        this.showMessage = newData.records[0];
      }
    },
  },
};
</script>
<style src="@/assets/css/group_dashboard/all_messages.css" scoped></style>
