<template>
  <div class="benefit_store">
    <h2>Benefit Store</h2>
    <carousel
      class="benefit_store_detail"
      :perPage="1"
      :autoplay="true"
      :adjustableHeight="true"
      :paginationEnabled="false"
      :loop="true"
    >
      <slide
        @slideclick="benefitStoreClickHandler"
      >
        <div class="benefit_title">
          <div class="benefit_icon">
            <img src="@/assets/images/benefit_store_logo_mono.svg" alt="Benefit Store" class="benefit_store_logo"/>
          </div>
          <h4>Go to Benefit Store</h4>
        </div>
        <p class="title_detail">View and apply for additional benefits</p>
      </slide>
      <!-- <slide>
        <div class="title">
          <div class="benefit_icon"></div>
          <h4>Lorem ipsum. slide 2</h4>
        </div>
        <p class="title_detail">
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam
        </p>
      </slide> -->
    </carousel>
  </div>
</template>
<script>
import {benefitStoreUrl} from "@/resource/EndPoints/endpoints"
export default {
  methods: {
    benefitStoreClickHandler() {
      // let url = `${benefitStoreUrl}/group-home?group_id=${btoa(this.$store.getters.groupInfo.gid)}`;
      let url = this.$store.getters.status.benefit_store_link
      window.open(url, "_blank");
    },
  },
};
</script>
<style src="@/assets/css/group_dashboard/benefit_store.css" scoped></style>
<style scoped>
.benefit_store_detail:hover{
    cursor: pointer;
}
.benefit_store_logo{
  width: 100%;
}
</style>