<template>
  <div class="group_address">
    <div v-slimscroll="groupAdressScroll" class="table_container">
      <table>
        <thead>
          <tr>
            <td>
              Address
              <span
                v-b-modal.add_address_info
                v-tooltip="'Add Address'"
                class="plus-circle"
              >
                <img src="@/assets/images/plus-circle.svg" width="21" />
              </span>
            </td>
            <td>Status</td>
            <td>Action</td>
          </tr>
        </thead>
        <div></div>
        <tbody v-if="groupAddresses.length > 0">
          <tr v-for="(item, index) in groupAddresses" :key="index">
            <td>
              <span class="street_address">
                {{ item.address1 }}
                <span v-tooltip="'Verified'" v-if="item.usps_verified == true" >
                  <img  src="@/assets/images/check.svg" width="13" />
                </span>
                <span v-tooltip="'Not Verified'" v-else>
                  <img src="@/assets/images/inactive.svg" width="13" />
                </span>
              </span>
              <span class="state_address">
                {{ item.city }}, {{ item.state }} {{ item.zip }}
              </span>
              <span class="b" v-if="item.type == 'billing'">Bill</span>
              <span class="h" v-if="item.type == 'business'">Biz</span>
            </td>
            <td>
              <div  v-if="item.is_primary == true" class="address_status">
                <img
                  src="@/assets/images/Override_Indication.svg"
                  style="margin-right: 5px"
                />
                Active
              </div>
              <div v-tooltip="'Set as Primary'" v-else class="set_primary">
                <button @click.prevent="setPrimaryAddress(item)">
                  Set as Primary
                </button>
              </div>
            </td>
            <td>
              <span
                class="delete"
                v-tooltip="'Delete'"
                v-if="item.is_primary == false"
              >
                <a
                  href="javascript:void(0);"
                  @click.prevent="deletePrimaryAddress(item)"
                >
                  <img src="@/assets/images/group_del.svg" width="23" />
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <template v-if="groupAddresses.length < 1">
          <no-content text="No group address found." />
        </template>
    </div>
    <AddGroupAddress />
  </div>
</template>

<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import AddGroupAddress from "./AddGroupAddress";

export default {
  data: function() {
    return {
      groupAdressScroll: {
        height: "242px",
        size: "5px",
      },
    };
  },
  components: {
    AddGroupAddress,
  },
  computed: {
    ...mapGetters({
      groupAddresses: "groupAddresses",
    }),
  },
  methods: {
    setPrimaryAddress(row) {
      let url = "set-primary-address";
      this.$store.commit("setDialogClass", "group-app-confirm-dialog");
      this.$confirm({
        title: "Set Primary Address",
        message: `Do you want to set this ${row.address1} to primary?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.put(url, {
              id: row.id,
              type: row.type,
            })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$store.dispatch("getGroupAddresses");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    deletePrimaryAddress(row) {
      let url = "delete-address";
      this.$store.commit("setDialogClass", "group-app-warning-dialog");
      this.$confirm({
        title: "Delete Address",
        message: `Do you want to delete this ${row.address1}?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url, {
              id: row.id,
              type: row.type,
            })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.$store.dispatch("getGroupAddresses");
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
};
</script>

<style src="@/assets/css/group_dashboard/group_information.css" scoped></style>
