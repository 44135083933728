import { render, staticRenderFns } from "./KnowledgeCenter.vue?vue&type=template&id=1a23979c&scoped=true&"
import script from "./KnowledgeCenter.vue?vue&type=script&lang=js&"
export * from "./KnowledgeCenter.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/group_dashboard/messages.css?vue&type=style&index=0&id=1a23979c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a23979c",
  null
  
)

export default component.exports