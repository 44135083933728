<template>
  <div>
    <!-- logo upload guideline modal -->
    <b-modal
      id="modal-upload"
      centered
      size="lg"
      title="Upload Logo"
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <div class="title">
        <h2>Upload File</h2>
        <span>
          Choose your file here
        </span>
      </div>
      <img
        @click="$bvModal.hide('modal-upload')"
        class="close_cross"
        src="@/assets/images/cross.svg"
        alt="cross"
      />
      <div class="modal_upload">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(upload)" ref="uploadLogoContainer">
            <div class="upload_section">
              <ValidationProvider
                :rules="{required:true,size:2048}"
                name="Group logo"
              >
                <b-form-group slot-scope="{ valid, errors }" >
                  <b-form-file
                    id="upload_image"
                    browse-text="Choose File"
                    placeholder="Choose a file or drop it here..."
                    accept="image/*"
                    v-model="file"
                    :state="errors[0] ? false : valid ? true : null"
                    @change="fileHandler"
                  >
                  </b-form-file>
                  <b-form-invalid-feedback>
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div>
            <div class="upload_guideline">
              <h2>Logo Upload Guideline.</h2>
              <ul>
                <li>Always use high Quality logo Files.</li>
                <li>Use PNG with Transparency for best results.</li>
                <li>
                  Follow the guideline as given below for the orientation of
                  your logo as applicable
                </li>
              </ul>
            </div>
            <div class="guideline_shape">
              <div class="guideline_rectangle">
                <h2>Logo Upload Guideline - Rectangle</h2>
                <div class="upload_rectangle">
                  <div class="long-arrow-left"></div>
                  <span class="breathing_room"
                    >15px Breathing room around the logo for better
                    visibility</span
                  >
                  <div class="rectangle_logo">
                    <img src="@/assets/images/group_logo.png" />
                  </div>

                  <div class="long-arrow-right"></div>
                  <span class="logo_width">250 X 90px</span>
                </div>
              </div>
              <!-- <div class="guideline_square">
                <h2>Logo Upload Guideline - Square</h2>
                <div class="upload_square">
                  <div class="long-arrow-left"></div>
                  <span class="breathing_room"
                    >15px Breathing room around the logo for better
                    visibility</span
                  >
                  <div class="square_logo">
                    <img src="@/assets/images/one_plus.png" />
                  </div>
                  <div class="long-arrow-right"></div>
                  <span class="logo_width">100 X 100px</span>
                </div>
              </div> -->
              <div class="upload-submit">
                <b-form-group class="submit">
                  <button :disabled="submit" class="btn btn-primary">
                    {{ submit ? "Uploading..." : "Upload" }}
                  </button>
                </b-form-group>
              </div>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  data: () => {
    return {
      file: null,
      submit: false,
    };
  },
  computed:{
    dimensionValue(){
      return "250,90" ||  "100,100"
    }
  },
  methods: {
    fileHandler(e) {
      this.file = e.target.files[0];
    },
    resetForm() {
      this.file = null;
    },
    upload() {
      let url = "update-group-logo";
      this.submit = true;
      let formData = new FormData();
      formData.append("group_logo", this.file);
        let loader = this.$loading.show({
        container: this.$refs.uploadLogoContainer,
      });

      Api.post(url, formData)
        .then((res) => {
          let response = res.data.message;
          this.$store.dispatch("getGroupInfo");
          this.$notify(response);
          setTimeout(() => {
            this.resetForm();
            this.$bvModal.hide("modal-upload");
          }, 2000);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally(() => {
          setTimeout(() => {
             loader.hide();
            this.submit = false;
          }, 2000);
        });
    },
  },
};
</script>
<style src="@/assets/css/group_dashboard/group_information.css" scoped></style>
