<template>
  <div id="app">
    <vue-confirm-dialog :class="dialogClass"></vue-confirm-dialog>
    <template v-if="!pageNotFound">
      <div v-if="defaultHeader">
        <DefaultHeader />
      </div>
      <div v-else>
        <template v-if="!registrationProcess">
          <GroupDashboardHeader />
          <GroupDashboardMain />
        </template>
      </div>
      <router-view />
      <div v-if="defaultFooter" class="default_footer">
        <GroupFooter />
      </div>
      <div v-else class="group_footer">
        <template v-if="!registrationProcess">
          <GroupFooter />
        </template>
      </div>
    </template>
    <template v-else>
      <page-not-found />
    </template>
    <app-download-modal :appUrl="appDownloadUrl" />
  </div>
</template>
<script>
import GroupDashboardMain from "@/components/layout/GroupDashboardMain";
import GroupDashboardHeader from "@/components/layout/GroupDashboardHeader";
import GroupFooter from "@/components/layout/GroupFooter";
import DefaultHeader from "@/components/layout/DefaultHeader";
import PageNotFound from "@/components/common/PageNotFound";
import { mapGetters } from "vuex";
import AppDownloadModal from "@/components/common/AppDownloadModal.vue";
import { ANDROID_APP_URL, IOS_APP_URL } from "./resource/EndPoints/endpoints";
import moment from "moment";
import DeviceHelper from "@/resource/DeviceHelper";
export default {
  components: {
    GroupDashboardHeader,
    GroupDashboardMain,
    GroupFooter,
    DefaultHeader,
    PageNotFound,
    AppDownloadModal,
  },
  data() {
    return {
      defaultHeader: false,
      defaultFooter: false,
      pageNotFound: false,
      registrationProcess: false,
      appDownloadUrl: "",
    };
  },
  computed: {
    ...mapGetters({ dialogClass: "dialogClass" }),
  },
  methods: {
    checkUserDevice() {
      let device = new DeviceHelper();
      if (device.isAndroid()) {
        this.appDownloadUrl = ANDROID_APP_URL;
        this.appDownloadModal();
      } else if (device.isIos()) {
        this.appDownloadUrl = IOS_APP_URL;
        this.appDownloadModal();
      }
    },
    appDownloadModal() {
      if (localStorage.use_web_status && localStorage.status_expiry) {
        let expiryDate = moment(localStorage.status_expiry);
        let currentDate = moment(new Date());
        let duration = moment.duration(expiryDate.diff(currentDate));
        if (duration.asHours() < 0) {
          localStorage.use_web_status = "";
          localStorage.status_expiry = "";
          this.$bvModal.show("app-download-modal");
        }
      } else {
        this.$bvModal.show("app-download-modal");
      }
    },
  },
  mounted() {
    this.$store.dispatch("getDialogClass");
    if (
      this.$route.name == "Login" ||
      this.$route.name == "SignUp" ||
      this.$route.name == "SignUpContinue" ||
      this.$route.name == "VerifyOTP" ||
      this.$route.name == "VerifyPasswordOTP" ||
      this.$route.name == "SendCode" ||
      this.$route.name == "LoginOTP" ||
      this.$route.name == "ForgotPassword" ||
      this.$route.name == "PasswordChange" ||
      this.$route.name == "ResetPassword" ||
      this.$route.name == "LoginSave"
    ) {
      this.defaultFooter = true;
      this.defaultHeader = true;
    } else {
      this.defaultFooter = false;
      this.defaultHeader = false;
    }
    if (this.$route.name == "PageNotFound") {
      this.pageNotFound = true;
    }
    if (
      this.$route.name == "Registration" ||
      this.$route.name == "RegisterSuccess"
    ) {
      this.defaultHeader = true;
      this.defaultFooter = false;
      this.registrationProcess = true;
    }
    setTimeout(() => {
      this.checkUserDevice();
    }, 2000);
  },
  watch: {
    "$route.params": {
      handler() {
        if (
          this.$route.name == "Login" ||
          this.$route.name == "SignUp" ||
          this.$route.name == "SignUpContinue" ||
          this.$route.name == "VerifyOTP" ||
          this.$route.name == "VerifyPasswordOTP" ||
          this.$route.name == "SendCode" ||
          this.$route.name == "LoginOTP" ||
          this.$route.name == "ForgotPassword" ||
          this.$route.name == "PasswordChange" ||
          this.$route.name == "ResetPassword" ||
          this.$route.name == "LoginSave"
        ) {
          this.defaultFooter = true;
          this.defaultHeader = true;
        } else {
          this.defaultFooter = false;
          this.defaultHeader = false;
        }
        if (
          this.$route.name == "Registration" ||
          this.$route.name == "RegisterSuccess"
        ) {
          this.defaultHeader = true;
          this.defaultFooter = false;
          this.registrationProcess = true;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
@import "./assets/css/vue-confirm-dialog.css";
@import "./assets/css/app.css";
@import "./assets/css/vue-multiselect.css";
</style>
