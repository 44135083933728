<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="change_password_wrapper">
        <div class="change_password">
          <div class="title">
            <div class="key_icon">
              <i class="fas fa-key"></i>
            </div>
            <div class="title_text">
              <h2>Change Password</h2>
              <span>Please confirm your current password</span>
            </div>
          </div>

          <div class="change_password_form">
            <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
              <b-form @submit.prevent="handleSubmit(changePassword)">
                <ValidationProvider
                  rules="required"
                  name="Current Password"
                  vid="old_password"
                >
                  <b-form-group slot-scope="{ valid, errors }">
                    <label>
                      <span>
                        Current Password <span class="input-required">*</span>
                      </span>
                    </label>
                    <div
                      class="changeForm-group"
                      :class="errors[0] || valid ? 'errorIcon_eye' : ''"
                    >
                      <b-form-input
                        id="old_password"
                        type="password"
                        v-model="form.old_password"
                        :state="errors[0] ? false : valid ? true : null"
                        placeholder="Enter Current Password"
                      >
                      </b-form-input>
                      <eye-button
                        id="old_password"
                        class="toggleSSN toggle-eye-icon"
                        type="text"
                      ></eye-button>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </ValidationProvider>

                <ValidationProvider
                  rules="required"
                  name="New Password"
                  vid="new_password"
                >
                  <b-form-group slot-scope="{ valid, errors }">
                    <label>
                      <span>
                        New Password <span class="input-required">*</span>
                      </span>
                    </label>
                    <div
                      class="changeForm-group"
                      :class="errors[0] || valid ? 'errorIcon_eye' : ''"
                    >
                      <b-form-input
                        id="new_password"
                        type="password"
                        v-model="form.new_password"
                        :state="errors[0] ? false : valid ? true : null"
                        placeholder="Enter New Password"
                      >
                      </b-form-input>
                      <eye-button
                        id="new_password"
                        class="toggleSSN toggle-eye-icon"
                        type="text"
                      ></eye-button>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div>
                  <password-strength-bar :password="form.new_password">
                  </password-strength-bar>
                </div>
                <ValidationProvider
                  rules="required|confirmed:new_password"
                  name="Confirm Password"
                >
                  <b-form-group slot-scope="{ valid, errors }">
                    <label>
                      <span>
                        Confirm Password <span class="input-required">*</span>
                      </span>
                    </label>
                    <div class="changeForm-group errorIcon_eye">
                      <b-form-input
                        type="password"
                        id="confirm_password"
                        v-model="confirmPassword"
                        :state="errors[0] ? false : valid ? true : null"
                        placeholder="Enter Confirmation Password"
                      >
                      </b-form-input>
                      <eye-button
                        id="confirm_password"
                        class="toggle-eye-icon"
                        hideOnDefault="true"
                        type="text"
                      ></eye-button>
                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </div>
                  </b-form-group>
                </ValidationProvider>
                <div>
                  <span v-if="errorText" class="password_error">
                    {{ errorText }}
                  </span>
                </div>
                <div class="submit_password">
                  <button :disabled="submit">
                    {{ submit ? "Changing..." : "Submit" }}
                  </button>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default {
  data() {
    return {
      form: {
        old_password: "",
        new_password: "",
      },
      confirmPassword: "",
      placeholder: "",
      submit: false,
      errorText: "",
    };
  },
  methods: {
    changePassword() {
      let url = "change-password";
      this.submit = true;
      Api.post(url, this.form)
        .then((res) => {
          let response = res.data.message;
          this.$notify(response);
          this.$router.push({ name: "Logout" });
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
          this.errorText = err.response.data.message;
          setTimeout(() => {
            this.errorText = "";
          }, 2000);
        })
        .finally(() => {
          this.submit = false;
        });
    },
  },
};
</script>
<style scoped>
.input-required {
  color: red;
}
.toggle-eye-icon {
  cursor: pointer;
}
</style>

<style src="@/assets/css/group_dashboard/change_password.css"></style>
