import Api from "@/resource/Api"
import { knowledgeBaseApiUrl } from "@/resource/EndPoints/endpoints"
import axios from "axios"
const state = {
    statusInfo: null,
    groupInfo: null,
    groupInfoActivePlans: 0,
    groupAddresses: [],
    groupPaymentType: null,
    groupBanks: [],
    groupKnowledges: [],
    repInfo: null,
    unPaidInvoices: [],
    invoiceIds: []
}

const getters = {
    status: state => state.statusInfo,
    groupInfo: state => state.groupInfo,
    groupInfoActivePlans: state => state.groupInfoActivePlans,
    groupAddresses: state => state.groupAddresses,
    groupBanks: state => state.groupBanks,
    groupKnowledges: state => state.groupKnowledges,
    repInfo: state => state.repInfo,
    unPaidInvoices: state => state.unPaidInvoices,
    invoiceIds: state => state.invoiceIds,
    groupPaymentType: state => state.groupPaymentType
}

const actions = {
    async getGroupKnowledges({ commit, state }) {
        let url = knowledgeBaseApiUrl;
        const newAxios = axios.create()
        await newAxios.post(url, { "publication_party": ["Group"] })
            .then((res) => {
                let response = res.data.data
                commit('setGroupKnowledges', response)
            }).catch((err) => {
                console.log('err', err)
            })
    },
    // async getReceivedMessages({ commit, state }) {
    //     console.log("state",commit.options)
    //     let url = 'get-received-message-list';
    //     await Api.get(url)
    //         .then((res) => {
    //             let response = res.data
    //             commit('setReceivedMessage', response)
    //         }).catch((err) => {
    //             console.log('err', err)
    //         })
    // },
    async getStatusInfo({ commit, state }) {
        let url = 'get-dashboard-stat';
        await Api.get(url)
            .then((res) => {
                let response = res.data.data
                commit('setStatusInfo', response)
            }).catch((err) => {
                console.log('err', err)
            })
    },
    async getGroupInfo({ commit, state }) {
        let url = "get-personal-info";
        await Api.get(url)
            .then((res) => {
                let response = res.data.data;
                commit('setGroupInfo', response.group_info)
                commit('setGroupInfoActivePlans', response.active_plans)
                commit('setRepInfo', response.rep_info)
            })
            .catch((err) => {
                console.log("err", err);
            });
    },
    async getGroupAddresses({ commit, state }) {
        let url = "get-address-list";
        await Api.get(url)
            .then((res) => {
                let primayItems, notPrimaryItems, items;
                let response = res.data.data;
                primayItems = response.filter((item) => {
                    if (item.is_primary) {
                        return item
                    }
                })
                notPrimaryItems = response.filter((item) => {
                    if (item.is_primary == false) {
                        return item
                    }
                })
                items = [...primayItems, ...notPrimaryItems]
                commit('setGroupAddresses', items)
            })
            .catch((err) => {
                console.log("err", err);
            });
    },
    async getGroupBanks({ commit, state }) {
        let url = "get-bank-list";
        await Api.get(url)
            .then((res) => {
                let primayItems, notPrimaryItems, items;
                commit('setGroupPaymentType', res.data.data.payment_type)
                let response = res.data.data.bank_list;
                primayItems = response.filter((item) => {
                    if (item.is_primary) {
                        return item
                    }
                })
                notPrimaryItems = response.filter((item) => {
                    if (item.is_primary == false) {
                        return item
                    }
                })
                items = [...primayItems, ...notPrimaryItems]
                commit('setGroupBanks', items)
            })
            .catch((err) => {
                console.log("err", err);
            });
    },
}

const mutations = {
    setRepInfo(state, data) {
        state.repInfo = data
    },
    setGroupKnowledges(state, data) {
        state.groupKnowledges = data
    },
    // setReceivedMessage(state, data) {
    //     state.receivedMessages = data
    // },
    setGroupInvoices(state, data) {
        state.groupInvoices = data
    },
    setStatusInfo(state, data) {
        state.statusInfo = data
    },
    setGroupInfo(state, data) {
        state.groupInfo = data
    },
    setGroupInfoActivePlans(state, data) {
        state.groupInfoActivePlans = data
    },
    setGroupAddresses(state, data) {
        state.groupAddresses = data
    },
    setGroupBanks(state, data) {
        state.groupBanks = data
    },
    setUnPaidInvoices(state, data) {
        state.unPaidInvoices = data
    },
    setInvoiceIds(state, data) {
        state.invoiceIds = data
    },
    setGroupPaymentType(state, data) {
        state.groupPaymentType = data
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}