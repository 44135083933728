<template>
  <div class="status">
    <div class="active">
      <div class="text">
        <p>Active</p>
        <span>Total Policies</span>
        <p class="status_count">{{ status.active_total }}</p>
        <span class="active-statusDetail">
          <router-link :to="{name:'MemberList',params:{status:'Active'}}">
            View Detail
          </router-link>
        </span>
      </div>
      <div class="percent">
        <circle-counter
          size="100px"
          dash-spacing="0"
          dash-count="100"
          stroke-width="8"
          active-stroke="#3BB54A"
          active-width="8"
          :active-count="status.active_percentage"
          text="Active"
          :percent="status.active_percentage"
        />
      </div>
    </div>
    <div class="withdrawn">
      <div class="text">
        <p>Withdrawn</p>
        <span>Total Policies</span>
        <p class="status_count">{{ status.withdrawn_total }}</p>
        <span class="withdrawn-statusDetail">
          <router-link :to="{name:'ViewMember',params:{status:'Withdrawn'}}">
            View Detail
          </router-link>
        </span>
      </div>
      <div class="percent">
        <circle-counter
          size="100px"
          dash-spacing="0"
          dash-count="100"
          stroke-width="8"
          active-stroke="#FEBF5E"
          active-width="8"
          :active-count="status.withdrawn_percentage"
          text="Withdrawn"
          :percent="status.withdrawn_percentage"
        />
      </div>
    </div>
    <div class="termed">
      <div class="text">
        <p>Termed</p>
        <span>Total Policies</span>
        <p class="status_count">{{ status.termed_total }}</p>
         <span class="termed-statusDetail">
          <router-link :to="{name:'ViewMember',params:{status:'Termed'}}">
            View Detail
          </router-link>
        </span>
      </div>
      <div class="percent">
        <circle-counter
          size="100px"
          dash-spacing="0"
          dash-count="100"
          stroke-width="8"
          active-stroke="#FF3D57"
          active-width="8"
          :active-count="status.termed_percentage"
          text="Termed"
          :percent="status.termed_percentage"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      status: "status",
    }),
  },
};
</script>

<style src="@/assets/css/group_dashboard/status_info.css" scoped></style>
