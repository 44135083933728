import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Helper from "@/resource/Helper"
import PageNotFound from "@/components/common/PageNotFound"
import Login from "@/pages/Auth/Login/Login"
import LoginOTP from "@/pages/Auth/Login/LoginOTP"
import LoginSave from "@/pages/Auth/Login/LoginSave"
import ForgotPassword from "@/pages/Auth/ForgotPassword/ForgotPassword"
import SendCode from "@/pages/Auth/ForgotPassword/SendCode"
import VerifyPasswordOTP from "@/pages/Auth/ForgotPassword/VerifyPasswordOTP"
import ResetPassword from "@/pages/Auth/ForgotPassword/ResetPassword"
import Signup from "@/pages/Auth/Signup/Signup"
import SignupContinue from "@/pages/Auth/Signup/SignupContinue"
import GroupDashboard from "@/pages/Dashboard/GroupDashboard"
import UserList from "@/pages/User/UserList"
import MemberList from "@/pages/Member/MemberList"
import MemberDetail from "@/pages/Member/MemberDetail"
import FileList from "@/pages/File/FileList"
import AccountInformation from "@/pages/AccountInformation/AccountInformation"
import BankingInformation from "@/pages/Bank/BankingInformation"
import ChangePassword from "@/pages/Setting/ChangePassword"
import ReceivedMessageList from "@/pages/ReceivedMessage/ReceivedMessageList"
import InvoiceDetail from "@/pages/Invoice/InvoiceDetail"
import AdminMessageCenter from "@/pages/AdminMessage/AdminMessageCenter"
import DisplaySetting from "@/pages/Setting/DisplaySetting"
import MemberContactReport from "@/pages/Report/MemberContactReport"
import KnowledegeCenter from "@/pages/KnowledgeCenter/KnowledgeCenter"
import PlanConfigurationList from "@/pages/PlanConfiguration/PlanConfigurationList"
import Ticketing from "@/pages/Ticketing/Ticketing"
import DirectoryList from "@/pages/Directory/DirectoryList";
Vue.use(VueRouter)
const routes = [
    {
        path: "/logout",
        name: "Logout",
        beforeEnter: (to, from, next) => {
            Helper.clearToken()
            next({
                path: "/"
            })
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },
        meta: {
            title: 'Login'
        }
    },

    {
        path: '/sign-up',
        name: 'Signup',
        component: Signup,
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },
        meta: {
            title: 'Sign Up'
        }

    },
    {
        path: '/sign-up-continue',
        name: 'SignupContinue',
        component: SignupContinue,
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },
        meta: {
            title: 'Sign Up'
        }

    },
    {
        path: '/send-code',
        name: 'SendCode',
        component: SendCode,
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },

    },

    {
        path: '/login-otp',
        name: 'LoginOTP',
        component: LoginOTP,
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },
        meta: {
            title: 'Login Otp'
        }

    },
    {
        path: '/verify-password-otp',
        name: 'VerifyPasswordOTP',
        component: VerifyPasswordOTP,
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },
        meta: {
            title: 'Verify Otp'
        }

    },
    {
        path: '/forgot-password',
        name: 'ForgotPassword',
        component: ForgotPassword,
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },
        meta: {
            title: 'Forgot Password'
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },
        meta: {
            title: 'Reset Password'
        }
    },
    // {
    //   path: '/change-password',
    //   name: 'ChangePassword',
    //   component: ChangePassword,

    // },
    {
        path: "/login-save",
        component: LoginSave,
        name: "LoginSave",
        beforeEnter: (to, from, next) => {
            notLoggedInAccess(to, from, next)
        },
        meta: {
            title: 'Login Save'
        }
    },
    {
        path: "/",
        component: GroupDashboard,
        name: "GroupDashboard",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Dashboard'
        }
    },
    {
        path: "/users",
        component: UserList,
        name: "UserList",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'View Users'
        }
    },
    {
        path: "/members",
        component: MemberList,
        name: "MemberList",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Members'
        }
    },
    {
        path: "/member/:policyId/policy-detail",
        component: MemberDetail,
        name: "MemberDetail",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Member Detail'
        }
    },
    {
        path: "/files",
        component: FileList,
        name: "FileList",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Files'
        }
    },
    {
        path: "/configured-plans",
        component: PlanConfigurationList,
        name: "PlanConfigurationList",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Configure Plans'
        }
    },
    {
        path: "/ticketing",
        component: Ticketing,
        name: "Ticketing",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Ticketing'
        }
    },
    {
        path: "/admin-messaging-center",
        component: AdminMessageCenter,
        name: "AdminMessageCenter",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Admin Message Center'
        }
    },
    {
        path: "/account-information",
        component: AccountInformation,
        name: "AccountInformation",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Account Information'
        }
    },
    {
        path: "/banking-information",
        component: BankingInformation,
        name: "BankingInformation",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Banking Information'
        }
    },
    {
        path: "/change-password",
        component: ChangePassword,
        name: "ChangePassword",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Change Password'
        }
    },
    {
        path: "/received-messages",
        component: ReceivedMessageList,
        name: "ReceivedMessageList",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Received Messages'
        }
    },
    {
        path: "/invoice/:date/detail",
        component: InvoiceDetail,
        name: "InvoiceDetail",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Invoice Detail'
        }
    },
    {
        path: "/display-setting",
        component: DisplaySetting,
        name: "DisplaySetting",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Display Setting'
        }
    },
    {
        path: "/member-contact-report",
        component: MemberContactReport,
        name: "MemberContactReport",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'Member Contact Report'
        }
    },
    {
        path: "*",
        component: PageNotFound,
        name: "PageNotFound",
        beforeEnter: requiredAuthentication
    },
    {
        path: "/knowledge-center",
        component: KnowledegeCenter,
        name: "KnowledgeCenterList",
        beforeEnter: requiredAuthentication,
        meta: {
            title: 'All Knowledge Feeds'
        }
    },
    {
      path: "/directory",
      component: DirectoryList,
      name: "DirectoryList",
      beforeEnter: requiredAuthentication,
      meta: {
        title: 'Directory'
      }
    },
    // {
    //   path: '/about',
    //   name: 'About',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ '@/views/About')
    // }


    // Group Registration
    {
        path: '/registration/broker-info',
        name: 'BrokerInfo',
        component: () => import('../pages/RegistrationProcess/BrokerInfo.vue'),
    },
    {
        path: '/registration/group-info',
        name: 'GroupInfo',
        component: () => import('../pages/RegistrationProcess/GroupInfo.vue'),
    },
    {
        path: '/registration/contact-info',
        name: 'ContactInfo',
        component: () => import('../pages/RegistrationProcess/ContactInfo.vue'),
    },
    {
        path: '/registration/login-info',
        name: 'LoginInfo',
        component: () => import('../pages/RegistrationProcess/LoginInfo.vue'),
    },
    {
        path: '/registration/address-info',
        name: 'AddressInfo',
        component: () => import('../pages/RegistrationProcess/AddressInfo.vue'),
    },
    {
        path: '/registration/billing-info',
        name: 'BillingInfo',
        component: () => import('../pages/RegistrationProcess/BillingInfo.vue'),
    },
    {
        path: '/registration/payment-info',
        name: 'PaymentInfo',
        component: () => import('../pages/RegistrationProcess/PaymentInfo.vue'),
    },
    {
        path: '/registration/group-contribution',
        name: 'ContributionInfo',
        component: () => import('../pages/RegistrationProcess/ContributionInfo.vue'),
    },
    {
        path: '/registration/signature-verification',
        name: 'SignatureVerification',
        component: () => import('../pages/RegistrationProcess/SignatureVerification.vue'),
    },
    {
        path: '/registration/register-success',
        name: 'RegisterSuccess',
        component: () => import('@/pages/Registration/RegisterSuccess.vue')
    },
    {
        path: '/registration',
        name: 'Registration',
        component: () => import('@/pages/Registration/Registration.vue'),
        beforeEnter: registerRoute

    },
    {
        path: '/continue-registration',
        name: 'Continue Registration',
        beforeEnter: continueRegistration
    },
]
function registerRoute(to, from, next) {
    let repCode = to.query.repCode
    let domainIds= to.query.domainIds
    let groupCensusId= to.query.groupCensusId
    if (repCode || domainIds) {
        return next({
            name: "Registration",
            params: { repCode: repCode, domainIds: domainIds, groupCensusId: groupCensusId }
        })
    } else {
        return next()
    }

}
function continueRegistration(to, from, next) {
    let [registrationId, savedTab, tabTitle, tab] = [to.query.registrationId, to.query.savedTab]
    switch (savedTab) {
        case "representative-info":
            [tabTitle, tab] = ['Rep Information', 'representative-info']
            break;
        case "group-info":
            [tabTitle, tab] = ['Group Information', 'group-info']
            break;
        case "contact-info":
            [tabTitle, tab] = ['Group Contact Information', 'contact-info']
            break;
        case "login-info":
            [tabTitle, tab] = ['Group/Admin Login Information', 'login-info']
            break;
        case "address-info":
            [tabTitle, tab] = ['Group Address Information', 'address-info']
            break;
        case "billing-contact-info":
            [tabTitle, tab] = ['Billing Address & Contact Information', 'billing-contact-info']
            break;
        case "payment-info":
            [tabTitle, tab] = ['Payment Information', 'payment-info']
            break;
        case "group-contribution":
            [tabTitle, tab] = ['Group Contribution', 'group-contribution']
            break;
        case "verification-info":
            [tabTitle, tab] = ['Signature & Verification', 'verification-info']
            break;
        default:
            [tabTitle, tab] = ['Broker Information', 'representative-info']
    }
    let url = `register/${tab}`;
    store.commit("setRegFormUrl", url);
    store.commit("setRegTitle", tabTitle);
    if (tab == 'representative-info') {
        store.commit("setRegTab", 'broker-info');
    } else {
        store.commit("setRegTab", tab);
    }
    store.commit("setRegTempId", registrationId);
    return next({
        path: "/registration",
        query: { rep_code: to.query.rep_code }
    })
}
const notLoggedInAccess = (to, from, next) => {
    if (store.getters.authenticated == false) {
        return next()
    } else {
        return next("/")
    }
}

function requiredAuthentication(to, from, next) {
    if (store.getters.authenticated == true) {
        return next()
    }
    return next({ path: "/login" })

}
const router = new VueRouter({
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = toRoute.meta && toRoute.meta.title ? `Corenroll Group | ${toRoute.meta.title}` : 'Corenroll Group ';
    next();
})
export default router
