<template>
    <div class="sidenav-wrapper" >
             <div class="">
            <GroupSideNav />
            </div>
        
    </div>
</template>
<script>
   import GroupSideNav from "@/components/layout/GroupSideNav"
    export default {
        components: {
           GroupSideNav,
        },
       data(){
           return{
             
           }
       },
       mounted(){
           
            
       },
      
     }

</script>
<style>

</style>