<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="login_save_welcome">
        <h1>You are Logged In</h1>
        <p>
          Hurray!! You have logged in successfully into your account. Save this
          device if its trusted.
        </p>
      </div>
      <div class="form">
        <div class="save_device">
          <h3>Would you like to save this device?</h3>
          <p>
            Save this device as a recognized device if its trusted. Please note
            if you select No, then this device (Computer) will not be saved and
            you will be asked to verify account for future logins from this
            device(Computer)
          </p>

          <b-form-group>
            <b-form-radio name="radios" value="1" v-model="trusted_device"
              >Yes (This is a Trusted Device)</b-form-radio
            >
            <b-form-radio name="radios" value="0" v-model="trusted_device"
              >No (This is not a Trusted Device)</b-form-radio
            >
          </b-form-group>
          <button @click="saveBrowser" :disabled="verifyLoader">
            <b-spinner v-if="verifyLoader == true" small></b-spinner>
            {{ verificationText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import helper from "@/resource/Helper";
import { uuid } from "vue-uuid";

export default {
  data() {
    return {
      trusted_device: 1,
      verifyLoader: false,
      verificationText: "Submit",
    };
  },
  methods: {
    saveBrowser() {
      this.$store.commit("setAuthentication", true);
      if (!parseInt(this.trusted_device)) {
        this.$store.commit("setDeviceId", uuid.v1());
      }
      // this.verificationText = "Verified, Redirecting To Dashboard Shortly.";
      this.verificationText = "Verified, Redirecting ...";

      //preload getters
      helper.dashboardGetters()
      this.verifyLoader = true;

      //after getters preloaded router push after 2 second
      this.$nextTick(() => {
        helper.delayRedirect("GroupDashboard", 2);
      });
    },
  },
};
</script>

<style>
@import "../../../assets/css/group_dashboard/login_save.css";
</style>
