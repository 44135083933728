<template>
  <div>
    <h2>Primary Information</h2>
    <div class="memberProfile_Image">
      <img :src="image" class="img-fluid" alt="" />
    </div>
    <div class="text-center">
      <h3>{{ fullname }}</h3>
      <span class="memberCntc">
        <i class="fas fa-envelope"></i>
        {{ personalInfo.email }}
      </span>
      <span class="memberCntc">
        <i class="fas fa-phone-alt"></i>
        {{ formattedPhone(personalInfo.phone_1) }}
      </span>
    </div>
    <div class="light-bg">
      <div class="memberDetail_lists">
        <ul>
          <!-- <li>
        <span class="strong">Sign Up Date</span>
        <span>: 19-19-2019</span>
      </li> -->
          <li>
            <span class="strong">Gender:</span>
            <span>{{ personalInfo.gender }}</span>
          </li>
          <li>
            <span class="strong">DOB:</span>
            <span>
              {{
                personalInfo.birth_date
                  | date_format('MM/DD/YYYY', 'YYYY-MM-DD')
              }}
            </span>
          </li>
          <li>
            <span class="strong">SSN:</span>
            <span>{{ personalInfo.ssn }}</span>
          </li>
          <li>
            <span class="strong">Phone 1:</span>
            <span>{{ formattedPhone(personalInfo.phone_1) }}</span>
          </li>
          <li>
            <span class="strong">Alt. Phone:</span>
            <span>{{ formattedPhone(personalInfo.phone_2) }}</span>
          </li>
        </ul>
      </div>
    </div>
    <h4>Address Information</h4>
    <div class="light-bg">
      <div class="memberDetail_lists">
        <ul>
          <li>
            <span class="strong">Address 1:</span>
            <span>
              {{ personalInfo.address ? personalInfo.address.address1 : '' }}
            </span>
          </li>
          <li>
            <span class="strong">Address 2:</span>
            <span>
              {{
                personalInfo.address
                  ? personalInfo.address.address2
                    ? personalInfo.address.address2
                    : 'N/A'
                  : ''
              }}
            </span>
          </li>
          <li>
            <span class="strong">City:</span>
            <span>
              {{ personalInfo.address ? personalInfo.address.city : '' }}
            </span>
          </li>
          <li>
            <span class="strong">State:</span>
            <span>
              {{ personalInfo.address ? personalInfo.address.state : '' }}
            </span>
          </li>
          <li>
            <span class="strong">ZIP Code:</span>
            <span>
              {{ personalInfo.address ? personalInfo.address.zip : '' }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Helper from '@/resource/Helper'

export default {
  name: 'PrimaryInfo',

  props: {
    personalInfo: {
      required: true,
    },
    image: {
      required: true,
    },
  },

  methods: {
    formattedPhone(phone) {
      if (phone) {
        return Helper.formatPhoneNumber(phone)
      }
      return 'N/A'
    },
  },
  computed: {
    fullname() {
      if (this.personalInfo) {
        let personalInfo = this.personalInfo
        let middleName =
          personalInfo.middle_name !== null ? personalInfo.middle_name : ''
        return `${personalInfo.first_name} ${middleName} ${personalInfo.last_name}`
      }
      return ''
    },
  },
}
</script>
