<template>
  <div class="column">
    <div
      v-for="(item, index) in messages.records"
      :key="index"
      class="row"
      @click.prevent="setMessage(item)"
      :class="currentItemId == item.mid ? 'active_background' : null"
      >
      <img src="@/assets/images/default_profile.jpg" alt="profile" />
      <div class="message_titleBlock">
        <span class="message_title">Cannot Register...</span>
        <br />
        <span class="message_subject">
          {{ formattedDate(item.date_created) }}
        </span><br>
        <div class="status">
         <span><i class="fas fa-circle"></i>&nbsp;&nbsp;OPENED</span>
         <!-- <span><i class="fas fa-circle closed"></i>&nbsp;&nbsp;OPENED</span> -->
        </div>
        <div class="comment-count">
          <span>
            <svg id="comment" xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13">
              <path id="Path_23" data-name="Path 23" d="M12.076-1.332H1.925A1.936,1.936,0,0,0,0,.611V6.88A1.936,1.936,0,0,0,1.919,8.823v2.845L5.97,8.823h6.106A1.936,1.936,0,0,0,14,6.88V.611a1.936,1.936,0,0,0-1.925-1.943Zm1.1,8.212a1.111,1.111,0,0,1-1.1,1.115H5.712L2.739,10.083V7.995H1.925A1.111,1.111,0,0,1,.821,6.88V.611A1.111,1.111,0,0,1,1.925-.5H12.076A1.111,1.111,0,0,1,13.18.611Zm0,0" transform="translate(-0.001 1.332)" fill="#707070"/>
              <path id="Path_24" data-name="Path 24" d="M171.293,131.172h6.785v.855h-6.785Zm0,0" transform="translate(-167.685 -128.253)" fill="#707070"/>
              <path id="Path_25" data-name="Path 25" d="M171.293,211.172h6.785v.855h-6.785Zm0,0" transform="translate(-167.685 -206.491)" fill="#707070"/>
              <path id="Path_26" data-name="Path 26" d="M171.293,291.172h6.785v.855h-6.785Zm0,0" transform="translate(-167.685 -284.729)" fill="#707070"/>
            </svg>&nbsp;2
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "TicketingList",
  props: {
    messages: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      currentItemId: "",
    };
  },
  methods: {
    formattedDate(date) {
      if (moment(date, "MM/DD/YYYY HH:mm:ss", true).isValid()) {
        return moment(date, "MM/DD/YYYY HH:mm:ss", true).format("MM-DD-YYYY");
      }
      return "N/A";
    },
    setMessage(item) {
      this.currentItemId = item.mid;
      this.$emit("onClickedItem", item);
    },
  },
  watch: {
    messages: function(newData, oldData) {
      if (newData != oldData) {
        this.currentItemId = newData.records.length > 0 ? newData.records[0].mid : null;
      }
    },
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/admin_message_center.css"
  scoped
></style>
<style scoped>
.message_titleBlock {
  position: relative;
}
.column div.status {
  margin-left: 0px!important;
  display: inline-block;
  padding: 5px 7px;
  margin-top: 10px;
  background: #F3F7FA;
  border-radius: 5px;
  font-size: 12px;
  color: #707070;
}
.column div.status .fa-circle {
  color: #3BB54A;
  margin-right: 3px;
}
.column div.status .fa-circle.closed {
  color: #EA584E;
}
.comment-count {
  display: inline-block;
  margin-left: unset!important;
  position: absolute;
  right: 30px;
  bottom: 8px;
  color: #707070;
}
.comment-count svg {
  margin-right: 4px;
}
</style>
