<template>
  <div class="wrapper_group_dashboard" ref="contra">
    <div class="group_dashboard">
      <div class="main_container">
        <breadcrumb :items="breadcrumbItems" />
        <div class="title">
          <h2>Member Contact Report</h2>
          <!-- <button class="downoad_file">
            <img src="@/assets/images/download.svg" width="20" height="20" />
            <span style="margin-left: 10px">
              <a
                href="qa-api.purenroll.com/api.group/v1/get-contact-report-file/MzIxNA=="
                target="_blank"
                download
              >
                Download Report
              </a>
            </span>
          </button> -->
        </div>
        <!-- <div class="search_container">
          <b-form>
            <div class="search">
              <button>Search</button>
            </div>
          </b-form>
        </div> -->
        <div class="table_container">
          <!-- <table>
            <thead>
              <tr>
                <td width="10%">System ID</td>
                <td width="30%">Information</td>
                <td width="20%">Dates</td>
                <td width="15%">Rep</td>
                <td width="15%">Plan</td>
                <td width="10%">Status</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in rows.records" :key="index">
                <td>{{ item.policy_id }}</td>
                <td>
                  <template v-if="item.email">
                    <strong>Email : </strong>
                    <span>{{ item.email }}</span>
                    <br />
                  </template>
                  <template v-if="item.phone">
                    <strong>Phone : </strong>
                    <span>{{ item.phone }}</span>
                    <br />
                  </template>
                  <template>
                    <strong>Address : </strong>
                    {{
                      formattedAddress(
                        item.address1,
                        item.address2,
                        item.city,
                        item.state,
                        item.zip
                      )
                    }}
                  </template>
                </td>
                <td>
                  <template v-if="item.enrollment_date">
                    <strong>Enrollment Date : </strong>
                    <span>{{
                      item.enrollment_date
                        | date_format("MM/DD/YYYY", "YYYY-MM-DD")
                    }}</span>
                    <br />
                  </template>
                  <template v-if="item.effective_date">
                    <strong>Effective Date : </strong>
                    <span>{{
                      item.effective_date
                        | date_format("MM/DD/YYYY", "YYYY-MM-DD")
                    }}</span>
                    <br />
                  </template>
                  <template v-if="item.term_date">
                    <strong>Term Date : </strong>
                    <span>{{
                      item.term_date | date_format("MM/DD/YYYY", "YYYY-MM-DD")
                    }}</span>
                  </template>
                </td>
                <td>{{ item.rep_name }}</td>
                <td>{{ item.plan_name + " / " + item.tier }}</td>
                <td>{{ item.status }}</td>
              </tr>
            </tbody>
          </table>
          <template v-if="rows.records && rows.records.length < 1">
            <no-content />
          </template>
          <div class="overflow-auto">
            <pagination :rows.sync="rows" :filters="filterData" />
          </div> -->
          <table>
            <thead>
              <tr>
                <td>File name</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Member Contact Report</td>
                <td >
                  <download-button :link="file"/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
export default {
  name: "MemberContactReport",
    data: () => {
    return {
      rows: [],
      filterData: {
        first_name: "",
        last_name: "",
        policy_id: "",
        status: "",
        agent_code: "",
        state: "",
        enrollment_date_start: "",
        enrollment_date_end: "",
        effective_date: "",
      },
      breadcrumbItems: [{ title: "Member Contact Report", active: true }],
      file: "",
    };
  },
  methods: {
    formattedAddress(address1, address2, city, state, zip) {
      address1 = this.$formattedDataWithComma(address1);
      address2 = this.$formattedDataWithComma(address2);
      city = this.$formattedDataWithComma(city);
      state = this.$formattedDataWithComma(state);
      zip = zip ? zip : "";
      return `${address1} ${address2} ${city} ${state} ${zip}`;
    },
    handleNewLineText(text) {
      return text.replace("\n", "<br/>");
    },
    getReports(filters = []) {
      let url;
      let loader = this.$loading.show();

      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `get-contact-report-data?page_size=10&${filterUrl}`;
      } else {
        url = `get-contact-report-data?page_size=10`;
      }
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
          this.file = res.data.file;
        })
        .catch((error) => {
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  mounted() {
    this.getReports();
  },
};
</script>
<style scoped>
.main_container {
  padding: 0px 15px 15px 15px;
  width: 100%;
  min-height: 65.5vh;
}
.table_container {
  margin-top: 20px;
  background: #fff;
  padding: 1px;
  box-shadow: -2px 2px 5px #43566420;
  border-radius: 8px;
}
table {
  width: 100%;
}
td {
  vertical-align: baseline;
}
table tr td {
  color: #254050;
  border: 1px solid #d7e1ea;
  padding: 20px 30px;
  font-size: 14px;
}
table tbody tr td {
  line-height: 30px;
}
table tr:last-child td {
  border-bottom: none;
}
table tr td:first-child {
  border-left: none;
}
table tr td:last-child {
  border-right: none;
}
table tbody tr:nth-child(even) {
  background: #f7fafc;
}
table thead tr td {
  font-weight: bold;
  background: #fff;
  padding: 15px 30px;
  border-top: none;
}
table tr td .action_icon {
  background: transparent
    linear-gradient(180deg, #00ccff 0%, #1597b8 49%, #0567bc 100%);
  border-radius: 27px;
  padding: 7px;
  width: 24px;
  height: 24px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
table tr td .action_icon:hover {
  background-image: linear-gradient(#146479 0%, #066bc4 100%, #2fa3c0 60%);
}

table {
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 5px #43566420;
  border-radius: 10px;
}
table tr td {
  font-size: 14px;
  padding: 10px 8px;
  vertical-align: middle;
  border-left: 1px solid #d7e1ea;
  border-top: 1px solid #d7e1ea;
}

.downoad_file {
  font-size: 14px;
  /* font-weight:500; */
  background: linear-gradient(#00ccff 0%, #1597b8 49%, #0567bc 100%);
  border-radius: 6px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.downoad_file:hover {
  background-image: linear-gradient(#146479 0%, #066bc4 100%, #2fa3c0 60%);
}
.downoad_file img {
  width: 15px;
}

.downoad_file a,
.downoad_file a:hover {
  text-decoration: none;
  color: #fff;
}

@media only screen and (max-width: 1450px) {
  .table_container table thead tr td {
    padding: 25px 0 20px 30px;
  }
  .table_container table tbody td {
    padding: 15px 0 15px 30px;
  }
}
@media only screen and (max-width: 1200px) {
  .table_container table thead tr td {
    padding: 25px 0 20px 15px;
  }

  .table_container table tbody td {
    padding: 15px 0 15px 15px;
  }
}
@media only screen and (max-width: 991px) {
  .downoad_file {
    font-size: 18px;
  }
  .table_container table {
    font-size: 13px;
  }
  .table_container table thead tr td {
    padding: 25px 0 20px 15px;
  }
  .table_container table tbody td {
    padding: 15px 0 15px 15px;
  }
  /* upload file  */
  #downoad_file {
    font-size: 14px;
  }
  #downoad_file #file_type {
    font-size: 12px;
  }

  /* upload file  */
}
@media only screen and (max-width: 760px) {
  .table_container {
    margin: 20px 0;
  }
  table,
  tr,
  tbody,
  thead,
  td {
    display: block;
  }
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  table tr {
    border-bottom: 1px solid #efefef;
    padding-top: 10px;
    margin-bottom: 0;
  }
  .table_container table tbody td {
    padding: 21px 0 0px 15px;
  }
  table td {
    border: none;
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
  }
  td:before {
    position: absolute;
    top: 0;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 499px) {
  .downoad_file {
    font-size: 16px;
    padding: 5px;
    /* margin-top:15px; */
  }
}
</style>