<template>
  <div class="banking_container">
    <div class="banking">
      <div class="title">
        <h2 v-if="groupPaymentType == 'EFT'">Banking Information</h2>
        <h2 v-else>Billing Method</h2>
        <!-- show only type is eft -->
           <template v-if="groupPaymentType != 'EFT'">
            <span v-b-modal.add_bank_info class="view-all">Change Payment Method</span>

        </template>
        <template v-if="groupPaymentType == 'EFT'">
          <router-link class="link-hover" :to="{ name: 'BankingInformation' }">
            <span class="view-all">View All</span>
          </router-link>
        </template>
      </div>
      <div class="banking_details">
        <template v-if="groupPaymentType == 'EFT'">
          <div class="active_banking_info" v-if="groupBanks.length > 0">
            <ul
              v-for="(item, index) in groupBanks.slice(0, 3)"
              :key="index"
              :class="item.is_primary ? 'bank-primary-backgroud' : ''"
            >
              <div class="banking_status" v-if="item.is_primary">
                <i class="fas fa-check-circle"></i>
                <span class="status">Active</span>
              </div>
              <li>
                <span>Account Name</span>
                <span class="bank_data">{{ item.bank_accountname }}</span>
              </li>
              <li>
                <span>Bank Name</span>
                <span class="bank_data">{{ item.bank_name }}</span>
              </li>
              <li>
                <span>Routing Number</span>
                <span class="bank_data">{{ item.bank_routing }}</span>
              </li>
              <li>
                <span>Account Number</span>
                <span class="bank_data">{{ item.bank_account4 }}</span>
              </li>
              <li>
                <span>Account Holder Type</span>
                <span class="bank_data">
                  {{
                    item.account_holder_type ? item.account_holder_type : "--"
                  }}
                </span>
              </li>
              <li>
                <span>Account Type</span>
                <span class="bank_data">
                  {{ item.account_type ? item.account_type : "--" }}
                </span>
              </li>
            </ul>
          </div>
          <div class="active_banking_info" v-else>
            <no-content text="No banking found." />
          </div>
        </template>

        <template v-else>
            <div class="active_banking_info billing-method">
              <div class="billingCateg">
                <span class="billing-categ">
                  {{ groupPaymentType }}
                </span>
              </div>
          </div>
        </template>
      </div>
       <AddBankInfo action='payment_type' />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import AddBankInfo from "@/pages/Bank/AddBankInfo.vue";
export default {
  components: { AddBankInfo },
  computed: {
    ...mapGetters({
      groupBanks: "groupBanks",
      groupPaymentType: "groupPaymentType",
    }),
  },
};
</script>
<style src="@/assets/css/group_dashboard/banking.css" scoped></style>
