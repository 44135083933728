<template>
  <div>
    <h4>Billing Method</h4>
    <div class="light-bg">
      <span
        class="billing-categ"
        :class="billingMethod.payment_type == 'EFT' ? '' : 'non-position'"
      >
        {{ billingMethod ? billingMethod.payment_type : '' }}
      </span>
      <ul v-if="billingMethod.details">
        <li>
          <span class="strong">Bank Name:</span>
          <span>
            {{ billingMethod.details ? billingMethod.details.name : '' }}
          </span>
        </li>
        <li>
          <span class="strong">Account Name:</span>
          <span>
            {{
              billingMethod.details ? billingMethod.details.account_name : ''
            }}
          </span>
        </li>
        <li>
          <span class="strong">Account Number:</span>
          <span>
            {{ billingMethod.details ? billingMethod.details.number : '' }}
          </span>
        </li>
      </ul>
    </div>

    <h4>Recurring Billing Method</h4>
    <div class="light-bg">
      <span
        class="billing-categ"
        :class="
          recurringBillingMethod.payment_type == 'EFT' ? '' : 'non-position'
        "
      >
        {{ recurringBillingMethod ? recurringBillingMethod.payment_type : '' }}
      </span>
      <ul v-if="recurringBillingMethod.details">
        <li>
          <span class="strong">Bank Name:</span>
          <span>
            {{
              recurringBillingMethod.details
                ? recurringBillingMethod.details.name
                : ''
            }}
          </span>
        </li>
        <li>
          <span class="strong">Account Name:</span>
          <span>
            {{
              recurringBillingMethod.details
                ? recurringBillingMethod.details.account_name
                : ''
            }}
          </span>
        </li>
        <li>
          <span class="strong">Account Number:</span>
          <span>
            {{
              recurringBillingMethod.details
                ? recurringBillingMethod.details.number
                : ''
            }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    billingMethod: {
      required: true,
    },
    recurringBillingMethod: {
      required: true,
    },
  },
}
</script>
