<template>
  <div class="wrapper_group_dashboard">
    <div class="memberDetail_wrap vld-parent" ref="container">
      <div class="member_details">
        <breadcrumb :items="breadcrumbItems" class="member-detail-breadcrumb" />
        <div class="title">
          <h1>Member Details</h1>
        </div>
        <div class="row">
          <div class="col-xl-4 col-lg-6 col-md-6">
            <div class="memberInformation">
              <primary-info :personalInfo="personalInfo" :image="image" />
              <beneficiary-info-table :beneficiaryInfo="beneficiaryInfo" />
            </div>
          </div>

          <div class="col-xl-8 col-lg-6 col-md-6">
            <div class="memberPolicy-wrap">
              <h2>Member Policies</h2>
              <div class="policy-block">
                <member-policy :policy="policy" />
                <member-plans
                  :plans="plans"
                  :billingMethod="billingMethod"
                  :recurringAmount="recurringAmount"
                />
                <member-billing
                  :billingMethod="billingMethod"
                  :recurringBillingMethod="recurringBillingMethod"
                />
                <member-dependents :dependents="dependents" />

                <member-documents
                  v-if="policy.status == 'ACTIVE'"
                  :documents="documents"
                  :policy="policy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from '@/resource/Api'
import defaultImage from '@/assets/images/default_profile.jpg'
import BeneficiaryInfoTable from './BeneficiaryInfoTable.vue'
import PrimaryInfo from './PrimaryInfo.vue'
import MemberPolicy from './MemeberPolicy.vue'
import MemberPlans from './MemberPlans.vue'
import MemberBilling from './MemberBilling.vue'
import MemberDependents from './MemberDependents.vue'
import MemberDocuments from './MemberDocuments.vue'
export default {
  name: 'MemberDetail',
  components: {
    BeneficiaryInfoTable,
    PrimaryInfo,
    MemberPolicy,
    MemberPlans,
    MemberBilling,
    MemberDependents,
    MemberDocuments,
  },
  data: () => {
    return {
      personalInfo: '',
      policy: '',
      plans: [],
      billingMethod: '',
      recurringBillingMethod: '',
      recurringAmount: '',
      dependents: [],
      documents: [],
      breadcrumbItems: [],
      beneficiaryInfo: [],
    }
  },

  methods: {
    getbreadcrumbItems() {
      this.breadcrumbItems = [
        {
          title: 'Members',
          path: { name: 'ViewMember' },
        },
        // {
        //   title: "Member",
        //   path: {
        //     name: "MemberDetail",
        //     params: { policyId: this.$route.params.policyId },
        //   },
        // },
        { title: 'Member Detail', active: true },
      ]
    },

    getDetail() {
      const url = `get-member-policy-info/${this.$route.params.policyId}`
      let loader = this.$loading.show()
      Api.get(url)
        .then((res) => {
          const data = res.data.data
          this.personalInfo = data.personal_info
          this.policy = data.policy_detail
          this.plans = data.plan_details
          this.billingMethod = data.billing_method
          this.recurringBillingMethod = data.recurring_billing_method
          this.recurringAmount = data.recurring_amount
          this.dependents = data.dependents
          this.documents = data.documents
          this.ccFee = data.cc_fee
          this.beneficiaryInfo = data.beneficiary_info

          this.image =
            data.personal_info.image !== ''
              ? data.personal_info.image
              : defaultImage
          loader.hide()
        })
        .catch((err) => {
          setTimeout(() => {
            loader.hide()
          }, 2000)
          console.log('error', 'Somethind went wrong')
        })
    },
  },
  created() {
    this.getDetail()
    this.getbreadcrumbItems()
  },
}
</script>
<style src="@/assets/css/group_dashboard/member_details.css"></style>
