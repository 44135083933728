import { render, staticRenderFns } from "./Ticketing.vue?vue&type=template&id=2d032818&scoped=true&"
import script from "./Ticketing.vue?vue&type=script&lang=js&"
export * from "./Ticketing.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/group_dashboard/admin_message_center.css?vue&type=style&index=0&id=2d032818&scoped=true&lang=css&"
import style1 from "./Ticketing.vue?vue&type=style&index=1&id=2d032818&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d032818",
  null
  
)

export default component.exports