<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
     <div class="directory_wrapper">
      <breadcrumb :items="breadcrumbItems" />
      <div class="page-title">
        <h3><strong>Directory</strong></h3>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="directory-block">
            <h2>{{ details.name }}</h2>
            <ul>
              <li>
                <strong>Phone:</strong>
                <a :href="'tel:' + details.phone">{{ details.phone }}</a>
              </li>
              <li>
                <strong>Email:</strong>
                <a :href="'mailto:' + details.email">{{ details.email }}</a>
              </li>
            </ul>
          </div>
        </div>

        <div
            class="col-xxl-3 col-xl-4 col-lg-4 col-md-6 mb-4"
            v-for="(list, index) in directoryList"
            :key="index"
        >
          <div class="directory-block-card">
            <h3>{{ list.name }}</h3>
            <span
            ><strong>Email:</strong>
              <a :href="'mailto:' + list.email">{{ list.email }}</a></span
            >
            <ul v-if="list.details">
              <li v-for="(detail, index) in list.details" :key="index">
                {{ detail.title }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import axios from "axios"
export default {
  name: "DirectoryList",
  data() {
    return {
      details: {
        name: "",
        email: "",
        phone: "",
      },
      directoryList: [],
      breadcrumbItems: [{ title: "Directory", active: true }],
    };
  },
  methods: {
    getDirectoryList() {
      let url = process.env.VUE_APP_API_URL_V2+'directory/get';
      let loader = this.$loading.show();
      axios.get(url)
          .then((res) => {
            this.directoryList = res.data.data.directory_list;
            let { name, email, phone } = res.data.data;
            this.details = { name, email, phone };
          })
          .catch(() => {
            this.directoryList = [];
          })
          .finally(() => loader.hide());
    },
  },
  created() {
    this.getDirectoryList();
  },
};
</script>
<style src="../../assets/css/directory.css" scoped></style>
<style>
.page-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
}
.page-title h3 {
    color: #2c3f58;
  }
</style>
