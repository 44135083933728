<template>
  <div class="breadcrumb-main">
    <b-breadcrumb>
      <b-breadcrumb-item>
        
        <router-link :to="{ name: 'GroupDashboard' }"> <b-icon
          icon="house"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
          
        ></b-icon></router-link>
        <span class="pl-2 home-text">Home</span>
      </b-breadcrumb-item>
      <template v-for="item in items">
        <b-breadcrumb-item :key="item.title" :active="item.active">
          <template v-if="item.path && item.path.name && item.path.params">
            <router-link
              :to="{
                name: item.path.name,
                params: item.path.params,
              }"
            >
              {{ item.title }}
            </router-link>
          </template>
          <template v-if="item.path && item.path.name">
            <router-link
              :to="{
                name: item.path.name,
              }"
            >
              {{ item.title }}
            </router-link>
          </template>
          <template v-else> {{ item.title }} </template>
        </b-breadcrumb-item>
      </template>
    </b-breadcrumb>
  </div>
</template>
<script>
export default {
  name: "Breadcrumb",
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style scoped>
.breadcrumb-main{
  width: 100%;
}
.breadcrumb{
  background-color: transparent;
  padding-left: 0;
  font-size: 13px;
  padding: 0;
}
.breadcrumb-item a{
  text-decoration: none;
  color: #03C3EC ;
}
.home-text{
  color: #03C3EC ;
}
</style>
