<template>
  <div v-if="dependents.length > 0">
    <h4>Dependents</h4>
    {{ dependents }}
    <div class="light-bg no-padding" style="overflow-x: auto;">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Relation</th>
            <th>DOB</th>
            <th>SSN</th>
            <th>Gender</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(dependent, index) in dependents"
            :key="dependent.dependent_id"
          >
            <td>{{ index + 1 }}</td>
            <td>{{ dependentFullName(dependent) }}</td>
            <td>{{ dependent.relationship }}</td>
            <td>
              {{ dependent.birth_date ? dependent.birth_date : '--' }}
            </td>
            <td>{{ dependent.ssn }}</td>
            <td>{{ dependent.gender }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dependents: {
      required: true,
    },
  },
  methods: {
    dependentFullName(dependent) {
      return `${dependent.first_name} ${
        dependent.middle_name ? dependent.middle_name : ''
      } ${dependent.last_name}`
    },
  },
}
</script>
