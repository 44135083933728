<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="admin_message_wrapper">
        <breadcrumb :items="breadcrumbItems" class="admin-center-messages-breadcrumb" />
        <h3>Ticketing System</h3>
        <div class="admin_message_center">
          <div class="admin_message_center_tab">
            <div class="message_list">
                <div class="title">
                    <h2>Ticket List</h2>
                    <button
                    class="new_message"
                    @click.prevent="
                        canCompose = true;
                        showMessage = null;">
                    New Ticket
                    </button>
                </div>
                <admin-message-list
                    :messages="messages"
                    @onClickedItem="getMessage"
                />
                <small v-if="messages.records">
                    <pagination :rows.sync="messages" :filters="[]" />
                </small>
            </div>
            <template v-if="canCompose">
            <admin-compose-message :getSentMessage="getSentMessages" />
            </template>
            <template v-if="showMessage">
            <admin-message-detail :message="showMessage" @sentMessageList="getSentMessages" />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminComposeMessage from "./TicketingCompose.vue";
import AdminMessageList from "./TicketingList.vue";
import AdminMessageDetail from "./TicketingDetails.vue";
import Api from "@/resource/Api";
import axios from "axios"
export default {
  name: "Ticketing",
  components: {
    AdminMessageList,
    AdminComposeMessage,
    AdminMessageDetail,
  },
  data() {
    return {
      canCompose: false,
      showMessage: null,
      selected: null,
      messages: {},
      breadcrumbItems: [{ title: "Ticketing", active: true }],
      users : []
    };
  },
  methods: {
    getMessage(item) {
      this.canCompose = false;
      this.showMessage = item;
    },

    getSentMessages() {
      let url = process.env.VUE_APP_API_URL_V2+`get-sent-message-list?page_size=10`;
      let loader = this.$loading.show();
      axios.get(url)
        .then((res) => {
          this.messages = res.data;
          this.showMessage = this.messages.records[0];
          if (this.messages.records.length < 1) {
            this.canCompose = true;
            this.showMessage = null;
          } else {
              this.canCompose = false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          loader.hide();
        });
    },

  },
  created() {
    //temporary
    ///this.$router.push({name:"GroupDashboard"})
    this.getSentMessages();
  },
  watch: {
    messages: function(newData, oldData) {
      if (newData != oldData) {
        this.showMessage =
          newData.records.length > 0 ? newData.records[0] : null;
      }
    },
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/admin_message_center.css"
  scoped
></style>
<style scoped>
.compose_message .form-group label {
  font-size: 14px !important;
  color: #254050;
}
.compose_message .custom-file-label {
  border: 1px solid #03c3ec !important;
  border-radius: 4px;
}
.compose_message #input-group-2 .form-file-text {
  color: #778699 !important;
  font-size: 13px;
}
.compose_message .custom-file-label::after {
  display: none;
}
@media only screen and (max-width: 1205px) {
  .compose_message .form-group label {
    font-size: 12px !important;
  }
  .compose_message #input-group-2 .form-file-text {
    font-size: 12px;
  }
}
.admin_message_center_tab {
    display: block;
    width: 100%;
    padding: 10px;
    display: flex;
}
h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    margin-top: 25px;
    color: #2c3f58;
    padding-left: 10px;
}
.admin_message_center_tab .card {
    border: none;
}
.admin_message_center_tab .card .card-header {
    padding: 0;
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 25px;
    background: #f7fafc;
}
.admin_message_center_tab .card .card-header ul {
    margin: 0;
    padding: 0;
}
.admin_message_center_tab .card .nav-pills li a {
    color: #03c3ec;
    border: 1px solid #03c3ec;
    display: inline-block;
    padding: 8px 40px;
    margin-right: 10px;
    text-align: center;
    border-radius: 60px;
    font-size: 14px;
    background: transparent;
}
.admin_message_center_tab .card .nav-pills li a:hover {
    background-image: linear-gradient( #00CCFF 0%, #1597B8 49%, #0567BC 100%)!important;
    color: #fff;
}
.admin_message_center_tab .card .nav-pills li a.active {
    background-image: linear-gradient( #00CCFF 0%, #1597B8 49%, #0567BC 100%)!important;
    color: #fff;
}
.admin_message_center_tab .card .card-body {
    background: #f7f7f7;
    padding: 0;
}
.admin_message_center_tab .card .card-body .message_list .column .row {
    border-bottom: 1px solid #ebf0f4;
}
.admin_message_center_tab .card .card-body .message_list .column .row:hover {
    background: #ebf0f4;
    transition: all .4s ease;
}
.message-filter .mb-3 {
  margin-bottom: 0!important;
}

.active_background {
  background: #dce5ec94!important;
}
@media (max-width: 767px) {
    .admin_message_center_tab {
        display: block;
    }
    .message_list {
        max-width: 100%;
    }
}
</style>
