<template>
  <div class="general_information">
    <div class="title">
      <h3>General Information</h3>
      <p>Update Basic Information of Group</p>
    </div>
    <ValidationObserver ref="generalInfo" v-slot="{ handleSubmit }" slim>
      <b-form
        @submit.stop.prevent="handleSubmit(updateInfo)"
        class="update-group-form"
        ref="generalInfoContainer"
      >
        <input-text
          :rules="{ required: false }"
          labelFor="group_code"
          labelName="Group Code"
          inputName="group_code"
          :vmodel.sync="form.group_code"
          :readonly="true"
        />

        <input-text
          :rules="{ required: true }"
          labelFor="group_name"
          labelName="Group Name"
          inputName="group_name"
          :vmodel.sync="form.group_name"
        />
        <!-- 
        <input-text
          :rules="{ required: true, numeric: true, digits: 10 }"
          labelFor="group_phone"
          labelName="Group Phone"
          inputName="group_phone"
          :vmodel.sync="form.group_phone"
          inputType="number"
        /> -->
        <b-form-group>
          <validation-provider
            name="Group Phone"
            :rules="{ required: true, numeric: true, digits: 10 }"
            v-slot="validationContext"
          >
            <label id="label-group-phone-number" for="label-group-phone-number">
              Group Phone<span style="color:Red">*</span>
            </label>
            <b-form-input
              id="group-phone-number"
              type="number"
              :state="getValidationState(validationContext)"
              aria-describedby="input-feedback"
              v-model="form.group_phone"
              placeholder="Enter Group Phone"
              @input="checkClearoutPhone"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-feedback group-phone-number-feedback"
              class="group-phone-number-feedback"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <input-text
          :rules="{ required: true }"
          labelFor="group_contact_first_name"
          labelName="Group Contact First Name"
          inputName="group_contact_first_name"
          :vmodel.sync="form.group_contact_first_name"
        />

        <input-text
          :rules="{ required: true }"
          labelFor="group_contact_last_name"
          labelName="Group Contact Last Name"
          inputName="group_contact_last_name"
          :vmodel.sync="form.group_contact_last_name"
        />

        <input-text
          :rules="{ required: true }"
          labelFor="group_email"
          labelName="Group Email"
          inputName="group_email"
          :vmodel.sync="form.group_email"
          inputType="email"
        />

        <input-select
          :rules="{ required: true }"
          labelFor="group_industry"
          labelName="Group Industry"
          inputName="group_industry"
          :vmodel.sync="form.group_industry"
          :options="industries"
          defaultOption="Select Industry"
        />

        <input-text
          :rules="{ required: true, numeric: true, digits: 10 }"
          labelFor="group_fax"
          labelName="Group Fax"
          inputName="group_fax"
          :vmodel.sync="form.group_fax"
          inputTypes="number"
        />

        <input-text
          :rules="{ required: true, numeric: true }"
          labelFor="group_tax_id"
          labelName="Group Tax ID (EIN)"
          inputName="group_tax_id"
          :vmodel.sync="form.group_tax_id"
          inputTypes="number"
        />

        <input-image
          :rules="{ required: false, size: 2048 }"
          labelFor="group_logo"
          labelName="Group Logo"
          inputName="group_logo"
          :imgSrc="imgSrc"
          :vmodel.sync="logo"
        />

        <b-form-group class="save_info">
          <button
            :disabled="submit == false && validPhone == false"
            :class="submit == false && validPhone == false ? 'disable' : ''"
          >
            {{ submit ? "Processing..." : "Update" }}
          </button>
        </b-form-group>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import CommonValidator from "@/resource/CommonValidator";
export default {
  data: () => {
    return {
      form: {
        group_contact_first_name: "",
        group_contact_last_name: "",
        group_email: "",
        group_code: "",
        group_name: "",
        group_tax_id: "",
        group_phone: "",
        group_fax: "",
        group_industry: null,
      },
      logo: null,
      imgSrc: "",
      submit: false,
      industries: [],
      supportImage: null,
      supportImgSrc: "",
      validPhone: true,
    };
  },
  methods: {
    async checkClearoutPhone() {
      let inputId, feedBackInputClass;
      inputId = "#group-phone-number";
      feedBackInputClass = ".group-phone-number-feedback";
      const check = await CommonValidator.validatePhoneNumberWithOptions(
        this.form.group_phone,
        inputId,
        feedBackInputClass
      );
      this.validPhone = check;
      return check;
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getGroupInfo() {
      let data = this.$store.getters.groupInfo;
      this.form = {
        group_contact_first_name: data.group_contact_first_name
          ? data.group_contact_first_name
          : "",
        group_contact_last_name: data.group_contact_last_name
          ? data.group_contact_last_name
          : "",
        group_email: data.group_email ? data.group_email : "",
        group_code: data.group_code ? data.group_code : "",
        group_name: data.group_name ? data.group_name : "",
        group_tax_id: data.group_tax_id ? data.group_tax_id : "",
        group_phone: data.group_phone ? data.group_phone : "",
        group_fax: data.group_fax ? data.group_fax : "",
        group_industry: data.group_industry ? data.group_industry : null,
      };
      this.imgSrc = this.$store.getters.groupInfo.group_logo;
      this.supportImgSrc = this.$store.getters.groupInfo.support_pic;
    },
    getGroupIndustries() {
      let url = "get-group-industry";
      Api.get(url)
        .then((res) => {
          let arr = res.data.data;
          this.industries = arr.map((data) => {
            return { value: data.value, text: data.title };
          });
        })
        .catch((err) => {
          console.log("Something is wrong.");
        });
    },
    async updateInfo() {
      if (await this.checkClearoutPhone()) {
        if (this.validPhone) {
          let url = "update-group-info";
          this.submit = true;
          let formData = new FormData();
          formData.append("group_logo", this.logo);
          for (const [key, value] of Object.entries(this.form)) {
            if (value) {
              formData.append(key, value);
            }
          }
          let loader = this.$loading.show({
            container: this.$refs.generalInfoContainer,
          });

          Api.post(url, formData)
            .then((res) => {
              let response = res.data.message;
              this.$notify(response);
            })
            .catch((err) => {
              this.$notify(err.response.data.message, "Error", "danger");
            })
            .finally(() => {
              this.$store.dispatch("getGroupInfo");
              setTimeout(() => {
                loader.hide();
                this.submit = false;
              }, 2000);
            });
        }
      }
    },
  },
  created() {
    this.getGroupInfo();
    this.getGroupIndustries();
    this.$store.dispatch("getGroupInfo");
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/account_information.css"
  scoped
></style>
