<template>
  <div>
    <!-- add bank info -->
    <b-modal
      id="add_bank_info"
      centered
      hide-footer
      no-close-on-backdrop
      hide-header
      size="lg"
    >
      <img
        class="close_cross"
        @click="closeModal"
        src="@/assets/images/cross.svg"
        alt="cross"
      />
      <div class="title popup-title">
        <h2 v-if="action == 'payment_type'">Change Payment Method</h2>
        <h2 v-else>Add Banking Information</h2>
        <span>Please enter your new Banking Information</span>
      </div>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <b-form
          @submit.stop.prevent="handleSubmit(addBank)"
          class="add-bank-form"
          ref="addBankInfoContainer"
        >
          <input-text
            :rules="{ required: true }"
            labelFor="account-holder-name"
            labelName="Account Holder Name"
            inputName="bank_accountname"
            :vmodel.sync="form.bank_accountname"
          />

          <!-- <input-text
            :rules="{ required: true, digits: 9 }"
            labelFor="routing-number"
            labelName="Routing Number"
            inputName="bank_routing"
            inputType="number"
            :vmodel.sync="form.bank_routing"
            @keyup.native="updateRoutingNumber"
          /> -->

          <b-form-group>
            <validation-provider
              name="Routing Number"
              :rules="{ required: true, digits: 9 }"
              v-slot="validationContext"
            >
              <label id="label-routing-number" for="label-routing-number">
                Routing Number<span style="color:Red">*</span>
              </label>
              <b-form-input
                id="routing-number"
                type="number"
                :state="getValidationState(validationContext)"
                aria-describedby="input-feedback"
                v-model="form.bank_routing"
                placeholder="Enter Routing Number"
                @input="updateRoutingNumber"
              ></b-form-input>
              <b-form-invalid-feedback
                id="input-feedback"
                class="routing-number-feedback"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <input-text
            :rules="{ required: true, regex: /^([a-zA-Z0-9 ])*$/ }"
            labelFor="bank-name"
            labelName="Bank Name"
            inputName="bank_name"
            :vmodel.sync="form.bank_name"
          />

          <input-text
            :rules="{ required: true, min:4, max:17 }"
            labelFor="bank-account"
            labelName="Bank Account"
            inputName="bank_account"
            inputType="number"
            :vmodel.sync="form.bank_account"
          />

          <input-select
            :rules="{ required: true }"
            labelFor="account-type"
            labelName="Account Type"
            inputName="account_type"
            :vmodel.sync="form.account_type"
            :options="accountTypes"
            defaultOption="Select Account Type"
          />

          <input-select
            :rules="{ required: true }"
            labelFor="account-holder-type"
            labelName="Account Holder Type"
            inputName="account_holder_type"
            :vmodel.sync="form.account_holder_type"
            :options="accountHolderTypes"
            defaultOption="Select Account Holder Type"
          />

          <b-form-group>
            <onoff-toggle
              v-model="form.is_primary"
              onColor="#29BCFF"
              offColor="#CCCCCC"
              :shadow="true"
              width="33"
              height="18"
              theme="ios"
            />
            <span class="set_primary">Set as Primary</span>
          </b-form-group>

          <div class="signature">
            <label>Signature <span style="color:red">*</span></label>
            <VueSignaturePad
              height="200px"
              ref="signaturePad"
              :customStyle="signatureStyles"
              :options="{ onBegin, onEnd }"
            />
            <p
              v-if="action == 'payment_type'"
              class="error-text"
              style="color:green;font-style:italic"
            >
              With adding this you are changing your payment method to eft.
            </p>
            <p v-if="signErrorText" class="error-text" style="color:red">
              {{ signErrorText }}
            </p>
            <div class="text-right">
              <a
                href="javascript:void(0);"
                class="clearSignature"
                @click.prevent="clearSignature"
              >
                Clear
              </a>
            </div>
          </div>

          <b-form-group class="submit">
            <button :disabled="submit" @click="clickSignatureMessageHandler">
              {{ submit ? "Processing..." : "Submit" }}
            </button>
          </b-form-group>
        </b-form>
      </ValidationObserver>
    </b-modal>
    <!-- add bank info  -->
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import axios from "axios";
import { validateRoutingNumberUrl } from "@/resource/EndPoints/endpoints";
export default {
  props: ["action"],
  data: () => {
    return {
      form: {
        bank_accountname: "",
        bank_routing: "",
        bank_name: "",
        bank_account: "",
        account_type: null,
        account_holder_type: null,
        sign: "",
        is_primary: false,
      },
      accountTypes: Helper.accountTypes(),
      accountHolderTypes: Helper.accountHolderTypes(),
      signatureStyles: {
        border: "1px solid #ced4da",
      },
      signErrorText: "",
      submit: false,
    };
  },
  methods: {
    closeModal() {
      this.reset();
      this.signErrorText = "";
      this.$bvModal.hide("add_bank_info");
    },
    clickSignatureMessageHandler() {
      this.signErrorMessage();
    },
    signErrorMessage() {
      if (this.form.sign == "") {
        this.signErrorText = "Please input signature here.";
        return false;
      } else {
        this.signErrorText = "";
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    updateRoutingNumber() {
      let url, pattern, routingNumber;
      routingNumber = this.form.bank_routing;
      pattern = new RegExp("^\\d{9}$");
      this.form.bank_name = "";
      if (routingNumber.match(pattern)) {
        const otherUrl = axios.create();
        otherUrl
          .post(
            validateRoutingNumberUrl,
            { routing_number: routingNumber },
            {
              headers: {
                Authorization: "",
              },
            }
          )
          .then((res) => {
            this.form.bank_name = "";
            if (res.data.statusCode == 200) {
              this.form.bank_name = res.data.data.result.bank_name;
              document
                .querySelector("#routing-number")
                .classList.remove("is-invalid");
            } else if (res.data.statusCode == 404) {
              this.form.bank_name = "";
              document
                .querySelector("#routing-number")
                .classList.add("is-invalid");
              document.querySelector(".routing-number-feedback").innerHTML =
                "Invalid Routing Number";
            }
          })
          .catch((err) => {
            this.form.bank_name = "";
            this.$notify("Invalid Routing Number", "Error", "danger");
            document
              .querySelector("#routing-number")
              .classList.add("is-invalid");
            document.querySelector(".routing-number-feedback").innerHTML =
              "Invalid Routing Number";
          });
      }
    },
    reset() {
      this.form = {
        bank_accountname: "",
        bank_routing: "",
        bank_name: "",
        bank_account: "",
        account_type: null,
        account_holder_type: null,
        sign: "",
        is_primary: false,
      };
      this.$refs.signaturePad.clearSignature();
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas();
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (isEmpty == false) {
        this.form.sign = data;
        this.signErrorText = "";
      }
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
      this.form.sign = "";
      this.signErrorText = "";
    },
    addBank() {
      let url = this.getUrl();
      this.signErrorMessage();
      this.submit = true;
      let loader = this.$loading.show({
        container: this.$refs.addBankInfoContainer,
      });
      Api.post(url, this.form)
        .then((res) => {
          let response = res.data.message;
          this.$notify(response);
          this.$store.dispatch("getGroupBanks");
          setTimeout(() => {
            this.reset();
            this.$bvModal.hide("add_bank_info");
          }, 2000);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally(() => {
          setTimeout(() => {
            loader.hide();
            this.submit = false;
          }, 2000);
        });
    },
    getUrl() {
      if (this.action == "payment_type") return "change-payment-method";
      return "add-bank";
    },
  },
  watch: {
    form: {
      handler: function(val, oldVal) {
        // if (val.sign == "") {
        //   this.signErrorText = "Please input signature here.";
        // } else {
        //   this.signErrorText = "";
        // }
      },
      deep: true,
    },
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/banking_information.css"
  scoped
></style>
