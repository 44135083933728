/**
 * helper class to check for device
 * may not execute on older devices and smartphones
 * Reference: https://github.com/ahand/mobileesp/blob/master/JavaScript/mdetect.js
 */

 const ipod = /ipod/i;
 const mobileDevice = /android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
 const iphone = /iPhone/i;
 const ipad = /ipad/i;
 const android = /android/i;
 
 export default class DeviceHelper{
     constructor(){
         this.uagent = navigator.userAgent.toLowerCase();
     }
 
     isMobile(){
        return mobileDevice.test(this.uagent);
     }
 
     isAndroid(){
         return android.test(this.uagent);
     }
 
     isIpod(){
         return ipod.test(this.uagent);
     }
 
     isIphone(){
         return iphone.test(this.uagent);
     }
     
     isIpad(){
         return ipad.test(this.uagent);
     }
 
     isIphoneOrIpod(){
         if(this.isIphone() || this.isIpod()){
             return true;
         }else{
             return false;
         }
     }
 
     isIos(){
         if(this.isIphoneOrIpod() || this.isIpad()){
             return true;
         }else{
             return false;
         }
     }
 }
 