<template>
  <div class="wrapper_group_dashboard vld-parent" ref="memberListContainer">
    <div class="group_dashboard">
      <div class="view_member">
        <breadcrumb :items="breadcrumbItems" />
        <div class="title">
          <h3>View Member</h3>
          <button class="clear_search" @click.prevent="reset">
            Clear Search
          </button>
        </div>
        <div class="search_container">
          <SendMemberEmail :memberDetails=memberDetails />
          <b-form>
            <b-form-group
              id="input-group-1"
              label="First Name"
              label-for="input-1"
            >
              <b-form-input
                id="input-1"
                v-model="filterData.first_name"
                placeholder="Enter First Name"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Last Name"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                v-model="filterData.last_name"
                placeholder="Enter Last Name"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-3"
              label="Rep Code"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="filterData.agent_code"
                placeholder="Enter Rep Code"
              ></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="Status" label-for="input-4">
              <b-form-select
                id="input-4"
                v-model="filterData.status"
                :options="status_option"
              >
                <template #first>
                  <b-form-select-option value="" disabled>
                    Select Status
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <b-form-group id="input-group-4" label="State" label-for="input-4">
              <b-form-select
                id="input-4"
                v-model="filterData.state"
                :options="states"
              >
                <template #first>
                  <b-form-select-option value="" disabled>
                    Select State
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group
              id="input-group-6"
              label="Enrollment Date From"
              label-for="input-6"
            >
              <date-picker
                v-model="filterData.enrollment_date_start"
                valueType="format"
                placeholder="Enter Enrollment Start Date"
                :disabled-date="disabledAfterEnrollmentEndDate"
              ></date-picker>
              <!-- <b-form-input
                id="input-6"
                type="date"
                v-model="filterData.enrollment_date_start"
              ></b-form-input> -->
            </b-form-group>
            <b-form-group
              id="input-group-6"
              label="Enrollment Date To"
              label-for="input-6"
            >
              <date-picker
                v-model="filterData.enrollment_date_end"
                valueType="format"
                placeholder="Enter Enrollment End Date"
                :disabled-date="disabledBeforeEnrollmentStartDate"
              ></date-picker>
              <!-- <b-form-input
                id="input-6"
                type="date"
                v-model="filterData.enrollment_date_end"
              ></b-form-input> -->
            </b-form-group>
            <b-form-group
              id="input-group-7"
              label="Effective Date"
              label-for="input-7"
            >
              <date-picker
                v-model="filterData.effective_date"
                valueType="format"
                placeholder="Enter Effective Date"
              ></date-picker>
            </b-form-group>
            <div class="search">
              <button @click.prevent="filter">Search</button>
            </div>
          </b-form>
        </div>
        <div class="member_detail">
          <div style="overflow-x: auto">
            <table>
              <thead>
                <tr>
                  <td width="10%">System ID</td>
                  <td width="20%">Name</td>
                  <td width="14%">Dates</td>
                  <td width="14%">Rep & Employee</td>
                  <td width="32%">Premiums</td>
                  <td width="10%">Actions</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in rows.records"
                  :key="index"
                  :class="item.status | backgroundClass"
                >
                  <td>
                    {{ item.policy_id }}<br />
                    <span :class="item.status | colorClass">
                      {{ item.status }}
                    </span>
                  </td>
                  <td>
                    <strong>
                      {{ item.first_name + " " + item.last_name }}
                    </strong>
                    <br />
                    <span
                      v-html="
                        formattedAddress(
                          item.member_home_address.address1,
                          item.member_home_address.address2,
                          item.member_home_address.city,
                          item.member_home_address.state,
                          item.member_home_address.zip
                        )
                      "
                    />
                  </td>
                  <td>
                    <strong>EnrollmentDate :</strong><br />
                    {{ formattedDate(item.enrollment_date) }}<br /><br />
                    <strong>Effective Date :</strong><br />
                    {{ formattedDate(item.effective_date) }}<br />
                    <br />
                    <strong>Paid Through :</strong><br />
                    {{ formattedDate(item.paid_through_date) }}<br />
                    <br />
                    <strong>Bill Date :</strong><br />
                    {{ item.bill_date ? item.bill_date : "N/A" }}
                  </td>
                  <td>
                    <strong>
                      {{ item.rep_first_name + " " + item.rep_last_name }}
                    </strong>
                    <br />
                    {{ item.agent_code }}<br /><br />
                    <strong> Employer Name </strong>
                    <br />
                    {{ item.employer_name }}
                  </td>
                  <td>
                    <div class="sub_table" style="overflow-x: auto">
                      <table>
                        <tbody>
                          <tr
                            v-for="(val, index) in item.plan_details"
                            :key="index"
                          >
                            <td
                              :class="
                                val.plan_status == 'TERMED' ||
                                val.plan_status == 'WITHDRAWN'
                                  ? 'text-red'
                                  : ''
                              "
                            >
                              <span
                                v-html="handleNewLineText(val.plan_title)"
                              />
                              <template
                                v-if="
                                  val.plan_status == 'TERMED' ||
                                  val.plan_status == 'WITHDRAWN'
                                "
                              >
                                <br />
                                {{
                                  "(" +
                                  formattedDate(val.plan_termed_date) +
                                  ")"
                                }}
                              </template>
                            </td>
                            <td>
                              {{ formattedDate(val.plan_effective_date) }}
                            </td>
                            <td>{{ val.plan_price | currency }}</td>
                          </tr>
                          <tr v-if="item.payment_type =='cc'"><td>Payment Service Fee</td><td></td><td>{{ item.cc_fee | currency }}</td></tr>
                          <tr style="border-top: 1px solid #d7e1ea">
                            <td style="border-top: 1px solid #d7e1ea">
                              <strong>Recurring Monthly</strong>
                            </td>
                            <td></td>
                            <td>
                              <strong>{{
                                item.recurring_amount | currency
                              }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td style="vertical-align: middle" width="10%">
                    <div class="icons">
                      <router-link
                        :to="{
                          name: 'MemberDetail',
                          params: { policyId: item.policy_id },
                        }"
                      >
                        <span class="action_icon" v-tooltip="'View Details'">
                          <i class="fas fa-info"></i>
                        </span>
                      </router-link>
                      <!-- <span class="action_icon" v-tooltip="'View Report'"
                        ><i class="fas fa-file"></i
                      ></span> -->
                      <span @click="showSendEmailModal(item)" class="action_icon" v-tooltip="'Send Email'"
                        ><i class="fas fa-envelope"></i
                      ></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <template v-if="rows.records && rows.records.length < 1">
            <no-content />
          </template>
          <div class="overflow-auto">
            <pagination :rows.sync="rows" :filters="filterData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import moment from "moment";
import SendMemberEmail from "@/pages/Member/SendEmail";
export default {
  name: "MemeberList",
    components: { SendMemberEmail },

  data() {
    return {
      selected: "active",
      status_option: [
        { value: "Active", text: "Active" },
        { value: "Termed", text: "Termed" },
        { value: "Withdrawn", text: "Withdrawn" },
      ],
      members: [],
      rows: [],
      filterData: {
        first_name: "",
        last_name: "",
        policy_id: "",
        status: "",
        agent_code: "",
        state: "",
        enrollment_date_start: "",
        enrollment_date_end: "",
        effective_date: "",
      },
      states: Helper.getStates(),
      breadcrumbItems: [{ title: "Members", active: true }],
      memberDetails: []
    };
  },
  filters: {
    colorClass(item) {
      if (item == "TERMED") {
        return "termed_status";
      } else if (item == "WITHDRAWN") {
        return "withdrawn_status";
      } else {
        return "active_status";
      }
    },
    backgroundClass(item) {
      if (item == "TERMED") {
        return "termed_status_row";
      } else if (item == "WITHDRAWN") {
        return "withdrawn_status_row";
      } else {
        return "active_status_row";
      }
    },
  },
  methods: {
    formattedAddress(address1, address2, city, state, zip) {
      address1 = this.$formattedDataWithComma(address1);
      address2 = this.$formattedDataWithComma(address2);
      city = this.$formattedDataWithComma(city);
      state = this.$formattedDataWithComma(state);
      zip = zip ? zip : "";
      return `${address1} ${address2} ${city}` + " <br/>" + ` ${state} ${zip}`;
    },
    disabledAfterEnrollmentEndDate(date) {
      const formattedDate = new Date(
        new Date(this.filterData.enrollment_date_end).setHours(0, 0, 0, 0)
      );
      return date > formattedDate.setDate(formattedDate.getDate() - 1);
    },
    disabledBeforeEnrollmentStartDate(date) {
      const formattedDate = new Date(
        new Date(this.filterData.enrollment_date_start).setHours(0, 0, 0, 0)
      );
      return date < formattedDate.setDate(formattedDate.getDate() + 1);
    },
    handleNewLineText(text) {
      return text.replace("\n", "<br/>");
    },
    formattedDate(date) {
      if (moment(date, "MM/DD/YYYY", true).isValid()) {
        return moment(date, "MM/DD/YYYY", true).format("YYYY-MM-DD");
      }
      return "N/A";
    },
    filter() {
      this.getMembers(this.filterData);
    },
    reset() {
      this.filterData = {
        first_name: "",
        last_name: "",
        policy_id: "",
        status: "",
        agent_code: "",
        state: "",
        enrollment_date_start: "",
        enrollment_date_end: "",
        effective_date: "",
      };
      this.getMembers(this.filterData);
    },
    getMembers(filters = []) {
      let url;
      let loader = this.$loading.show();

      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `get-members?${filterUrl}`;
      } else {
        url = `get-members`;
      }
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch((error) => {
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
    showSendEmailModal(memberDetails) {
        this.memberDetails = memberDetails;
        this.$bvModal.show("send_member_email");
    }
  },
  mounted() {
    if (this.$route.params.status) {
      this.filterData.status = this.$route.params.status;
      this.getMembers(this.filterData);
    } else {
      this.getMembers();
    }
  },
  watch: {
    rows: function (newData, oldData) {
      if (newData != oldData) {
        this.rows = newData;
      }
    },
  },
};
</script>
<style src="@/assets/css/group_dashboard/view_member.css"></style>
<style scoped>
.group_dashboard {
  display: block;
}

.text-red {
  color: red;
}
</style>
