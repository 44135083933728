<template>
    <a
      href="javascript:void(0);"
      v-b-tooltip.hover
      :title="title"
      :class="className"
      @click="clicked"
    >
      <i v-if="iconClass" :class="iconClass" />
      {{ haveText ? title : "" }}
    </a>
  </template>
  
  <script>
  export default {
    name: "CustomButton",
    props: {
      title: {
        type: String,
        required: false,
        default: "Title",
      },
      iconClass: {
        type: String,
        required: false,
        default: "",
      },
      routeLink: {
        required: false,
        default: null,
      },
      className: {
        type: String,
        required: false,
      },
      haveText: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    methods: {
      clicked() {
        this.$emit("onClicked");
      },
    },
  };
  </script>