import Vue from "vue"
import Vuex from "vuex"
import Dashboard from "./modules/dashboard"
import Auth from "./modules/auth"
import App from "./modules/app"
import createPersistedState from "vuex-persistedstate";
import axios from "axios"
import router from "@/router/index"
import Registration from "./modules/registration"
import * as Cookies from "js-cookie";

Vue.use(Vuex)
let expiryHour = val => new Date(new Date().getTime() + val * 3600 * 1000);
const vuexStore = new Vuex.Store({
    plugins: [
        createPersistedState({
            paths: ["Auth", "App", "Dashboard","Registration"],
        }),
        /**
         * will be reset after 12 hours 
         */
        createPersistedState({
            paths: ["Registration"],
            key: "registration",
            storage: {
                getItem: (key) => Cookies.get(key),
                setItem: (key, value) =>
                    Cookies.set(key, value, { expires: expiryHour(12), secure: false }),
                removeItem: (key) => Cookies.remove(key),
            }
        }),
    ],
    modules: {
        Auth,
        App,
        Dashboard,
        Registration
    }
})

axios.interceptors.request.use((config) => {
    config.headers['Authorization'] = vuexStore.getters.accessToken != "" ? "Bearer " + vuexStore.getters.accessToken : "";
    return config;
});

axios.interceptors.response.use(response => response, function (error) {

    if (error?.response?.data?.message == 'Unauthorized' || error?.response?.status == 418) {
        router.push({ name: "Logout" });
    }
    return Promise.reject(error);
});

export default vuexStore;
