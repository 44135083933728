<template>
  <div class="side_nav">
    <sidebar-menu
      :menu="menu"
      :collapsed="is_mobile_nav"
      @item-click="onItemClick"
      showOneChild
    />
  </div>
</template>

<script>
/*eslint-disable */
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import home from "@/assets/images/ionic-md-home.svg";
import manage from "@/assets/images/manage.svg";
import report from "@/assets/images/report.svg";
import messageCenter from "@/assets/images/material-message.svg";
import tickets from "@/assets/images/tickets.svg";
import groupLogout from "@/assets/images/logout-icon.svg";
import analytics from "@/assets/images/analytics.svg";
import AddUsers from "../../pages/User/AddUser.vue";
import helper from "@/resource/Helper";
import AdminMessageCenter from "../../pages/AdminMessage/AdminMessageCenter.vue"
import directory from '@/assets/images/directory.svg'
import { mapGetters } from "vuex";
export default {
  components: {
    SidebarMenu,
    AddUsers,
    AdminMessageCenter,
  },
  data() {
    return {
      menu: [
        {
          href: "/",
          title: "Home",
          icon: {
            element: "img",
            attributes: { src: home },
          },
        },
        {
          href: "/directory",
          title: "Directory",
          icon: {
            element: "img",
            attributes: { src: directory },
          },
        },
        {
          href: "#1",
          title: "Manage",
          icon: {
            element: "img",
            attributes: { src: manage },
          },
          child: [
            {
              href: "/members",
              title: "Members",
              class: "submenu-tag",
            },
            // {
            //   href: "/view-users",
            //   title: "View Users",
            //   class: "submenu-tag",
            // },
            {
              href: "/files",
              title: "Files",
              class: "submenu-tag",
            },
             {
              href: "/configured-plans",
              title: "Configured Plans",
              class: "submenu-tag",
            },
             {
               href:"/users",
               title: "View Users",
               class: "submenu-tag",
             },
            // {
            //   href: "/upload-files",
            //   title: "Upload Files",
            //   class: "submenu-tag",
            // },
            //  {
            //    href: "/admin-messaging-center",
            //    title: "Admin Messaging Center",
            //    class: "submenu-tag",
            //  },
            //  {
            //    href: "/ticketing",
            //    title: "Ticketing",
            //    class: "submenu-tag",
            //  },
          ],
        },
        {
          href: "#2",
          title: "Reports",
          icon: {
            element: "img",
            attributes: { src: report },
          },
          child: [
            {
              href: "/member-contact-report",
              title: "Member Contact Report",
              class: "submenu-tag",
            },
          ],
        },
        {
          href: "/admin-messaging-center",
          title: "Admin Messaging Center",
          icon: {
            element: "img",
            attributes: { src: messageCenter },
          },
         },
        // {
        //   href: "/ticketing",
        //   title: "Ticketing",
        //   icon: {
        //     element: "img",
        //     attributes: { src: tickets },
        //   },
        //  },
        /*{
          href: this.$store.getters.status.analytics_link,
          title: "Analytics",
          external: true,
          icon: {
            element: "img",
            attributes: { src: analytics },
          },
        },*/
        {
          href: "/logout",
          title: "Logout",
          icon: {
            element: "img",
            attributes: { src: groupLogout },
          },
        },
      ],

      is_mobile_nav: false,
    };
  },
  methods: {
    showModal() {
      this.$refs["add-user"].show();
    },
    onItemClick(event, item, node) {
      if (item.title == "Logout") {
        helper.logout();
      }
    },
  },
  mounted() {
    this.is_mobile_nav = screen.width <= 991 ? true : false;
    window.addEventListener("resize", function() {
      if (
        (document.querySelectorAll(".vsm_expanded").length &&
          screen.width <= 991) ||
        (!document.querySelectorAll(".vsm_expanded").length &&
          screen.width > 991)
      ) {
        document
          .querySelector(".vsm--toggle-btn")
          .dispatchEvent(new Event("click"));
      }
    });
  },
};
</script>
<style src="@/assets/css/group_dashboard/group_sidenav.css"></style>
