<template>
  <div>
    <span class="userStatus" :class="policyStautsClass">
      <i class="far fa-check-circle"></i>
      {{ policy.status }}
    </span>
    <h3>Policy ID : #{{ policy.policy_id }}</h3>
    <div class="light-bg">
      <ul>
        <!-- <li>
                      <span class="strong">Status</span>
                      <span>: {{ policy.status }}</span>
                    </li> -->
        <li>
          <span class="strong">Enrollment Date:</span>
          <span>
            {{
              policy.enrollment_date | date_format('MM/DD/YYYY', 'YYYY-MM-DD')
            }}
          </span>
        </li>
        <li>
          <span class="strong">Effective Date:</span>
          <span>
            {{
              policy.effective_date | date_format('MM/DD/YYYY', 'YYYY-MM-DD')
            }}
          </span>
        </li>
        <li v-if="policy.status == 'TERMED' || policy.status == 'WITHDRAWN'">
          <span class="strong">Term Date:</span>
          <span>
            {{ policy.term_date | date_format('MM/DD/YYYY', 'YYYY-MM-DD') }}
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    policy: {
      required: true,
    },
  },
}
</script>
