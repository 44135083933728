import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import { BootstrapVue } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { FormPlugin } from 'bootstrap-vue'
import { DropdownPlugin } from 'bootstrap-vue'
import { TabsPlugin } from 'bootstrap-vue'
import { PaginationPlugin } from 'bootstrap-vue'
import { IconsPlugin } from 'bootstrap-vue'
import { FormDatepickerPlugin } from 'bootstrap-vue'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import OnoffToggle from 'vue-onoff-toggle'
import tooltip from 'vue-simple-tooltip'
import UUID from "vue-uuid";
import config from "./config/config"
import store from "./store"
import EndPoint from "./resource/EndPoints/Endppoint.vue"
import VueSignaturePad from 'vue-signature-pad';
import VueConfirmDialog from 'vue-confirm-dialog';
import VueSlimScroll from 'vue-slimscroll'
import VueCarousel from 'vue-carousel';
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Multiselect from 'vue-multiselect'

import InputText from "@/components/form/InputText";
import InputSelect from "@/components/form/InputSelect";
import InputImage from "@/components/form/InputImage";
import InputFile from "@/components/form/InputFile";
import InputTextarea from "@/components/form/InputTextarea";

import Breadcrumb from "@/components/ui/Breadcrumb";
import NoContent from "@/components/ui/NoContent";
import Pagination from "@/components/ui/Pagination";
import EyeButton from "@/components/ui/EyeButton"
import PasswordStrengthBar from "@/components/ui/PasswordStrengthBar";
import CircleCounter from "@/components/ui/CircleCounter";
import CloseModalIcon from "@/components/ui/CloseModalIcon"
import DownloadButton from "@/components/ui/DownloadButton"
import DeleteButton from "@/components/ui/DeleteButton"
import ToggleElement from "@/components/ui/ToggleElement"
import CKEditor from '@ckeditor/ckeditor5-vue2';
import vuescroll from 'vuescroll';
// config.config()
Vue.use(vuescroll);
Vue.use(BootstrapVue)
Vue.use(ModalPlugin)
Vue.use(FormPlugin)
Vue.use(DropdownPlugin)
Vue.use(TabsPlugin)
Vue.use(PaginationPlugin)
Vue.use(IconsPlugin)
Vue.use(FormDatepickerPlugin)
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.use(OnoffToggle);
Vue.use(tooltip);
Vue.use(UUID);
Vue.use(VueSignaturePad);
Vue.use(VueConfirmDialog);
Vue.use(VueSlimScroll);
Vue.use(VueCarousel);
Vue.use(CKEditor);
Vue.component('multiselect', Multiselect)

Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
Vue.component('breadcrumb', Breadcrumb)
Vue.component('date-picker', DatePicker)
Vue.component('pagination', Pagination)
Vue.component('no-content', NoContent)
Vue.component('eye-button', EyeButton)
Vue.component('password-strength-bar', PasswordStrengthBar)
Vue.component('circle-counter', CircleCounter)
Vue.component('close-modal-icon',CloseModalIcon)
Vue.component('download-button',DownloadButton)
Vue.component('delete-button',DeleteButton)

Vue.component('input-text', InputText)
Vue.component('input-select', InputSelect)
Vue.component('input-image', InputImage)
Vue.component('input-file', InputFile)
Vue.component('input-textarea',InputTextarea)
Vue.component('toggle-element',ToggleElement)

import ErrorText from "@/components/ui/ErrorText"
Vue.component('error-text', ErrorText);

import RegInputText from '@/pages/Registration/utils/RegInputText.vue';
Vue.component('reg-input-text',RegInputText)

import RegInputSelect from '@/pages/Registration/utils/RegInputSelect.vue';
Vue.component('reg-input-select',RegInputSelect)

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import "@/assets/css/loader.css"
import "@/assets/css/toaster.css"
import loaderImg from "@/assets/images/loader.png";
import '@/assets/css/preloader.css';
import  customRules from '../src/resource/Rules/rules'

const custom = Vue.extend({
  render() {
    return (
      <div class="row">
        <div class="loader-logo">
          <img class="img-fluid" src={loaderImg} />
        </div>
      </div>
    );
  }
});

Vue.component('custom-loader', custom);
Vue.use(Loading, {
  opacity: 0.5,
  zIndex: 9999,
}, {
  default: (new Vue()).$createElement('custom-loader')
});
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Object.keys(customRules).forEach(rule => {
  extend(rule,customRules[rule])
});

// extend("group_logo_dimension", {
//   message: "The logo must be either of dimension 250 X 90 or 100 X 100",

//   validate: () => {
//     let height, width;
//     height = width == 100 ? 100 : 90
//     width = height == 100 ? 100 : 250
//     const image = new Image();
//     image.onerror = () => resolve({ valid: false });
//     image.onload = () => resolve({
//       valid: image.width == 250 && image.height == 90
//     });
//     console.log('widht',width)
//     console.log('height',height)
//   },
// });

localize("en", en);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);


Vue.prototype.$endpoint = EndPoint
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);

Vue.prototype.$notify = (message, title = "Error", type = "success", position = 'top-right') => {
  const vm = new Vue()

  let typeTitle;
  typeTitle = type == "danger" ? 'error' : type
  vm.$toast.open({
    message: message,
    type: typeTitle.toLowerCase(),
    duration: 5000,
    dismissible: true,
    position: position
  })
}

Vue.filter('truncate', function (value, limit) {
  if (value.length > limit) {
    return value.substring(0, limit) + "..."
  }
  return value
})

Vue.filter('date_format', function (value, defaultFormat = "YYYY-MM-DD", dateFormat = "MM/DD/YYYY") {
  if (moment(value, defaultFormat, true).isValid()) {
    return moment(value, defaultFormat, true).format(dateFormat);
  }
  return "N/A";
})

Vue.filter('currency', function (value) {
  if (value % 1 == 0) {
    return `$${parseInt(value)}.00`;
  }
  return `$${value}`;
})

Vue.prototype.$formattedDataWithComma = (data) => {
  return !(data == null || data === '') ? data + ',' : ''
},


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')