<template>
  <div class="invoice_details">
    <div class="total_payment">
      <ul>
        <li>
          Total Policies: <span>{{ invoice.policy_count }}</span>
        </li>
        <li>
          Total Unpaid Policies: <span>{{ this.unPaidInvoices.length }}</span>
        </li>
        <li>
          Total Paid Policies: <span>{{ invoice.policy_count }}</span>
        </li>
        <li>
          Total Policy Amount:
          <span>{{ invoice.total_invoice | currency }}</span>
        </li>
        <li>
          Total Previous Due:
          <span>{{ invoice.total_previous_due | currency }}</span>
        </li>
        <li>
          Total Due Amount:
          <span>{{ invoice.total_invoice_due_amount | currency }}</span>
        </li>
        <li>
          Total Paid Amount:
          <span>{{ invoice.total_invoice_due_paid_amount | currency }}</span>
        </li>
        <li>
          Total Processing Amount:
          <span>{{ invoice.processing_amount | currency }}</span>
        </li>
        <li>
          Selected Payment Amount: <span>{{ totalSum | currency }}</span>
        </li>
      </ul>
      <!-- <h6>
        Total Payment:
        <span>{{ totalSum }}</span>
      </h6> -->
    </div>
    <div class="select_invoice_details">
      <h5>Select Invoice Details</h5>
      <div class="select_all">
        <b-form-checkbox v-model="selectAll" @change="select">
          <span>Select All</span>
        </b-form-checkbox>
      </div>
      <div class="select_invoice_table">
        <table>
          <thead>
            <tr>
              <td></td>
              <td>Invoice ID</td>
              <td>Primary Name</td>
              <td>Due amount</td>
            </tr>
          </thead>
          <tbody v-if="unPaidInvoices.length > 0">
            <tr v-for="(item, index) in unPaidInvoices" :key="index">
              <td style="text-align:center">
                <!-- <input
                  type="checkbox"
                  v-model="invoiceIds"
                  :value="item.invoice_id"
                  @change="updateCheckall()"
                  style="margin-top:5px;width:100%;"
                /> -->

                <b-form-checkbox
                  v-model="invoiceIds"
                  :id="'invoice-' + item.invoice_id + '-checkbox'"
                  :value="item.invoice_id"
                  @change="updateCheckall()"
                  style="margin-top:5px;width:100%;"
                />
              </td>
              <td>{{ item.invoice_id }}</td>
              <td>{{ item.cfname + " " + item.clname }}</td>
              <td>{{ item.invoice_due_amount | currency }}</td>
            </tr>
          </tbody>
        </table>
        <template v-if="unPaidInvoices.length < 1">
          <no-content text="No Invoices for payment" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      invoiceIds: [],
      selectAll: true,
    };
  },
  computed: {
    ...mapGetters({ unPaidInvoices: "unPaidInvoices" }),
    totalSum() {
      let total = 0;
      total = this.unPaidInvoices.reduce((total, value) => {
        if (this.invoiceIds.includes(value.invoice_id)) {
          total = total + Number(value.invoice_due_amount);
        }
        return total;
      }, 0);
      return total;
    },
  },
  methods: {
    select(checked) {
      this.invoiceIds = [];
      if (checked) {
        for (let i in this.unPaidInvoices) {
          this.invoiceIds.push(this.unPaidInvoices[i].invoice_id);
        }
      }
    },
    updateCheckall() {
      if (this.unPaidInvoices.length == this.invoiceIds.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
  },
  created() {
    //creating this invoicesIds to set checkbox checked
    this.invoiceIds = this.$store.getters.invoiceIds;
    if (this.invoiceIds.length < 1) {
      this.select(this.selectAll);
    }
    this.updateCheckall();
  },
  watch: {
    invoiceIds: function(newData, oldData) {
      console.log(this.invoiceIds);
      if (newData != oldData) {
        // this.$emit("setSelectedInvoiceIds", newData);
        this.$store.commit("setInvoiceIds", newData);
      }
    },
  },
};
</script>
<style scoped>
.select_invoice_table {
  height: 300px;
  overflow-y: auto;
}
</style>
