<template>
  <b-modal id="add_group_user" centered hide-footer hide-header size="lg">
    <img
      @click="$bvModal.hide('add_group_user')"
      class="close_cross"
      src="../../assets/images/cross.svg"
      alt="cross"
    />
    <div class="title">
      <h2>Add Group User</h2>
      <span>Please Add Group User Data</span>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(addUser)">
        <input-text
          :rules="{ required: true }"
          labelFor="first_name"
          labelName="First Name"
          inputName="first_name"
          :vmodel.sync="form.first_name"
        />
        <input-text
          :rules="{ required: true }"
          labelFor="last_name"
          labelName="Last Name"
          inputName="last_name"
          :vmodel.sync="form.last_name"
        />
        <input-text
          :rules="{ required: true, email: true,email_verify:true }"
          labelFor="user_email"
          labelName="User Email"
          inputName="user_email"
          :vmodel.sync="form.login_email"
        />

         <input-text
          :rules="{ required: true, numeric: true, digits: 10,clearout_phone:true }"
          labelFor="user_phone"
          labelName="Enter Phone"
          inputName="user_phone"
          :vmodel.sync="form.login_phone"
        />

        <b-form-group>
          <validation-provider
            name="password"
            :rules="{ required: true,custom_password: true }"
            v-slot="validationContext">
            <label id="label-password" for="label-password-number">
              User Password<span style="color:red">*</span>
            </label>
            <b-form-input
              id="group_user_password"
              type="text"
              :state="getValidationState(validationContext)"
              aria-describedby="input-feedback"
              v-model="form.password"
              placeholder="Enter Password"
            ></b-form-input>
            <eye-button
              id="group_user_password"
              class="toggle-eye-icon"
              hideOnDefault="true"
              type="text"
            ></eye-button>
            <b-form-invalid-feedback
              id="input-feedback password-feedback"
              class="password-feedback"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
            <password-strength-bar  @updateScore="setPasswordScore" :password="form.password">
            </password-strength-bar>
          </validation-provider>
        </b-form-group>



        <b-form-group class="submit">
          <button :disabled="formDisabled">
            {{ submit ? "Processing..." : "Submit" }}
          </button>
        </b-form-group>
      </b-form>
    </ValidationObserver>
  </b-modal>

  <!-- Personal Address Update Ends -->
</template>
<script>
import Api from "@/resource/Api";
import CommonValidator from "@/resource/CommonValidator";

export default {
  data() {
    return {
      form: this.initialData(),
      submit: false,
      formDisabled : false,
      validPhone: false,
      validEmail: false,
      validPassword: false
    };
  },
  props: ['userList'],

  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    addUser() {
      let url = "group-user/add";
      this.submit = true;
      this.formDisabled = true;
      let loader = this.$loading.show({
        container: this.$refs.addBankInfoContainer,
      });
      Api.post(url, this.form)
        .then((res) => {
        
          this.userList()
          let response = res.data.message;
          this.$notify(response);
          
          setTimeout(() => {
            this.form = this.initialData()
            this.$bvModal.hide("add_group_user");
          }, 2000);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally(() => {
          setTimeout(() => {
            loader.hide();
            this.submit = false;
          }, 2000);
        });
    },
    initialData() {
      return {
        first_name: "",
        last_name: "",
        login_email: "",
        login_phone: "",
        password: "",
      }
    },
    setPasswordScore(score) {
      this.validPassword = (score >= 60);
    },
    formCheck() {
      this.formDisabled  = !(this.validPassword && this.validPhone);
    }
  },
};
</script>
<style scoped>
/* add group user  */
#add_group_user .title {
  display: block;
  text-align: center;
  margin-top: 30px;
}
#add_group_user .title h2 {
  font-size: 24px;
  color: #2c3f58;
  margin-bottom: 6px !important;
}
#add_group_user .title span {
  font-size: 14px;
  color: #778699;
}
#add_group_user form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 25px;
}
#add_group_user .form-group {
  width: 50%;
  padding: 5px 22px;
  font-size: 14px;
  color: #254050;
}
#add_group_user input {
  font-size: 14px;
  color: #778699;
  /* border:1px solid #03C3EC; */
  border-radius: 0.25 rem;
}

#add_group_user {
  position: relative;
}
.close_cross {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
  width: 55px;
}
#add_group_user .submit {
  width: 55%;
  margin: 20px auto 30px auto;
}
#add_group_user .submit button {
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(#00ccff 0%, #1597b8 49%, #0567bc 100%);
  color: #fff;
}
.user_action_activate {
  margin-left: 1%;
  background: #33c733 !important;
}

.toggleSSN {
  position: relative !important;
  top: -25px;
  left: 103%;
}

/* add group user  */
</style>
