<template>
  <onoff-toggle
    onColor="#29BCFF"
    offColor="#CCCCCC"
    :shadow="true"
    width="37"
    height="22"
    theme="ios"
    v-model="computedInputData"
  />
</template>
<script>
export default {
  props: {
    vmodel: {
      required: true,
    },
  },
  computed: {
    computedInputData: {
      get: function () {
        let val = this.vmodel;
        return val == 1 ? true : false;
      },
      set: function (value) {
        value = value == 1 ? true : false;
        this.$emit("update:vmodel", value ? value : "");
      },
    },
  },
};
</script>