<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="configured_plans_main">
        <div class="configured_plans_detail">
          <h2>Configured Plans</h2>
        </div>
        <div>
          <add-plan />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AddPlan from "./AddPlan";
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";

export default {
  components: { AddPlan },
  name: "PlanConfigurationList",
  data() {
    return {
      rows: [],
      filterData: {},
    };
  },
  methods: {
    async toggleConfiguration(id) {
      let loader = this.$loading.show();
      let url = `plan-configuration/feature-configuration/${id}`;
      await Api.put(url)
          .then((res) => {
            let response = res.data.message;
            this.$notify(response);
            this.getPlans();
          })
          .catch((err) => {
            this.$notify(err.response.data.message, "Error", "danger");
          })
          .finally(() => {
            loader.hide();
          });
    },
    deleteConfiguration(id) {
      let url = `plan-configuration/delete-configuration/${id}`;
      this.$store.commit("setDialogClass", "group-app-warning-dialog");
      this.$confirm({
        title: "Delete Configuration",
        message: `Do you want to delete this configuration?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
                .then((res) => {
                  let response = res.data.message;
                  this.getPlans();
                  this.$notify(response);
                })
                .catch((err) => {
                  this.$notify(err.response.data.message, "Error", "danger");
                })
                .finally(() => {
                  loader.hide();
                });
          }
        },
      });
    },
    getPlans(filters = []) {
      let url;
      let loader = this.$loading.show();

      if (filters) {
        let filterUrl = Helper.objToUrlParams(filters);
        url = `plan-configuration/get-configuration?page_size=10&${filterUrl}`;
      } else {
        url = `plan-configuration/get-configuration?page_size=10`;
      }

      Api.get(url)
          .then((res) => {
            this.rows = res.data;
          })
          .catch((err) => {
            console.log("Something went wrong!");
          })
          .finally(() => {
            loader.hide();
          });
    },
  }
};
</script>
<style src="../../assets/css/group_dashboard/configured_plans.css">
</style>
