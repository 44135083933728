<script>
const VALIDATE_SSN = process.env.VUE_APP_VALIDATE_SSN
const VALIDATE_PHONE_NUMBER = process.env.VUE_APP_VALIDATE_PHONE_NUMBER
const VALIDATE_ADDRESS = process.env.VUE_APP_VALIDATE_ADDRESS
const VALIDATE_ROUTING_NUMBER = process.env.VUE_APP_VALIDATE_ROUTING_NUMBER
const VALIDATE_EMAIL = process.env.VUE_APP_VALIDATE_EMAIL

export default  {
  VALIDATE_SSN: VALIDATE_SSN,
  VALIDATE_PHONE_NUMBER: VALIDATE_PHONE_NUMBER,
  VALIDATE_ADDRESS: VALIDATE_ADDRESS,
  VALIDATE_ROUTING_NUMBER: VALIDATE_ROUTING_NUMBER,
  VALIDATE_EMAIL: VALIDATE_EMAIL,

  /*Login APIs */
  LOGIN : "auth/login",
  SEND_LOGIN_OTP : "auth/authenticate",
  VERIFY_LOGIN_OTP : "auth/authenticate-code",
 
 /*Forgot Password */
  SEND_FORGOT_PASSWORD_OTP : "auth/reset-password",
  VERIFY_PASSWORD_OTP : "auth/reset-password-check",
  CHANGE_PASSWORD_API : "auth/reset-password-code",
 
}

</script>