<template>
  <div class="messages_container">
    <div class="messages">
      <div class="title">
        <h2>Knowledge Center</h2>
        <template v-if="groupKnowledges.length > 0">
          <router-link class="link-hover" :to="{ name: 'KnowledgeCenterList' }">
            <span class="view-all">View All</span>
          </router-link>
        </template>
      </div>
      <div class="column" v-if="groupKnowledges.length > 0">
        <div
          class="row"
          v-for="(item, index) in groupKnowledges.slice(0, 10)"
          :key="index"
        >
          <div class="message_information" @click="detail(item)">
            <i
              style="font-size:25px;color:#29BCFF;margin-right:10px;"
              class="fas fa-newspaper"
            ></i>
            <div>
              <span class="message_title">{{ item.title | truncate(25) }}</span>
              <br />
              <span class="message_subject">
                {{ item.published_date }}
              </span>
            </div>
          </div>
          <div>
            <!-- <span class="message_opens"></span><span class="message_date">{{item.date}}</span> -->
          </div>
        </div>
      </div>
      <div class="column" v-else>
        <no-content text="No knowledge center data found." />
      </div>
    </div>
    <knowledge-center-detail :data="knowledgeCenterDetail" />
  </div>
</template>
<script>

import axios from "axios";
import { apiBaseUrlV2 } from "@/resource/EndPoints/endpoints";
import KnowledgeCenterDetail from "../KnowledgeCenter/KnowledgeCenterDetail.vue";
export default {
  data: () => {
    return {
      knowledgeCenterDetail: {},
      groupKnowledges: [],
    };
  },
  components: { KnowledgeCenterDetail },
  mounted() {
    this.getKnowledgeFeed();
  },
  methods: {
    detail(item) {
      this.knowledgeCenterDetail = item;
      this.$bvModal.show("knowledge-center-detail");
    },
   getKnowledgeFeed() {
       let url = `${apiBaseUrlV2}knowledge-feed/merged-feed`;
      return axios
        .get(url)
        .then((response) => {
          this.groupKnowledges = response.data.data;
        })
        .error((err) => {});
    },
  },
};
</script>
<style src="@/assets/css/group_dashboard/messages.css" scoped>
@media only screen and (max-width: 767px) {
  .message_container {
    padding-top: 0;
  }
}
</style>
