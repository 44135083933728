<template>
  <div>
    <h4>Plans</h4>
    <div class="light-bg">
      <ul>
        <li
          v-for="plan in plans"
          :key="plan.plan_id"
          :class="
            plan.plan_status == 'TERMED' || plan.plan_status == 'WITHDRAWN'
              ? 'plan-inactive'
              : ''
          "
        >
          <span class="strong">
            {{ plan.plan_title }}:
            <template
              v-if="
                plan.plan_status == 'TERMED' || plan.plan_status == 'WITHDRAWN'
              "
            >
              <br />
              (
              {{
                plan.plan_termed_date | date_format('MM/DD/YYYY', 'YYYY-MM-DD')
              }}
              )
            </template>
          </span>
          <span>{{ plan.plan_price | currency }}</span>
        </li>
        <li v-if="billingMethod.payment_type == 'cc'">
          <span class="strong">Payment Service Fee</span>
          <span>{{ ccFee | currency }}</span>
        </li>
        <hr />
        <li>
          <span class="strong">Recurring Monthly:</span>
          <span>{{ recurringAmount | currency }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    plans: {
      required: true,
    },
    billingMethod: {
      required: true,
    },
    recurringAmount: {
      required: true,
    },
  },
}
</script>
