<template>
  <b-form-group :class="formGroupClass">
    <validation-provider
      :name="labelName"
      :rules="rules"
      v-slot="validationContext"
    >
      <label :id="`label-${labelFor}`" :for="labelFor">
        <span v-html="computedLabel" />
      </label>
        <b-form-file
          :id="labelFor"
          browse-text="Choose File"
          :state="getValidationState(validationContext)"
          @change="fileHandler"
          v-model="computedInputData"
          :readonly="readonly"
          :placeholder="computedPlaceholder"
          :accept="computedMimeTypes"
        >
        </b-form-file>
        
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
     
    </validation-provider>

  </b-form-group>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import imageSrc from "@/assets/images/upload-dummy.png";
export default {
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Object,
      required: false,
      default: null,
    },
    labelName: {
      type: String,
      required: true,
    },
    labelFor: {
      type: String,
      required: true,
    },
    inputName: {
      type: String,
      required: true,
    },
    vmodel: {
      required: true,
      default: null,
    },
    formGroupClass: {
      type: String,
      required: false,
    },
    fileClass:{
      type:String,
      required:false
    },
    acceptMimeTypes:{
      type:Array,
      required:false,
      default: () => []
    }
  },
  components: {
    ValidationProvider,
  },
  data: () => {
    return {
      file: null,
    };
  },
  computed: {
    computedMimeTypes(){
      return this.acceptMimeTypes.toString()
    },
    computedPlaceholder() {
      if (this.placeholder == "") {
        return `No File Choosen`;
      }
      return this.placeholder;
    },
    computedLabel() {
      if (this.rules.required) {
        return this.labelName + "<span class='input-required'> * </span>";
      }
      return this.labelName;
    },
    computedInputData: {
      get: function() {
        return this.vmodel;
      },
      set: function() {
        console.log("dfdfdfdfd",this.file)
        let file = this.file;
        this.$emit("update:vmodel", this.file ? this.file : null);
      },
    },
  },
  methods: {
    fileHandler(e) {
      const file = e.target.files[0];
      this.file = file;
      this.imageSrc = URL.createObjectURL(file);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style>
.input-required {
  color: red !important;
}
</style>
