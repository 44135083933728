<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="forgot_welcome">
        <h1>Forgot your Password?</h1>
        <p>
          Don't Worry! we've got your back. Just follow the instructions and
          you'll be good :)
        </p>
      </div>
      <div class="form">
        <div class="reset_form">
          <h3>Reset Password</h3>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(changePassword)">
              <ValidationProvider
                rules="required"
                name="New Password"
                vid="newpassword"
              >
                <b-form-group
                  label="New Password"
                  label-for="input-1"
                  slot-scope="{ valid, errors }"
                >
                  <div class="changeForm-group errorIcon_eye">
                    <b-form-input
                      id="input-1"
                      type="text"
                      v-model="newpassword"
                      :state="errors[0] ? false : valid ? true : null"
                      placeholder="Enter your new password"
                    ></b-form-input>
                    <eye-button
                      id="input-1"
                      class="toggle-eye-icon"
                      hideOnDefault="true"
                      type="text"
                    ></eye-button>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <b-form-group label=" " label-for="">
                <div>
                  <password-strength-bar
                    @updateScore="setPasswordScore"
                    :password="newpassword"
                  ></password-strength-bar>
                </div>
              </b-form-group>

              <ValidationProvider
                rules="required|confirmed:newpassword"
                name="Confirm Password"
              >
                <b-form-group
                  label="Confirm Password"
                  label-for="input-2"
                  slot-scope="{ valid, errors }"
                >
                  <div
                    class="changeForm-group"
                    :class="errors[0] || valid ? 'errorIcon_eye' : ''"
                  >
                    <b-form-input
                      id="input-2"
                      type="text"
                      :state="errors[0] ? false : valid ? true : null"
                      v-model="confirmpassword"
                      placeholder="Repeat new password"
                    ></b-form-input>
                    <eye-button
                      id="input-2"
                      class="toggle-eye-icon"
                      hideOnDefault="true"
                      type="text"
                    ></eye-button>
                    <b-form-invalid-feedback>
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <!-- <div class="error_message" v-if="formErrorFlag == true">
                {{ formErrors["error"] }}
              </div> -->
              <button :disabled="verifyLoader">
                <b-spinner v-if="verifyLoader == true" small></b-spinner>
                {{ verifyLoader ? "Changing Password..." : "Change Password" }}
              </button>
            </b-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import helper from "@/resource/Helper";
import axios from "axios";
import router from "../../../router";

export default {
  data() {
    return {
      newpassword: "",
      confirmpassword: "",
      formErrorFlag: false,
      formErrors: [],
      verifyLoader: false,
      passwordScore: 0,
    };
  },
  methods: {
    changePassword() {
      let app = this;
      app.formErrorFlag = false;
      if (app.confirmpassword == "" || app.newpassword == "") {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Please enter password.";
        return;
      }
      if (app.confirmpassword != app.newpassword) {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Password mismatch!";
        return;
      }
      if (this.passwordScore < 60) {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Please use strong password.";
        return;
      }
      app.verifyLoader = true;
      let url =
        process.env.VUE_APP_API_URL + this.$endpoint.CHANGE_PASSWORD_API;
      axios
        .post(url, {
          password: app.newpassword,
          reset_code: this.$store.getters.OTPCode,
        })
        .then(function(response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            helper.delayRedirect("Login", 0);
          }
        })
        .catch(function(error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.verifyLoader = false;
        });
    },
    setPasswordScore(score) {
      this.passwordScore = score;
    },
  },
  created() {
    if (!this.$store.getters.OTPCode) {
      router.push({ name: "VerifyPasswordOTP" });
    }
    if (!this.$store.getters.groupEmail && !this.$store.getters.OTPCode) {
      router.push({ name: "ForgotPassword" });
    }
  },
};
</script>
<style scoped>
.changeForm-group {
  position: relative;
}
.toggleSSN {
  position: absolute;
  top: 26px;
  transform: translate(0, -50%);
  margin-top: 0 !important;
}
.password-info {
  font-size: 13px;
  font-style: italic;
}
.password_strength_text {
  font-size: 14px;
}
.green-text {
  color: #009e68;
}
.red-text {
  color: red;
}
.errorIcon_eye .form-control.is-invalid,
.errorIcon_eye .form-control.is-valid {
  background-image: none;
}
.toggle-eye-icon {
  cursor: pointer;
}
</style>
