import axios from "axios";
import router from "../router/index";
import store from "../store";
import Vue from "vue";

export default class Helper {
  static showSpinner() {
    //Show loading. This is the only way to make it appear before the app is mounted.
    let spinnerWrapper = document.createElement("div");
    spinnerWrapper.className = "spinner-wrapper";
    let spinner = document.createElement("div");
    spinner.className = "spinner";
    spinnerWrapper.appendChild(spinner);
    let spinnerStyle = document.createElement("style");
    spinnerStyle.textContent = `.spinner {
          width: 40px;
          height: 40px;
          background-color: #333;
        
          margin: 100px auto;
          -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
          animation: sk-rotateplane 1.2s infinite ease-in-out;
        }
        
        @-webkit-keyframes sk-rotateplane {
          0% { -webkit-transform: perspective(120px) }
          50% { -webkit-transform: perspective(120px) rotateY(180deg) }
          100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
        }
        
        @keyframes sk-rotateplane {
          0% { 
            transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg) 
          } 50% { 
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg) 
          } 100% { 
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
          }
        }`;
    spinnerWrapper.appendChild(spinnerStyle);
    let appDiv = document.getElementById("app");
    appDiv.appendChild(spinnerWrapper);
  }

  static hideSpinner() {
    let div = document.getElementsByClassName("spinner-wrapper");
    div[0].remove();
  }

  static simpleEditorConfig() {
    return {
      toolbar: [
        "heading",
        "|",
        "bold",
        "italic",
        "|",
        "bulletedList",
        "numberedList",
        "|",
        "|",
        "|",
        "undo",
        "redo",
      ],
      table: {
        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
      },
    }
  }

  static validateEmail(email) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  }
  static validatePassword(password) {
    return new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    ).test(password); // eslint-disable-line
  }
  static logout() {
    store.commit('setMainGroupId','')
    axios.get("/logout");
    this.clearToken();
    router.push({ name: "Login" });
  }
  static forceLogout() {
    this.clearToken();
    router.push({ name: "Login" });
  }

  static clearToken() {
    store.commit("setAuthentication", "");
    store.commit("setAccessToken", "");
    store.commit("setGroupEmail", "");
    store.commit("setGroupId", "");
    store.commit('setMainGroupId','')
  }

  static setLoginDetails(data) {
    store.commit("setAccessToken", data.access_token);
    store.commit("setGroupId", data.group.group_id);
    store.commit("setOtpCode", "");
    router.push({ name: "LoginSave" });
  }

  static redirect(url) {
    window.location = window.location.hostname + url;
  }
  static infoMessage(
    thisObj,
    variant = "success",
    message = "This is message"
  ) {
    let typeTitle;
    typeTitle = variant == "danger" ? 'error' : variant
    thisObj.$root.$toast.open({
      message: message.charAt(0).toUpperCase() + message.slice(1),
      type: typeTitle.toLowerCase(),
      duration: 2000,
      dismissible: true,
      position: "top-right",
      queue:true
    })

    // thisObj.$root.$bvToast.toast(
    //   message.charAt(0).toUpperCase() + message.slice(1),
    //   {
    //     title:
    //       variant != ""
    //         ? variant.charAt(0).toUpperCase() + variant.slice(1)
    //         : "",
    //     variant: variant == "error" ? "danger" : variant,
    //     solid: true,
    //   }
    // );
  }

  static alert(status = "success", message = "This is test") {
    switch (status) {
      case "success":
        Vue.notify({
          group: "alert",
          title: "Success!",
          type: "success",
          text: message,
        });
        break;
      case "error":
        Vue.notify({
          group: "alert",
          title: "Error!",
          type: "error",
          text: message,
        });
        break;
      case "warning":
        Vue.notify({
          group: "alert",
          title: "Warning!",
          type: "warn",
          text: message,
        });
        break;
      case "info":
        Vue.notify({
          group: "alert",
          title: "Info!",
          text: message,
        });
        break;
    }
  }

  static delayRedirect(routeName, delaySec = 0, data = []) {
    setTimeout(() => {
      router.push({
        name: routeName,
        params: {
          items: data,
        },
        meta: {
          reload: true,
        },
      });
    }, delaySec * 1000);
  }
  static validateDate(date) {
    return new Date(date) != "Invalid Date";
  }
  static downloadFile(fileUrl) {
    window.location = fileUrl;
  }

  static getStatesList() {
    return {
      AK: "Alaska",
      AL: "Alabama",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District of Columbia",
      FL: "Florida",
      GA: "Georgia",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "NEW Hampshire",
      NJ: "NEW Jersey",
      NM: "NEW Mexico",
      NY: "NEW York",
      NC: "North Carolina",
      ND: "North Dakota",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PA: "Pennsylvania",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    };
  }

  static getRelationList() {
    return {
      Spouse: "Spouse",
      "Uncle or Aunt": "Uncle or Aunt",
      "Nephew or Niece": "Nephew or Niece",
      "Brother or Sister": "Brother or Sister",
      Child: "Child",
      "Ex Spouse": "Ex Spouse",
      Mother: "Mother",
      Father: "Father",
      "Life Partner": "Life Partner",
      Trust: "Trust",
      Friend: "Friend",
      Other: "Other",
    };
  }

  static formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  static getAddressTypes() {
    return [
      { value: "business", text: "Business" },
      { value: "billing", text: "Billing" },
    ]
  }

  static dashboardGetters() {
    store.dispatch("getStatusInfo");
    store.dispatch("getGroupInfo");
    store.dispatch("getGroupAddresses");
    store.dispatch("getGroupBanks")
    // store.dispatch("getGroupInvoices")
    store.dispatch("getGroupKnowledges")
  }

  static accountTypes() {
    return [
      { value: "savings", text: "Saving" },
      { value: "checking", text: "Checking" },
    ]
  }

  static accountHolderTypes() {
    return [
      { value: "individual", text: "Individual" },
      { value: "company", text: "Company" },
    ]
  }

  static getStates() {
    let states = this.getStatesList();
    let result = []
    for (const [key, value] of Object.entries(states)) {
      result.push({ "value": key, "text": value })
    }
    return result
  }

  /**
    * convert object params to url
    */
  static objToUrlParams(obj) {
    return Object.keys(obj)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
      .join("&");
  }

  static validateCustomPassword(value) {
    /*eslint-disable*/
    var pwd = value
    var nScore = 0;
    var nLength = 0;
    var nAlphaUC = 0;
    var nAlphaLC = 0;
    var nNumber = 0;
    var nSymbol = 0;
    var nMidChar = 0;
    var nRequirements = 0;
    var nAlphasOnly = 0;
    var nNumbersOnly = 0;
    var nRepChar = 0;
    var nConsecAlphaUC = 0;
    var nConsecAlphaLC = 0;
    var nConsecNumber = 0;
    var nConsecSymbol = 0;
    var nConsecCharType = 0;
    var nSeqAlpha = 0;
    var nSeqNumber = 0;
    var nSeqChar = 0;
    var nMultLength = 4;
    var nMultAlphaUC = 2;
    var nMultAlphaLC = 2;
    var nMultNumber = 4;
    var nMultSymbol = 6;
    var nMultMidChar = 2;
    var nMultRequirements = 2;
    var nMultConsecAlphaUC = 2;
    var nMultConsecAlphaLC = 2;
    var nMultConsecNumber = 2;
    var nMultSeqAlpha = 3;
    var nMultSeqNumber = 3;
    var nTmpAlphaUC = "";
    var nTmpAlphaLC = "";
    var nTmpNumber = "";
    var nTmpSymbol = "";
    var sAlphas = "abcdefghijklmnopqrstuvwxyz";
    var sNumerics = "01234567890";
    /* var sComplexity = saStrength[index.TOOSHORT]; */
    var sColor = "#eee";
    var nMinPwdLen = 6;
    if (pwd) {
      nScore = parseInt(pwd.length * nMultLength);
      nLength = pwd.length;
      var arrPwd = pwd.replace(/\s+/g, "").split(/\s*/);
      var arrPwdLen = arrPwd.length;
      // console.log("pwdLenght",arrPwdLen)
      /* Loop through password to check for Symbol, Numeric, Lowercase and Uppercase pattern matches */
      for (var a = 0; a < arrPwdLen; a++) {
        if (arrPwd[a].match(new RegExp(/[A-Z]/g))) {
          if (nTmpAlphaUC !== "") {
            if (nTmpAlphaUC + 1 == a) {
              nConsecAlphaUC++;
              nConsecCharType++;
            }
          }
          nTmpAlphaUC = a;
          nAlphaUC++;
        } else if (arrPwd[a].match(new RegExp(/[a-z]/g))) {
          if (nTmpAlphaLC !== "") {
            if (nTmpAlphaLC + 1 == a) {
              nConsecAlphaLC++;
              nConsecCharType++;
            }
          }
          nTmpAlphaLC = a;
          nAlphaLC++;
        } else if (arrPwd[a].match(new RegExp(/[0-9]/g))) {
          if (a > 0 && a < arrPwdLen - 1) {
            nMidChar++;
          }
          if (nTmpNumber !== "") {
            if (nTmpNumber + 1 == a) {
              nConsecNumber++;
              nConsecCharType++;
            }
          }
          nTmpNumber = a;
          nNumber++;
        } else if (arrPwd[a].match(new RegExp(/[^a-zA-Z0-9_]/g))) {
          if (a > 0 && a < arrPwdLen - 1) {
            nMidChar++;
          }

          if (nTmpSymbol !== "") {
            if (nTmpSymbol + 1 == a) {
              nConsecSymbol++;
              nConsecCharType++;
            }
          }
          nTmpSymbol = a;
          nSymbol++;
        }

        /* Internal loop through password to check for repeated characters */
        for (var b = 0; b < arrPwdLen; b++) {
          if (arrPwd[a].toLowerCase() == arrPwd[b].toLowerCase() && a != b) {
            nRepChar++;
          }
        }
      }

      /* Check for sequential alpha string patterns (forward and reverse) */
      for (var s = 0; s < 23; s++) {
        var sFwd = sAlphas.substring(s, parseInt(s + 3));
        var sRev = sFwd.strReverse();
        if (
          pwd.toLowerCase().indexOf(sFwd) != -1 ||
          pwd.toLowerCase().indexOf(sRev) != -1
        ) {
          nSeqAlpha++;
          nSeqChar++;
        }
      }

      /* Check for sequential numeric string patterns (forward and reverse) */
      for (var s = 0; s < 8; s++) {
        var sFwd = sNumerics.substring(s, parseInt(s + 3));
        var sRev = sFwd.strReverse();
        if (
          pwd.toLowerCase().indexOf(sFwd) != -1 ||
          pwd.toLowerCase().indexOf(sRev) != -1
        ) {
          nSeqNumber++;
          nSeqChar++;
        }
      }

      /* Modify overall score value based on usage vs requirements */

      /* General point assignment */
      if (nAlphaUC > 0 && nAlphaUC < nLength) {
        nScore = parseInt(nScore + (nLength - nAlphaUC) * nMultAlphaUC);
      }
      if (nAlphaLC > 0 && nAlphaLC < nLength) {
        nScore = parseInt(nScore + (nLength - nAlphaLC) * nMultAlphaLC);
      }
      if (nNumber > 0 && nNumber < nLength) {
        nScore = parseInt(nScore + nNumber * nMultNumber);
      }
      if (nSymbol > 0) {
        nScore = parseInt(nScore + nSymbol * nMultSymbol);
      }
      if (nMidChar > 0) {
        nScore = parseInt(nScore + nMidChar * nMultMidChar);
      }

      /* Point deductions for poor practices */
      if ((nAlphaLC > 0 || nAlphaUC > 0) && nSymbol === 0 && nNumber === 0) {
        // Only Letters
        nAlphasOnly = nLength;
        nScore = parseInt(nScore - nAlphasOnly);
      }
      if (nAlphaLC === 0 && nAlphaUC === 0 && nSymbol === 0 && nNumber > 0) {
        // Only Numbers
        nNumbersOnly = nLength;
        nScore = parseInt(nScore - nNumbersOnly);
      }
      if (nRepChar > 0) {
        // Same character exists more than once
        nScore = parseInt(nScore - nRepChar);
      }
      if (nConsecAlphaUC > 0) {
        // Consecutive Uppercase Letters exist
        nScore = parseInt(nScore - nConsecAlphaUC * nMultConsecAlphaUC);
      }
      if (nConsecAlphaLC > 0) {
        // Consecutive Lowercase Letters exist
        nScore = parseInt(nScore - nConsecAlphaLC * nMultConsecAlphaLC);
      }
      if (nConsecNumber > 0) {
        // Consecutive Numbers exist
        nScore = parseInt(nScore - nConsecNumber * nMultConsecNumber);
      }
      if (nSeqAlpha > 0) {
        // Sequential alpha strings exist (3 characters or more)
        nScore = parseInt(nScore - nSeqAlpha * nMultSeqAlpha);
      }
      if (nSeqNumber > 0) {
        // Sequential numeric strings exist (3 characters or more)
        nScore = parseInt(nScore - nSeqNumber * nMultSeqNumber);
      }

      /* Determine if mandatory requirements have been met */
      if (nLength >= nMinPwdLen) {
        nRequirements = 0;
        var nMinReqChars = 4;
        if (nAlphaUC >= 1) {
          nRequirements++;
        }
        if (nAlphaLC >= 1) {
          nRequirements++;
        }
        if (nNumber >= 1) {
          nRequirements++;
        }
        if (nSymbol >= 1) {
          nRequirements++;
        }
        // console.log("nScore",nScore)
     
        if (nRequirements >= nMinReqChars) {
          nScore = parseInt(nScore + nRequirements * nMultRequirements);
        }else{
          if(nScore > 64){
            nScore = nScore -(nRequirements*10)
          }
        }

      }
      //again determining the password strend if upperCase,lowerCase , minLength ,Symbol and Number
      return nScore>=60;
    //document.querySelector('.score').innerText = nScore;
    }
  }
}

