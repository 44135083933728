import { render, staticRenderFns } from "./DisplaySetting.vue?vue&type=template&id=4adffe0d&scoped=true&"
import script from "./DisplaySetting.vue?vue&type=script&lang=js&"
export * from "./DisplaySetting.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/group_dashboard/display_setting.css?vue&type=style&index=0&id=4adffe0d&scoped=true&lang=css&"
import style1 from "./DisplaySetting.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4adffe0d",
  null
  
)

export default component.exports