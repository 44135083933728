<template>
  <b-modal
    v-if="data"
    id="received-message-detail"
    centered
    size="lg"
    scrollable
    hide-footer
    :title="title"
  >

    <div class="message_description">
      <div class="date">
        {{ formattedDetailDate }}
      </div>
      <h5>{{ data.subject }}</h5>
      <div class="message_content">
        <p v-html="data.message" />
        <br />
        <div v-if="data.attachments && data.attachments.length > 0">
          <h6>Attachments:</h6>
          <ul style="list-style:none">
            <li v-for="file in data.attachments" :key="file.id">
              <a :href="file.file_url" target="__blank" download>
                <i class="fa fa-file"></i>
                {{ file.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import moment from "moment"
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    title() {
      return `From : ${this.data.sender_name} - ${this.data.from}`;
    },
     formattedDetailDate() {
      let date = this.data.date_created;
      if (moment(date, "YYYY/MM/DD HH:mm:ss", true).isValid()) {
        return moment(date, "YYYY/MM/DD HH:mm:ss", true).format("gg MMM , ddd");
      }
      return "N/A";
    },
  },
};
</script>
<style src="@/assets/css/group_dashboard/all_messages.css" scoped></style>
