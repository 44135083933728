import { render, staticRenderFns } from "./ReceivedMessageDetail.vue?vue&type=template&id=5eda1af5&scoped=true&"
import script from "./ReceivedMessageDetail.vue?vue&type=script&lang=js&"
export * from "./ReceivedMessageDetail.vue?vue&type=script&lang=js&"
import style0 from "@/assets/css/group_dashboard/all_messages.css?vue&type=style&index=0&id=5eda1af5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eda1af5",
  null
  
)

export default component.exports