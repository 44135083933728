<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="wrapper_account_information">
        <breadcrumb :items="breadcrumbItems" />
        <h3>Account Information</h3>
        <div class="account_info_tabs">
          <b-tabs>
            <b-tab
              title="General Information"
              @click="selectTab('General Information')"
            >
              <general-information />
            </b-tab>
            <b-tab
              title="Billing Information"
              @click="selectTab('Billing Information')"
            >
              <billing-information />
            </b-tab>
            <b-tab
              title="Support Information"
              @click="selectTab('Support Information')"
            >
              <support-information />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GeneralInformation from "@/pages/AccountInformation/GeneralInformation";
import SupportInformation from "@/pages/AccountInformation/SupportInformation";
import BillingInformation from "@/pages/AccountInformation/BillingInformation";
export default {
  components: { GeneralInformation, SupportInformation, BillingInformation },
  data: () => {
    return {
      tabTitle: "General Information",
      breadcrumbItems: [],
    };
  },
  methods: {
    selectTab(tabTitle) {
      this.tabTitle = tabTitle;
      this.getBreadCrumbItems(tabTitle);
    },
    getBreadCrumbItems(tabTitle) {
      this.breadcrumbItems = [
        { title: "Account Information", active: true },
        { title: tabTitle, active: true },
      ];
    },
  },
  created() {
    this.getBreadCrumbItems(this.tabTitle);
  },
};
</script>
<style src="@/assets/css/group_dashboard/account_information.css"></style>
