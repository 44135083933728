export const validateSsnUrl = process.env.VUE_APP_VALIDATE_SSN
export const validatePhoneNumberUrl = process.env.VUE_APP_VALIDATE_PHONE_NUMBER
export const validateAddressUrl = process.env.VUE_APP_VALIDATE_ADDRESS
export const validateRoutingNumberUrl = process.env.VUE_APP_VALIDATE_ROUTING_NUMBER
export const apiUrl =  process.env.VUE_APP_API_URL  
export const knowledgeBaseApiUrl = process.env.VUE_APP_API_KNOWLEDGE_BASE
export const knowledgeBaseDownloadUrl = process.env.VUE_APP_KNOWLEDGE_BASE_DOWNLOAD_URL
export const validateAddressUsps= process.env.VUE_APP_VALIDATE_ADDRESS_USPS
export const benefitStoreUrl= process.env.VUE_APP_BENEFIT_STORE_URL
export const invoiceApiUrl = process.env.VUE_APP_API_INVOICE_URL
export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL
export const ANDROID_APP_URL = process.env.VUE_APP_GROUP_ANDROID_APP_URL
export const IOS_APP_URL  = process.env.VUE_APP_GROUP_IOS_APP_URL
export const apiBaseUrlV2  = process.env.VUE_APP_API_URL_V2
