<template>
  <div class="pull-right" v-if="items.total_records > items.per_page">
    <b-pagination
      v-if="items.total_records > 0"
      v-model="items.current_page"
      :total-rows="items.total_records"
      :per-page="items.per_page"
      class="mt-4"
      prev-text="Prev"
      next-text="Next"
      first-text="First"
      last-text="Last"
      @change="handlePageChange"
      
    >
      <!-- <template #first-text>
        <a href="javascript:void(0);" @click="goToUrl(items.first)">
          First
        </a>
      </template>
      <template #prev-text>
        <a href="javascript:void(0);" @click="goToUrl(items.previous)">
          Prev
        </a>
      </template>
      <template #next-text>
        <a href="javascript:void(0);" @click="goToUrl(items.next)">
          Next
        </a>
      </template>
      <template #last-text>
        <a href="javascript:void(0);" @click="goToUrl(items.last)">
          Last
        </a>
      </template>
      <template #page="{ page, active }">
        <b v-if="active">
          {{ page }}
        </b>
        <a
          v-else
          href="javascript:void(0);"
          @click="goToUrl(`${items.path}?page_size=${items.per_page}&page=${page}`)"
        >
          {{ page }}
        </a>
      </template> -->
    </b-pagination>
  </div>
</template>

<script>
import Helper from "@/resource/Helper";
import axios from "axios";
export default {
  name: "Pagination",
  props: {
    rows: {
      required: true,
    },
    filters: {
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      items: [],
      filterParams: {},
    };
  },
  methods: {
    handlePageChange(page) {
      let url;
      url = `${this.items.path}?page_size=${this.items.per_page}&page=${page}`;
      this.getData(url);
    },
    // goToUrl(url) {
    //   if (this.filterQuery) {
    //     url = url + `&${this.filterQuery}`;
    //   }
    //   this.getData(url);
    // },
    async getData(url) {
      if (this.filterQuery) {
        url = `${url}&${this.filterQuery}`;
      }
      let loader = this.$loading.show();
      await axios
        .get(url)
        .then((response) => {
          let data = response.data;
          this.$emit("update:rows", data);
        })
        .catch((error) => {
          console.log("error");
        })
        .finally(() => {
          loader.hide();
        });
    },
  },

  computed: {
    filterQuery() {
      return Helper.objToUrlParams(this.filterParams);
    },
  },
  created() {
    this.items = this.rows;
    this.filterParams = this.filters;
  },
  watch: {
    rows(value) {
      this.items = value;
    },
    filters(value) {
      this.filterParams = value;
    },
  },
};
</script>

<style>
.pagination{
  display: block;
}
.pagination .page-item{
  display: inline-block;
}
</style>
