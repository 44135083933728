<template>
  <!-- upload files modal -->
  <b-modal id="upload_file" class="uload-file-popup" centered hide-footer hide-header size="lg">
    <close-modal-icon modalId="upload_file"/>
    <div class="title">
      <h2>Upload File</h2>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form
        @submit.stop.prevent="handleSubmit(submit)"
        class="add-file-form"
        ref="addFileContainer"
      >
        <input-text
          :rules="{ required: true }"
          labelFor="file_title"
          labelName="File Title"
          inputName="file_title"
          :vmodel.sync="form.file_title"
          formGroupClass="col-md-10 offset-md-1"
        />

        <input-select
          :rules="{ required: true }"
          labelFor="doc_type"
          labelName="File Type"
          inputName="doc_type"
          :vmodel.sync="form.doc_type"
          :options="fileTypes"
          defaultOption="Select Type"
          formGroupClass="col-md-10 offset-md-1"
        />

        <input-file
          :rules="{ required: true, mimes: mimeTypes }"
          labelFor="select_file"
          labelName="Select File (PDF,Doc,xls,csv,txt) "
          inputName="select_file"
          :vmodel.sync="form.file"
          :acceptMimeTypes="mimeTypes"
          formGroupClass="col-md-10 offset-md-1"
        />
        <input-textarea
          :rules="{ required: true }"
          labelFor="file"
          labelName="File Notes "
          inputName="file"
          :vmodel.sync="form.doc_notes"
          formGroupClass="col-md-10 offset-md-1"
        />

        <div class="add_file col-md-10 offset-md-1">
          <button>Add File</button>
        </div>
      </b-form>
    </ValidationObserver>
  </b-modal>
  <!-- upload files modal -->
</template>

<script>
import Api from "@/resource/Api";
import CloseModalIcon from '../../components/ui/CloseModalIcon.vue';
export default {
  components: { CloseModalIcon },
  data: () => {
    return {
      fileTypes: [
        { value: "My Files", text: "My Files" },
        { value: "Forms", text: "Forms" },
        { value: "Enrollment Form", text: "Enrollment Form" },
        { value: "Census File", text: "Census File" },
        { value: "Member Docs", text: "Member Docs" },
        { value: "Rep Doc", text: "Rep Doc" },
        { value: "Commission Statement", text: "Commission Statement" },
      ],
      form: {
        file_title: "",
        doc_notes: "",
        file: null,
        doc_type: null,
      },
      mimeTypes: [
        "application/doc",
        "application/ms-doc",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "application/vnd.ms-powerpoint",
        "application/mspowerpoint",
        "application/powerpoint",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        "application/excel",
        "application/x-msexcel",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
        "application/pdf",
        "text/csv",
        "image/jpeg",
        "image/jpg",
        "image/png",
      ],
    };
  },
  methods: {
    reset() {
      this.form = {
          file_title: "",
        doc_notes: "",
        file: null,
        doc_type: null,
      };
      this.$refs.observer.reset();
    },
    submit() {
      let url = "add-doc";
      let loader = this.$loading.show({
        container: this.$refs.addFileContainer,
      });
      let formData = new FormData();
      formData.append("file", this.form.file);
      for (const [key, value] of Object.entries(this.form)) {
        if (value) {
          formData.append(key, value);
        }
      }
      Api.post(url, formData)
        .then((res) => {
          let response = res.data.message;
          this.$notify(response);
          setTimeout(() => {
            this.reset();
            this.$bvModal.hide("upload_file");
            this.$emit("onFileAdded");
          }, 2000);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally(() => {
          setTimeout(() => {
            loader.hide();
          }, 2000);
        });
    },
  },
};
</script>
