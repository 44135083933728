<template>
  <div class="rep_and_todo_container">
    <div class="rep_and_todo">
      <div class="rep_information">
        <h2>Rep Information</h2>
        <div class="rep_info_detail" > 
          <div class="rep_profile" >
            <div class="rep_profileDP">
              <img :src="repImage" :alt="repInfo.rep_name" />
            </div>
            <h2>{{ repInfo.rep_name }}</h2>
          </div>
          <div class="rep_contact">
            <span class="email">
              <a :href="`mailto:${repInfo.rep_email}`">
                <img src="@/assets/images/email.svg" />
                {{ repInfo.rep_email }}
              </a>
            </span>
            <span class="phone">
              <a :href="`tel:${repInfo.rep_phone2}`">
                <img src="@/assets/images/bxs-phone.svg" />
                {{ phoneNumber }}
              </a>
            </span>
          </div>
        </div>
      </div>
      <div class="todo_list">
        <h2>To Do List</h2>
        <p class="sub_title">Major List 1</p>
        <template v-if="todos.length > 0">
          <ul>
            <li v-for="(item, index) in todos" :key="index">
              <span class="indication">
                <b-form-checkbox
                  class="todoCheckbox"
                  :id="`todo-checkbox-${item.id}`"
                  :checked="item.status == 'Completed' ? true : false"
                  @change="toggleTodo(item)"
                >
                  <span v-if="item.status == 'Completed'">
                    <s>{{ item.title }}</s>
                  </span>
                  <span v-else>
                    {{ item.title }}
                  </span>
                </b-form-checkbox>
              </span>
            </li>
          </ul>
        </template>
        <template v-else>
          <ul>
            <no-content text="No todo list found." />
          </ul>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import Helper from "@/resource/Helper";
import userGroupImage from "@/assets/images/default_profile.jpg";
export default {
  data: function() {
    return {
      todos: [],
      userGroupImage,
    };
  },
  computed: {
    ...mapGetters({
      repInfo: "repInfo",
    }),
    repImage() {
      let repInfo = this.repInfo;
      return repInfo
        ? repInfo.rep_image !== ""
          ? repInfo.rep_image
          : userGroupImage
        : userGroupImage;
    },
    phoneNumber() {
      return Helper.formatPhoneNumber(this.repInfo.rep_phone2);
    },
  },
  methods: {
    toggleTodo(item) {
      const url = "update-todo-status";
      this.$store.commit("setDialogClass", "group-app-confirm-dialog");
      let textStatus = item.status == "Completed" ? "Incomplete" : "Complete";
      let messageStatus = textStatus.toLowerCase();
      let val = item.status == "Completed" ? true : false;
      this.$confirm({
        title: `${textStatus} Todo`,
        message: `Do you want to ${messageStatus} this ${item.title}?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.put(url, {
              id: item.id,
              status: val ? "incomplete" : "completed",
            })
              .then((res) => {
                let response = res.data.message;
                this.$notify(response);
                this.getTodos();
              })
              .catch((err) => {
                document.getElementById(
                  `todo-checkbox-${item.id}`
                ).checked = val;
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          } else {
            document.getElementById(`todo-checkbox-${item.id}`).checked = val;
          }
        },
      });
    },
    async getTodos() {
      const url = "get-todo-list";
      await Api.get(url)
        .then((res) => {
          this.todos = res.data.data;
        })
        .catch((err) => {
          console.log("Error");
        });
    },
  },
  mounted() {
    this.getTodos();
  },
};
</script>
<style src="@/assets/css/group_dashboard/rep_information.css" scoped></style>
<style scoped>
.todoCheckbox .custom-control-label::before {
  top: 2.5px !important;
}
</style>
