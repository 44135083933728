<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="all_invoices">
        <breadcrumb
          :items="breadcrumbItems"
          class="invoice-detail-breadcrumb"
        />
        <div class="title">
          <h2>Invoices</h2>
          <div class="date_paynow">
            <button class="pay_now" v-b-modal.paystand_dialog v-if="canPay">
              Pay Now
            </button>
          </div>
        </div>
        <invoice-payment :invoice="invoice" @onPaymentSuccess="updateInvoice" />
        <invoice-list :rows="invoices" />
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import moment from "moment";
import InvoiceList from "@/pages/Invoice/InvoiceList.vue";
import InvoicePayment from "@/pages/Invoice/InvoicePayment.vue";
export default {
  components: { InvoiceList, InvoicePayment },
  data() {
    return {
      invoices: [],
      invoice: {},
      canPay: true,
      breadcrumbItems: [{ title: "Invoices", active: true }],
    };
  },
  methods: {
    updateInvoice() {
      this.$store.commit("setInvoiceIds", []);
      this.$store.commit("setUnPaidInvoices", []);
      this.getInvoice();
      this.getInvoices();
    },
    getInvoice() {
      // const gId = this.$store.getters.groupInfo.gid
      const selectedDate = this.$route.params.date;
      const endOfMonthDate = moment(selectedDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      const url = `render-invoice/${endOfMonthDate}`;
      Api.get(url)
        .then((res) => {
          this.invoice = res.data.data;
        })
        .catch((err) => {
          console.log("error");
        });
    },
    getInvoices() {
      const date = this.$route.params.date;
      let endOfDate;
      endOfDate = moment(date)
        .endOf("month")
        .format("YYYY-MM-DD");
      const url = `render-invoice-list/${endOfDate}`;
      let loader = this.$loading.show();
      Api.get(url)
        .then((res) => {
          this.invoices = res.data.data;
          let unPaidInvoices = res.data.data.filter((item) => {
            if (
              item.invoice_payment_status == "UNPAID" ||
              item.invoice_payment_status == "PARTIAL"
            ) {
              return item;
            }
          });
          if (unPaidInvoices.length > 0) {
            this.$store.commit("setUnPaidInvoices", unPaidInvoices);
          } else {
            this.canPay = false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.$store.commit("setInvoiceIds", []);
    this.$store.commit("setUnPaidInvoices", []);
    this.getInvoice();
    this.getInvoices();
  },
};
</script>
<style src="@/assets/css/group_dashboard/all_invoices.css"></style>
