<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="forgot_welcome">
        <h1>Verify your otp?</h1>
        <p>
          Don't Worry! we've got your back. Just follow the instructions and
          you'll be good :)
        </p>
      </div>
      <div class="form">
        <div class="top-button">
          <router-link :to="{ name: 'Login' }"
            ><button class="sign_up_inactive">Login</button></router-link
          >
        </div>
        <div class="reset_form">
          <h3>Verify OTP</h3>

          <b-form-group label="Enter OTP Code" label-for="input-2">
            <b-input-group id="input-2">
              <div id="otp">
                <b-form-input
                  :class="errorClass"
                  type="text"
                  maxlength="1"
                  id="otp_1"
                  required
                  @keyup="otpChange($event, 1)"
                  v-model="otp.one"
                  placeholder="-"
                ></b-form-input>
                <b-form-input
                  :class="errorClass"
                  type="text"
                  maxlength="1"
                  id="otp_2"
                  required
                  @keyup="otpChange($event, 2)"
                  v-model="otp.two"
                  placeholder="-"
                ></b-form-input>
                <b-form-input
                  :class="errorClass"
                  type="text"
                  maxlength="1"
                  id="otp_3"
                  required
                  @keyup="otpChange($event, 3)"
                  v-model="otp.three"
                  placeholder="-"
                ></b-form-input>
                <b-form-input
                  :class="errorClass"
                  type="text"
                  maxlength="1"
                  id="otp_4"
                  required
                  @keyup="otpChange($event, 4)"
                  v-model="otp.four"
                  placeholder="-"
                ></b-form-input>
              </div>
            </b-input-group>
          </b-form-group>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
             <OTPTimer title="Not received? Click to resend" @sendOTP=sendOtpCode :expirySecond=60 />

          <button @click="verifyToken" :disabled="verifyLoader">
            <b-spinner v-if="verifyLoader == true" small></b-spinner>
            Verify
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import helper from "@/resource/Helper";
import { mapGetters } from "vuex";
import store from "@/store";
import OTPTimer from '@/components/common/OTPTimer';

export default {
  name: "VerifyToken",
  components: {
    OTPTimer
  },
  data() {
    return {
      otp: {
        one: "",
        two: "",
        three: "",
        four: "",
      },
      formErrorFlag: false,
      formErrors: [],
      verifyLoader: false,
      errorClass: "",
      //deviceId: this.existingDeviceId? this.existingDeviceId : uuid.v1(),
    };
  },
  computed: {
    ...mapGetters({
      deviceId: "deviceId",
      email: "groupEmail",
    }),
  },
  methods: {
    verifyToken() {
      let app = this;
      app.errorClass = "";
      app.formErrorFlag = false;
      let code = parseInt(
        app.otp.one + app.otp.two + app.otp.three + app.otp.four
      );
      if (code == "NaN" || code.toString().length < 4) {
        app.formErrorFlag = true;
        app.formErrors["error"] = "Enter valid code.";
        app.errorClass = "highlight_otp";
        return;
      }
      app.verifyLoader = true;

      let url = process.env.VUE_APP_API_URL + this.$endpoint.VERIFY_LOGIN_OTP;
      axios
        .post(url, {
          email: app.email,
          data: "both",
          user_type: "G",
          device_id: app.deviceId,
          verification_code: code,
        })
        .then(function(response) {
          /*eslint-disable*/
          if (response.data.status == "success") {
            store.commit("setMainGroupId", response.data.group.group_id);
            helper.setLoginDetails(response.data);
          }
        })
        .catch(function(error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
              helper.infoMessage(
              app,
              error.response.data.status,
              error.response.data.message
            );
          }
          app.verifyLoader = false;
        });
    },
    otpChange(event, index) {
      let app = this;
      if (event.code != "") {
        if (isNaN(parseInt(event.key))) {
          event.target.value = "";
          app.errorClass = "highlight_otp";
          return;
        } else {
          event.target.value = event.key;
          app.errorClass = "";
        }
      } else {
        /* this section for mobile browser */
        if (isNaN(parseInt(document.querySelector("#otp_" + index).value))) {
          document.querySelector("#otp_" + index).value = "";
          app.errorClass = "highlight_otp";
          return;
        } else {
          app.errorClass = "";
        }
      }

      let newIndex = index == 1 ? 1 : index - 1;
      if (document.querySelector("#otp_" + newIndex).value != "") {
        let nextIndex = parseInt(index) + +1;
        if (nextIndex < 5) {
          document.querySelector("#otp_" + nextIndex).focus();
        } else {
          document.querySelector("#otp_1").focus();
        }
      } else {
        let prevIndex = +parseInt(index) - 1;
        document.querySelector("#otp_" + index).value = "";
        document.querySelector("#otp_" + prevIndex).focus();
      }
    },
    sendOtpCode() {
      let app = this;
      app.verifyLoader = true;
      let url = process.env.VUE_APP_API_URL + this.$endpoint.SEND_LOGIN_OTP;
      axios
        .post(url, {
          email: this.$store.getters.groupEmail,
          data: "both",
          device_id: app.deviceId,
        })
        .then(function(response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
          }
          app.verifyLoader = false;
        })
        .catch(function(error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.verifyLoader = false;
        });
    },
  },
  mounted() {
    document
      .querySelector("#otp_1")
      .addEventListener("onchange.native", function() {});
  },
};
</script>

<style scoped>
@import "../../../assets/css/group_dashboard/forgot_password.css";
.highlight_otp {
  border: 2px solid red !important;
}
.resend_otp {
  cursor: pointer;
  color: "blue";
  text-decoration: underline;
}

.error_message {
  color: red !important;
  margin-top: 4px;
}
</style>
