<template>
  <b-modal id="send_member_email" centered hide-footer hide-header size="lg">
    <img
      @click="$bvModal.hide('send_member_email')"
      class="close_cross"
      src="../../assets/images/cross.svg"
      alt="cross"
    />
    <div class="title">
      <h2>Send Email</h2>
      <span>Send Email To Member</span>
    </div>
    <div id="from_info">
      From:
      <span
        ><strong>{{ groupInfo.group_name }}</strong>
      </span>
      <span>{{ groupAddress }}</span>
      <span>{{ groupInfo.group_email }}</span>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(sendEmail)">
        <input-text
          :rules="{ required: true, email_verify: true }"
          labelFor="email"
          labelName="Email"
          inputName="email"
          :vmodel.sync="form.email"
        />
        <input-text
          :rules="{ required: true }"
          labelFor="subject"
          labelName="Subject"
          inputName="subject"
          :vmodel.sync="form.subject"
        />
        <b-form-group class="ckeditor-textarea">
          <validation-provider
            name="message"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <label id="label-message" for="label-message-number">
              Message<span style="color:red">*</span>
            </label>
            <ckeditor
              class="is-invalid"
              :state="getValidationState(validationContext)"
              :editor="editor"
              v-model="form.message"
              :config="editorConfig"
            ></ckeditor>
            <b-form-invalid-feedback
              id="input-feedback message-feedback"
              class="message-feedback"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

        <b-form-group class="submit">
          <button :disabled="formDisabled">
            {{ submit ? "Processing..." : "Send" }}
          </button>
        </b-form-group>
      </b-form>
    </ValidationObserver>
  </b-modal>

  <!-- Personal Address Update Ends -->
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  data() {
    return {
      form: this.initialData(),
      submit: false,
      formDisabled: false,
      validPhone: false,
      validEmail: false,
      validPassword: false,
      groupAddress: "",
      editor: ClassicEditor,
      editorConfig: {
        height: "500px",
        rows: 8
      },
    };
  },
  props: ["memberDetails"],
  computed: {
    ...mapGetters({
      groupInfo: "groupInfo",
      groupAddresses: "groupAddresses",
    }),
  },
  mounted() {
    this.groupAddresses.map((data) => {
      if (data.type == "business" && data.is_primary) {
        this.groupAddress = [data.address1, data.city, data.city].join(",");
      }
    });
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    sendEmail() {
      let url = "send-email";
      this.submit = true;
      this.formDisabled = true;
      let loader = this.$loading.show({
        container: this.$refs.addBankInfoContainer,
      });
      Api.post(url, this.form)
        .then((res) => {
          let response = res.data.message;
          this.$notify(response);
          setTimeout(() => {
            this.form = this.initialData();
            this.$bvModal.hide("send_member_email");
          }, 2000);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally(() => {
          setTimeout(() => {
            loader.hide();
            this.submit = false;
          }, 2000);
        });
    },
    initialData() {
      return {
        user_id: this.memberDetails?.user_id,
        email: this.memberDetails?.email,
        subject: "",
        message: "",
      };
    },
    formCheck() {
      this.formDisabled = !(this.validPassword && this.validPhone);
    },
  },
  watch: {
    memberDetails: function(val) {
      this.form = this.initialData();
    },
  },
};
</script>
<style scoped>
/* add group user  */
#send_member_email .title {
  display: block;
  text-align: center;
  margin-top: 30px;
}
#send_member_email .title h2 {
  font-size: 24px;
  color: #2c3f58;
  margin-bottom: 6px !important;
}
#send_member_email .title span {
  font-size: 14px;
  color: #778699;
}
#send_member_email form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 25px;
}
#send_member_email .form-group {
  width: 50%;
  padding: 5px 15px;
  font-size: 14px;
  color: #254050;
}
#send_member_email input {
  font-size: 14px;
  color: #778699;
  /* border:1px solid #03C3EC; */
  border-radius: 0.25 rem;
}

#send_member_email {
  position: relative;
}
.close_cross {
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
  width: 55px;
}
#send_member_email .submit {
  width: 55%;
  margin: 20px auto 30px auto;
}
#send_member_email .submit button {
  padding: 10px 0px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(#00ccff 0%, #1597b8 49%, #0567bc 100%);
  color: #fff;
}
.user_action_activate {
  margin-left: 1%;
  background: #33c733 !important;
}

.toggleSSN {
  position: relative !important;
  top: -25px;
  left: 103%;
}

/* add group user  */

#from_info {
  width: 50%;
  padding: 5px 22px;
  font-size: 14px;
  color: #254050;
}
#from_info span {
  display: block;
}
.ckeditor-textarea {
  width: 100% !important;
}
@media(max-width: 767px){
  #send_member_email .form-group{
    width: 100%;
  }
  #send_member_email{
    padding-left: 0px !important;
  }
}
</style>
<style>
.ck-blurred { height: 100px !important}
.ck-focused { height: 100px !important}
</style>
