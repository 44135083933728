<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="welcome">
        <h1>Welcome</h1>
        <p>Experience the ease of access in one place.</p>
      </div>

      <div class="form">
        <div class="top-button">
            <button class="sign_up_inactive" @click="routeToRegistration">Sign Up</button>
        </div>
        <div class="login_form">
          <h3>Log In</h3>

          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <b-form
              @submit.stop.prevent="handleSubmit(login)"
              class="login-form"
              ref="loginContainer"
            >
              <b-form-group>
                <validation-provider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
                >
                  <label id="label-login-email" for="label-login-email">
                    Email
                  </label>
                  <div class="changeForm-group errorIcon_eye">
                    <b-form-input
                      id="login-email"
                      type="email"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-feedback"
                      v-model="input.username"
                      placeholder="Enter your email"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="input-feedback login-email-feedback"
                      class="login-email-feedback"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </validation-provider>
              </b-form-group>

              <b-form-group>
                <validation-provider
                  name="Password"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <label id="label-login-password" for="label-login-password">
                    Password
                  </label>
                  <div class="changeForm-group errorIcon_eye">
                    <b-form-input
                      id="input-2"
                      type="password"
                      v-model="input.password"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-feedback"
                      placeholder="Enter your password"
                    ></b-form-input>
                    <eye-button
                      id="input-2"
                      class="toggle-eye-icon"
                      type="text"
                    ></eye-button>
                    <b-form-invalid-feedback
                      id="input-feedback login-password-feedback"
                      class="login-password-feedback"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </validation-provider>
              </b-form-group>

              <div class="error_message" v-if="formErrorFlag == true">
                {{ formErrors["error"] }}
              </div>
              <a class="">
                <button type="submit" :disabled="loginLoader">
                  <b-spinner v-if="loginLoader == true" small></b-spinner>
                  Log In
                </button>
              </a>
            </b-form>
          </ValidationObserver>
          <router-link :to="{ name: 'ForgotPassword' }">
            <p>Forgot Password?</p></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import axios from "axios";
import helper from "@/resource/Helper";
import { uuid } from "vue-uuid";
import store from "@/store";
export default {
  name: "Login",
  data() {
    return {
      input: {
        username: "",
        password: "",
      },
      loginLoader: false,
      formErrorFlag: false,
      formErrors: [],
      deviceID: "",
    };
  },
  mounted() {
    let uniqueDeviceId = this.$store.getters.deviceId
      ? this.$store.getters.deviceId
      : uuid.v1();
    this.deviceID = uniqueDeviceId;
    this.$store.commit("setDeviceId", uniqueDeviceId);
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    login() {
      let app = this;
      this.formErrors = [];
      let inputError = false;
      app.$store.commit("setAuthentication", false);

      this.loginLoader = true;
      app.$store.commit("setGroupEmail", this.input.username);
      let url = process.env.VUE_APP_API_URL + this.$endpoint.LOGIN;
      axios
        .post(url, {
          email: this.input.username,
          password: this.input.password,
          device_id: this.deviceID,
        })
        .then(function (response) {
          if (response.data.status == "success") {
            debugger;
            store.commit("setAuthentication", true);
            store.commit("setMainGroupId", response.data.group.group_id);
            helper.setLoginDetails(response.data);
            helper.dashboardGetters();
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            if (error.response.data.message == "Your device is not verified") {
              helper.infoMessage(
                app,
                error.response.data.status,
                error.response.data.message
              );
              app.sendOtpCode();
            } else {
              app.formErrorFlag = true;
              app.formErrors["error"] = error.response.data.message;
              app.loginLoader = false;

              setTimeout(() => {
                app.formErrorFlag = false;
                app.formErrors = [];
              }, 4000);
            }
          }
        });
    },

    sendOtpCode() {
      let app = this;
      let url = process.env.VUE_APP_API_URL + this.$endpoint.SEND_LOGIN_OTP;
      axios
        .post(url, {
          email: this.input.username,
          data: "both",
          device_id: app.deviceID,
        })
        .then(function (response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            helper.delayRedirect("LoginOTP", 0);
          }
        })
        .catch(function (error) {
          if (error.response.data.status == "error") {
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.loginLoader = false;
        });
    },
    routeToRegistration () {
      let app = this
      app.$router.push('/registration')
      this.$store.commit('setGroupCensusId','')
      this.form['group_census_id'] = ''
    }
  },
};
</script>
<style scoped>
@import "../../../assets/css/group_dashboard/login.css";

.error_message {
  display: block;
}
.changeForm-group {
  position: relative;
}

.toggleSSN {
  position: absolute;
  top: 25px;
}
.toggle-eye-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  color: #03c3ec;
  cursor: pointer;
}
</style>
