<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="forgot_welcome">
        <h1>Forgot your Password?</h1>
        <p>
          Don't Worry! we've got your back. Just follow the instructions and
          you'll be good :)
        </p>
      </div>
      <div class="form">
        <div class="top-button">
          <router-link :to="{ name: 'Login' }"
            ><button class="sign_up_inactive">Login</button></router-link
          >
        </div>
        <div class="reset_form">
          <h3>Reset Password</h3>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(sendForgotPassword)">
              <b-form-group>
                <validation-provider
                  name="Email"
                  :rules="{ required: true, email: true }"
                  v-slot="validationContext"
                >
                  <label id="label-login-email" for="label-email">
                    Email
                  </label>
                  <div class="changeForm-group">
                    <b-form-input
                      id="email"
                      type="email"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-feedback"
                      v-model="email"
                      placeholder="Enter your email"
                    ></b-form-input>
                    <b-form-invalid-feedback
                      id="input-feedback email-feedback"
                      class="email-feedback"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </div>
                </validation-provider>
              </b-form-group>

              <div class="error_message" v-if="formErrorFlag == true">
                {{ formErrors["error"] }}
              </div>
              <button type="submit">
                <b-spinner v-if="verifyLoader == true" small></b-spinner>
                Request OTP Code
              </button>
            </b-form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import helper from "../../../resource/Helper";
import axios from "axios";

export default {
  data() {
    return {
      email: "",
      formErrorFlag: false,
      formErrors: [],
      verifyLoader: false,
    };
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    sendForgotPassword() {
      let app = this;
      this.formErrorFlag = false;
      if (this.email == "" || helper.validateEmail(this.email) == false) {
        this.formErrors["error"] = "Please enter valid email address.";
        this.formErrorFlag = true;
        return;
      }
      app.verifyLoader = true;
      let url =
        process.env.VUE_APP_API_URL + this.$endpoint.SEND_FORGOT_PASSWORD_OTP;
      axios
        .post(url, {
          data: app.email,
          option: "email",
        })
        .then(function(response) {
          if (response.data.status == "success") {
            helper.infoMessage(
              app,
              response.data.status,
              response.data.message
            );
            app.$store.commit("setGroupEmail", app.email);
            helper.delayRedirect("VerifyPasswordOTP", 1);
            app.verifyLoader = false;
          }
        })
        .catch(function(error) {
          if (error.response.data.status == "error") {
             helper.infoMessage(
              app,
              error.response.data.status,
              error.response.data.message
            );
            app.formErrorFlag = true;
            app.formErrors["error"] = error.response.data.message;
          }
          app.verifyLoader = false;
          setTimeout(() => {
            app.formErrorFlag = false;
            app.formErrors = [];
          }, 4000);
        });
    },
  },
};
</script>

<style>
@import "../../../assets/css/group_dashboard/forgot_password.css";
</style>
