<template>
  <div class="compose_message">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <b-form
        @submit.stop.prevent="handleSubmit(composeMessage)"
        class="compose-message"
        ref="composeMessageContainer"
      >
        <!--<input-select
          :rules="{ required: true }"
          labelFor="member"
          labelName="Member"
          inputName="member"
          :vmodel.sync="form.member"
          :options="members"
          defaultOption="Select Member"
        /> -->
        <b-form-group>
          <validation-provider
            name="member"
            :rules="{ required: true }"
            v-slot="validationContext"
          >
            <label id="Member" for="member">
              Member<span style="color: red">*</span>
            </label>

            <multiselect
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              v-model="form.members"
              :options="members.map((type) => type.id)"
              :custom-label="(opt) => members.find((x) => x.id == opt).name"
              :track-by="members.id"
              label="name"
            >
              <template v-if="values.length > 2" slot="selection" slot-scope="{ values }">
                  <span class="multiselect__single" >
                    {{
                      values.length + " Users Selected"
                    }}
                    
                  </span>
                </template>
            </multiselect>

            <b-form-invalid-feedback
              :state="getValidationState(validationContext)"
              id="input-feedback"
            >
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>

   
        <b-form-group>
      
       <label id="Files" for="files">
              Choose File
            </label>
        <b-form-file
          browse-text="Choose File"
          v-model="form.filesmultiple"
          placeholder="Choose File"
          multiple="multiple"
          accept="png,jpg,xlx,xlsx,pdf,txt,csv,jpeg"
        >
        </b-form-file>
  </b-form-group>

        <input-text
          :rules="{ required: true }"
          labelFor="subject"
          labelName="Subject"
          inputName="subject"
          :vmodel.sync="form.subject"
        />
        
         <input-textarea
          :rules="{ required: true }"
          labelFor="message"
          labelName="Message "
          inputName="message"
          :vmodel.sync="form.message"
          formGroupClass="col-md-12"
        />
        <fieldset class="form-group text-right col-12">
          <button :disabled="submit" class="send ">
            {{ submit ? "Sending..." : "Send" }}
          </button>
        </fieldset>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import Api from "@/resource/Api";
export default { 
 
    props: {
    getSentMessage: {
      type: Object,
      required: true,
    },
  },
  data: () => {
    return {
      members: [""],
      selected: false,
      form: {
        members: [],
        subject: "",
        message: "",
        filesmultiple: [],
      },
      submit: false,
    };
  },
  created() {
    this.getUserLists();
  },
  methods: {
    composeMessage() {
      let url = "send-message";
      let formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      this.form.members.map((key, data) => {
        formData.append("receiver[" + data + "]", key);
      });

      this.form.filesmultiple.map((key, data) => {
        formData.append("files[" + data + "]", key);
      });
      let loader = this.$loading.show({
        container: this.$refs.composeMessageContainer,
      });
      this.submit = true;
      Api.post(url, formData)
        .then((res) => {
          let response = res.data.message;
          this.$notify(response);
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "Error", "danger");
        })
        .finally((err) => {
          this.getSentMessage();
          setTimeout(() => {
            loader.hide();
            this.resetForm()
            this.submit = false;
          }, 2000);
         
        });
    },
    resetForm() {
      this.form = {
        members: [],
        subject: "",
        message: "",
        filesmultiple: [],
      }
    },
    getUserLists() {
      let url = `get-user-list`;
      Api.get(url).then((res) => {
        this.members = res.data.data.member;
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/admin_message_center.css"
  scoped
></style>
