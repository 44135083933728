<template>
    <div class="no-content">
        <p class="center">
            {{text}}
        </p>
    </div>
</template>
<script>
export default {
    props:{
        text:{
            type:String,
            required:false,
            default:"No result found"
        }
    }
}
</script>
<style scoped>
.no-content{
    padding: 30px;
}
.center{
    text-align: center;
}
</style>