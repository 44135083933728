<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="welcome">
        <h1>Welcome</h1>
        <p>
          Register to create your online access to your member dashboard.
          (Please note online account access is not available until your start
          date or thereafter)
        </p>
      </div>

      <div class="form">
        <div class="top-button">
          <router-link :to="{ name: 'Login' }">
            <button class="login_inactive">Log In</button></router-link
          >
          <button class="sign_up_active">Register</button>
        </div>
        <div class="signup_form">
          <div class="signup_head">
            <h3>Register</h3>
            <span
              >To register your account, please create username and password.</span
            >
          </div>
          <b-form-group label="Email" label-for="input-1">
            <b-form-input
              id="input-1"
              type="text"
              required
              v-model="email"
              placeholder="Enter your email"
              readonly
            ></b-form-input>
             <div class="error_message" v-if="formErrorFlag == true">
              {{ formErrors["email"] }}
              </div>
          </b-form-group>
          <b-form-group label="New Password" label-for="input-1">
            <b-form-input
              id="input-1"
              type="password"
              required
              v-model="newpassword"
              placeholder="Enter your new password"
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Confirm Password" label-for="input-2">
            <b-form-input
              id="input-2"
              type="password"
              required
              v-model="confirmpassword"
              placeholder="Repeat new password"
            ></b-form-input>
          </b-form-group>
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
          <button @click="confirmSignUp()" :disabled="verifyLoader">
             <b-spinner v-if="verifyLoader == true" small></b-spinner>
            Submit
            </button>
          <router-link :to="{ name: 'Login' }"
            ><p>Already have an <b>Account?</b></p></router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['items'],
  data() {
    return {
      newpassword: '',
      confirmpassword: '',
      email: '',
      verifyLoader: false,
      formErrorFlag: false,
      formErrors: [],
    };
  },
  // mounted () {
  //   // eslint-disable-line
  //     this.email = this.$store.state.memberEmail;
  // },
  // methods: {
  //   confirmSignUp() {
  //     let app = this;
  //     app.formErrorFlag = false;
  //     if (app.confirmpassword == "" || app.newpassword == "") {
  //       app.formErrorFlag = true;
  //       app.formErrors["error"] = "Please enter password.";
  //       return;
  //     }
  //    if (app.confirmpassword != app.newpassword) {
  //       app.formErrorFlag = true;
  //       app.formErrors["error"] = "Password mismatch!";
  //       return;
  //     }
  //     if(helper.validatePassword(this.newpassword) ==false) {
  //       app.formErrorFlag = true;
  //       app.formErrors["error"] = "Password should be of 8 charcter contains atleast 1 uppercase,1 lowercase, number & special charcter";
  //       return;
  //     }
     
  //     app.verifyLoader = true;
  //     axios
  //       .post("auth/sign-up-member", {
  //         password: app.newpassword,
  //         userid: this.$store.state.memberId,
  //         username: app.email,
  //       })
  //       .then(function (response) {
  //         if (response.data.status == "success") {
  //           helper.infoMessage(
  //             app,
  //             response.data.status,
  //             response.data.message
  //           );
  //           helper.delayRedirect("Login", 0);
  //         }
  //       })
  //       .catch(function (error) {
  //         if (error.response.data.status == "error") {
  //           app.formErrorFlag = true;
  //           app.formErrors["error"] = error.response.data.message;
  //         }
  //         app.verifyLoader = false;
  //       });
  //   },
  //   }
};
</script>
<style>
@import "../../../assets/css/group_dashboard/signup.css";
</style>