<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="banking_information">
        <breadcrumb :items="breadcrumbItems" class="banking-breadcrumb" />
        <div class="title">
          <h2>Banking Information</h2>
          <button v-b-modal.add_bank_info class="add_bank_info">
            <i class="fas fa-plus"></i>
            <span style="margin-left: 5px">Add Banking Info</span>
          </button>
        </div>
        <div class="table_container">
          <div class="bank_info">
            <table>
              <thead>
                <tr>
                  <td>S.No</td>
                  <td>Account Name</td>
                  <td>Routing Number</td>
                  <td>Bank Name</td>
                  <td>Account Number</td>
                  <td>Account Type</td>
                  <td>Account Holder Type</td>
                  <td>Actions</td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in groupBanks"
                  :key="index"
                  :class="item.is_primary ? 'bank-primary-backgroud' : null"
                >
                  <td>
                    <strong>{{ index + 1 }}</strong>
                  </td>
                  <td>{{ item.bank_accountname }}</td>
                  <td>{{ item.bank_routing }}</td>
                  <td>{{ item.bank_name }}</td>
                  <td>{{ item.bank_account4 }}</td>
                  <td>
                    {{ item.account_type ? item.account_type : "--" }}
                  </td>
                  <td>
                    {{
                      item.account_holder_type ? item.account_holder_type : "--"
                    }}
                  </td>
                  <td>
                    <div class="icons">
                      <template v-if="item.is_primary">
                        <div class="banking_status-listActive">
                          <i class="fas fa-check-circle"></i>
                          <span class="status">Active</span>
                        </div>
                      </template>
                      <template v-else>
                        <div class="">
                          <a
                            href="javascript:void(0);"
                            @click.prevent="setPrimaryBank(item.bank_id)"
                          >
                            <img
                              src="@/assets/images/landscape-phone.svg"
                              width="22"
                            />
                            Set as Primary
                          </a>
                        </div>
                          <delete-button
                            @click.native.prevent="deleteBank(item.bank_id)"
                          />
                      </template>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <AddBankInfo />
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import { mapGetters } from "vuex";
import AddBankInfo from "@/pages/Bank/AddBankInfo.vue";
export default {
  components: { AddBankInfo },
  data: () => {
    return {
      breadcrumbItems: [{ title: "Banking Information", active: true }],
    };
  },
  computed: {
    ...mapGetters({
      groupBanks: "groupBanks",
      groupPaymentType: "groupPaymentType",
    }),
  },
  methods: {
    setPrimaryBank(bankId) {
      let url = `set-primary-bank?bank_id=${bankId}`;
      this.$store.commit("setDialogClass", "group-app-confirm-dialog");
      this.$confirm({
        title: "Set Primary Bank",
        message: `Do you want to set this bank to primary?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.put(url)
              .then((res) => {
                let response = res.data.message;
                this.$store.dispatch("getGroupBanks");
                this.$notify(response);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
    deleteBank(bankId) {
      let url = `delete-bank?bank_id=${bankId}`;
      this.$store.commit("setDialogClass", "group-app-warning-dialog");
      this.$confirm({
        title: "Delete Bank",
        message: `Do you want to delete this bank?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            let loader = this.$loading.show();
            Api.delete(url)
              .then((res) => {
                let response = res.data.message;
                this.$store.dispatch("getGroupBanks");
                this.$notify(response);
              })
              .catch((err) => {
                this.$notify(err.response.data.message, "Error", "danger");
              })
              .finally(() => {
                loader.hide();
              });
          }
        },
      });
    },
  },
  mounted() {
    //check type eft ,if no redicrect to dashboard
    if (this.groupPaymentType !== "EFT") {
      this.$router.push({ name: "GroupDashboard" });
    }
    this.$store.dispatch("getGroupBanks");
    let loader = this.$loading.show();
    setTimeout(() => {
      loader.hide();
    }, 1000);
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/banking_information.css"
  scoped
></style>
<style>
.select_section .custom-file-label {
  font-size: 16px;
  border: 1px solid #dee2e5;
  border-radius: 8px;
}
.select_section .custom-file-label .form-file-text {
  display: none !important;
}
.select_section .custom-file-label::after {
  right: unset;
  left: 0;
  bottom: 0;
  height: unset;
  background: #03c3ec33;
  margin: 3px 6px;
  padding: 3px 15px;
  border-radius: 8px;
  font-size: 14px;
  color: #5e5e5e;
  border: none;
  cursor: pointer;
}
.select_section .custom-file {
  width: 290px;
}
#add_group_user .form-text {
  margin-top: 15px !important;
}
#add_group_user .text-muted {
  color: #03c3ec !important;
}
@media only screen and (max-width: 991px) {
  .select_section .custom-file-label {
    font-size: 12px;
  }
  .select_section .custom-file-label::after {
    font-size: 12px;
  }
}
@media only screen and (max-width: 575px) {
  .select_section .custom-file {
    width: 100%;
  }
}
</style>
