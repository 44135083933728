<template>
  <!-- Modal -->
  <b-modal
    id="personalizedSiteSendEmail"
    modal-class="client-details-modal"
    centered
    size="lg"
    hide-footer
  >
    <template #modal-header style="border-bottom: 0px">
      <div class="view-clients-header">
        <b-button variant="close-btn close-option" @click="closeModal">
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div class="view-clients-modal-content">
        <h6>Email Member</h6>
        <div class="addMessage-form">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit, invalid }">
            <form
              @submit.stop.prevent="handleSubmit(sendEmail)"
              ref="sendPersonalizedEmailContainer"
            >
              <div>
                <div class="form-group row p-0 m-0">
                  <div class="col-md-6 p-0">
                    <!-- validation rule , neverbounce: true  -->
                    <input-text
                    :rules="{ required: true, email : true }"
                      labelFor="email"
                      labelName="Email"
                      inputName="email"
                      inputType="email"
                      :vmodel.sync="form.email"
                      class="input-fld p-0"
                    />
                  </div>
                  <div class="col-md-6 p-0">
                    <input-text
                      :rules="{ required: true }"
                      labelFor="subject"
                      labelName="Subject"
                      inputName="subject"
                      :vmodel.sync="form.subject"
                      class="input-fld-right pr-0"
                    />
                  </div>

                  <div class="col-xxl-12 col-md-12 mb-3 p-0">
                    <validation-provider
                      name="message"
                      rules="required"
                      v-slot="{ errors }"
                    >
                    <label>
                      Message
                      <span class="required-indc input-required">*</span>
                    </label>
                    <ckeditor
                      :editor="editor"
                      v-model="form.message"
                      :config="editorConfig"
                      tag-name="textarea"
                      ref="cktext"
                    />
                    <span class="error-message"> 
                      {{ errors[0] }}
                    </span>
                  </validation-provider>
                    <div class="row justify-content-md-center">
                      <div class="col-xxl-5 mb-4">
                        <b-button class="btn btn-email-send btn-secondary" type="submit" :disabled="isSend">
                          <template v-if="isSend"> Sending ... </template>
                          <template v-else :disabled="invalid"> Send </template>
                        </b-button>
                      </div>
                    </div>
                    <div class="mb-3 modal-inner-bg">
                      <label for="">From:</label>
                      <p class="mb-2">
                        <strong>{{ groupInfo.group_contact_first_name }} {{ groupInfo.group_contact_last_name}}</strong>
                      </p>
                      <p class="mb-2" v-if="groupInfo.group_code">{{ groupInfo.group_code }}</p>
                      <p class="mb-2">{{ groupInfo.group_email }}</p>
                      <p>{{ groupInfo.group_phone }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Helper from "@/resource/Helper";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  name: "PersonalizeEmailsend",
  props: {
    selectedLink: {
      required: true,
      type: String,
    },
  },
  data: () => ({
    editor: ClassicEditor,
    editorConfig: Helper.simpleEditorConfig(),
    form: {
      email: "",
      message: "",
      subject: "",
      link: "",
    },
    isSend: false,
  }),
  computed: {
    ...mapGetters({ groupInfo : "groupInfo" })
  },
  methods: {
    reset() {
      this.form = {
        email: "",
        message: "",
        subject: "",
        link: "",
      };
      this.$refs.observer.reset();
    },
    closeModal() {
      this.reset();
      this.$emit("onCloseModal");
      this.$bvModal.hide("personalizedSiteSendEmail");
    },
    sendEmail() {
      let url = process.env.VUE_APP_API_URL_V2 +
        `send-personalized-email`;
      this.form.link = this.selectedLink;
      this.isSend = true;
      let loader = this.$loading.show({
        container: this.$refs.sendPersonalizedEmailContainer,
      });
      axios.post(url, this.form)
        .then((res) => {
          this.$notify(res.data.message);
          this.closeModal();
        })
        .catch((err) => {
          this.$notify(err.response.data.message, "error");
        })
        .finally(() => {
          this.isSend = false;
          loader.hide();
        });
    }
  },
};
</script>
<style>
.ck-editor__editable {
  min-height: 200px;
}
.error-message{
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: #dc3545;
}
.btn-email-send {
  background: linear-gradient(#0cf,#1597b8 49%,#0567bc) !important;
    border-radius: 6px !important;
    color: #fff;
    outline: none !important;
    border: 0;
    text-align: center;
    padding: 10px 100px !important;
    margin-top: 10px;
    transition: all .6s ease !important;
    margin-bottom: 20px;
    border: none !important;
}
#personalizedSiteSendEmail___BV_modal_header_{
  border-bottom: 0px !important;
  justify-content: flex-end !important;
}

.view-clients-header .close-option {
    position: absolute;
    top: -15px;
    right: -15px;
    background: #fff;
    color: #000;
    border: 0;
    box-shadow: -2px 2px 13px -4px rgb(0 0 0 / 46%);
    border-radius: 60px;
    font-size: 18px;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.view-clients-modal-content h6 {
    font-weight: 700;
    color: #2c3f58;
    margin-bottom: 20px;
}

</style>