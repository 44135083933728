<template>
  <div class="wrapper_group_dashboard vld-parent knowledge_grpdashboard_wrap" ref="knowledgeBaseContainer">
    <div class="group_dashboard knowledge_grpdashboard">
      <breadcrumb :items="breadcrumbItems" class="knowledge-base-breadcrumb" />
       <div class="title header-title">
            <h2>Knowledge Center</h2>
          </div>
        <b-tabs card v-model="tabIndex">
          <b-tab title="From Admin" @click="tabIndex = 0">
            <b-card-text>
               <KnowledgeCenterListAdminVue ></KnowledgeCenterListAdminVue>
              
               
            </b-card-text>
          </b-tab>

            <b-tab title="From Rep" @click="tabIndex = 1">
            <b-card-text>
              <KnowledgeCenterListRepVue></KnowledgeCenterListRepVue>
            </b-card-text>
          </b-tab>
        </b-tabs>
    
    </div>
  </div>
</template>
<script>

import KnowledgeCenterListAdminVue from "./KnowledgeCenterListAdmin.vue";
import KnowledgeCenterListRepVue from "./KnowledgeCenterListRep.vue";
export default {
  data: () => {
    return {
      breadcrumbItems: [{ title: "Knowledge Center", active: true }],
      tabIndex: 1,
    };
  },
  components: {
    KnowledgeCenterListAdminVue,
    KnowledgeCenterListRepVue
  },

};
</script>
<style src= '@/assets/css/group_dashboard/all_messages.css'></style>
