<template>
  <div class="invoices_container">
    <div class="invoices singleInvoice">
      <div class="title">
        <h2>Invoices</h2>
        <invoice-payment :invoice="invoice" @onPaymentSuccess="updateInvoice" />
        <payment-history-download
          :invoice="invoice"
          @onPaymentSuccess="updateInvoice"
        />
        <div class="">
          <button class="pay_now" v-b-modal.payment_history>
            Payment History
          </button>
          <button v-if="canPay" class="pay_now" v-b-modal.paystand_dialog>
            Pay Now
          </button>
          <!-- <date-picker
            v-model="selectedMonth"
            :default-value="new Date()"
          ></date-picker> -->
          <date-picker
            v-model="selectedMonth"
            @input="getInvoice(selectedMonth)"
            class="invoicePicker"
            type="month"
            :default-value="new Date()"
            placeholder="Select Month"
          ></date-picker>

          <!-- <div class="select_date">
            <span class="calender_icon">
              <img src="@/assets/images/group_calender.svg" />
            </span>
            <input
              type="month"
              v-model="selectedMonth"
              @change="getInvoice(selectedMonth)"
            />
            <span class="calender_dropdown">
              <img src="@/assets/images/Layer.svg" />
            </span>
          </div> -->
          <!-- <button class="pay_now" v-b-modal.paystand_dialog>Pay Now</button> -->
          <!-- <router-link class="link-hover" :to="{ name: 'AllInvoices' }">
            <span class="view-all">View All </span>
          </router-link> -->
        </div>
      </div>
      <div class="table_container" style="overflow-x: auto">
        <table>
          <thead>
            <tr>
              <td>Invoice Id</td>
              <td>Date</td>
              <td>Totals</td>
              <td>Status</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            <tr v-if="invoice">
              <td>
                <strong>{{ invoice.invoice_id_with_group }}</strong>
              </td>
              <td>
                Issue Date:
                {{ invoice.invoice_date | date_format("MM-DD-YYYY") }}
                <br />
                Due Date:
                {{ invoice.invoice_due_date | date_format("MM-DD-YYYY") }}
                <br />
                From Date:
                {{ invoice.invoice_start_date | date_format("MM-DD-YYYY") }}
                <br />
                To Date: {{ invoice.invoice_end_date | date_format }}
              </td>
              <td>
                Monthly Premium Amount: {{ invoice.total_invoice | currency }}
                <br />
                Total Due Amount :
                {{ invoice.total_invoice_due_amount | currency }}
                <span v-if="invoice.total_previous_due > 0">
                  <br />
                  Total Previous Due Amount :
                  {{ invoice.total_previous_due | currency }}
                </span>
              </td>
              <td>{{ invoice.payment_status }}</td>
              <td>
                <div class="icons">
                  <a
                    :href="invoice.file"
                    target="_blank"
                    class="action_container"
                    download
                    v-tooltip="'Download'"
                  >
                    <span class="action_icon">
                      <img src="@/assets/images/download.svg" />
                    </span>
                    Download
                  </a>
                  <router-link
                    class="link-hover"
                    :to="{
                      name: 'InvoiceDetail',
                      params: { date: formattedDate(selectedMonth) },
                    }"
                  >
                    <span class="action_container" v-tooltip="'View Details'">
                      <span class="action_icon">
                        <i class="fas fa-info"></i>
                      </span>
                      View Details
                    </span>
                  </router-link>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <template v-if="!invoice">
          <no-content text="No Invoices Found" />
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import moment from "moment";
import InvoicePayment from "@/pages/Invoice/InvoicePayment.vue";
import PaymentHistoryDownload from "../Invoice/PaymentHistoryDownload.vue";
export default {
  components: { InvoicePayment, PaymentHistoryDownload },
  data() {
    return {
      invoice: null,
      selectedMonth: [],
      canPay: false,
    };
  },
  methods: {
    updateInvoice() {
      this.$store.commit("setInvoiceIds", []);
      this.$store.commit("setUnPaidInvoices", []);
      this.getInvoice(this.selectedMonth);
    },
    formattedDate(date) {
      return moment(date)
        .endOf("month")
        .format("YYYY-MM-DD");
    },
    setUnpaidInvoices(date) {
      const newDate = moment(date)
        .endOf("month")
        .format("YYYY-MM-DD");
      const url = `render-invoice-list/${newDate}`;
      Api.get(url)
        .then((res) => {
          let unPaidInvoices = res.data.data.filter((item) => {
            if (
              item.invoice_payment_status == "UNPAID" ||
              item.invoice_payment_status == "PARTIAL"
            ) {
              return item;
            }
          });
          if (unPaidInvoices.length > 0) {
            this.canPay = true;
            this.$store.commit("setUnPaidInvoices", unPaidInvoices);
          } else {
            this.canPay = false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    getInvoice(date = null) {
      // const gId = this.$store.getters.groupInfo.gid
      const selectedDate = date ? date : this.selectedMonth;
      const endOfMonthDate = moment(selectedDate)
        .endOf("month")
        .format("YYYY-MM-DD");
      const url = `render-invoice/${endOfMonthDate}`;
      let loader = this.$loading.show();

      Api.get(url)
        .then((res) => {
          this.invoice = res.data.data;
          if (res.data.data.length == 0) {
            this.invoice = null;
            this.$store.commit("setUnPaidInvoices", []);
          }
          //set unpaid invoices
          if (this.invoice) {
            this.setUnpaidInvoices(selectedDate);
          }
        })
        .catch((err) => {
          this.invoice = null;
          this.$store.commit("setUnPaidInvoices", []);
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.$store.commit("setInvoiceIds", []);
    this.$store.commit("setUnPaidInvoices", []);
    // this.selectedMonth = moment()
    //   .endOf("month")
    //   .format("YYYY-MM");
    this.selectedMonth = new Date();
    this.getInvoice();
  },
};
</script>
<style src="@/assets/css/group_dashboard/invoices.css"></style>
<style>
.modal.show .modal-dialog {
  color: #254050 !important;
}
</style>
