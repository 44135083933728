<template>
  <div class="group_information_container">
    <div class="group_information">
      <router-link :to="{ name: 'AccountInformation' }">
        <div class="info_edit" title="Edit Information">
          <img src="@/assets/images/group_info_edit.svg" width="50" />
        </div>
      </router-link>
      <div class="title">
        <h2>Group Information</h2>
        <!-- <router-link class="link-hover" :to="{ name: '' }">
          <span class="view-all">View All</span>
        </router-link> -->
      </div>
      <!-- group profile -->
      <GroupInfo />
      <!-- group detail -->
      <GroupAddress />
    </div>
  </div>
</template>

<script>
import GroupInfo from "@/pages/GroupInformation/GroupInfo";
import GroupAddress from "@/pages/GroupInformation/GroupAddress";
export default {
  components: {
    GroupInfo,
    GroupAddress,
  },
};
</script>

<style src="@/assets/css/group_dashboard/group_information.css" scoped></style>
