<template>
  <b-modal
    v-if="data"
    id="knowledge-center-detail"
    centered
    size="lg"
    scrollable
    hide-footer
    :title="data.title"
  >
    <div class="message_description">
      <div class="date">
          {{data.published_date | date_format('YYYY-MM-DD','gg MMM , ddd')}}
      </div>
      <div class="message_content">
        <p v-html="data.description"></p>
        <br />
        <div  v-if="data.file && data.file.length > 0">
          <h6>Attachments:</h6>
          <ul  style="list-style:none">
            <li v-for="file in data.file" :key="file.id">
              <a
                :href="knowledgeBaseDownloadUrl + file.filename"
                target="__blank"
                download
               
              >
                <i class="fa fa-file"></i>
                {{ file.filename }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { knowledgeBaseDownloadUrl } from "@/resource/EndPoints/endpoints";
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data: () => {
    return {
      knowledgeBaseDownloadUrl: knowledgeBaseDownloadUrl,
    };
  },
};
</script>
<style src= '../../assets/css/group_dashboard/all_messages.css' scoped>

</style>


