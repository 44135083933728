<template>
  <div class="wrapper_group_dashboard">
    <div class="group_dashboard">
      <div class="admin_message_wrapper">
        <breadcrumb :items="breadcrumbItems" class="admin-center-messages-breadcrumb" />
        <div class="admin_message_center">
          <div class="admin_message_center_tab">
            <h3>Admin Message Center</h3>
            <b-card no-body>
              <b-tabs pills card>
                <b-tab title="Send" active><b-card-text>
                  <div class="message_list">
                    <div class="title">
                      <h2>Send Message</h2>
                      <button
                        class="new_message"
                        @click.prevent="
                          canCompose = true;
                          showMessage = null;">
                        Add New
                      </button>
                    </div>
                    <admin-message-list
                      :messages="messages"
                      @onClickedItem="getMessage"
                    />
                    <small v-if="messages.records">
                      <pagination :rows.sync="messages" :filters="[]" />
                    </small>
                  </div>
                  <template v-if="canCompose">
                    <admin-compose-message :getSentMessage="getSentMessages" />
                  </template>
                  <template v-if="showMessage">
                    <admin-message-detail :message="showMessage" @sentMessageList="getSentMessages" />
                  </template>
                </b-card-text></b-tab>

                <b-tab title="Received"><b-card-text>
                  <div class="message_list">
                    <div class="title">
                      <h2>Received Message</h2>
                      <div class="message-filter">
                        <b-form-select v-model="selected" class="mb-3">
                          <b-form-select-option :value="null">All</b-form-select-option>
                          <b-form-select-option value="unread">Unread</b-form-select-option>
                          <b-form-select-option value="read">Read</b-form-select-option>
                          <b-form-select-option value="archive">Archive</b-form-select-option>
                        </b-form-select>
                      </div>
                    </div>
                    <admin-message-list
                      :messages="messages"
                      @onClickedItem="getMessage"
                    />
                    <small v-if="messages.records">
                      <pagination :rows.sync="messages" :filters="[]" />
                    </small>
                  </div>
                  <template v-if="canCompose">
                    <admin-compose-message :getSentMessage="getSentMessages" />
                  </template>
                  <template v-if="showMessage">
                    <!--<admin-message-detail :message="showMessage" @sentMessageList="getSentMessages" /> -->
                  </template>
                </b-card-text></b-tab>
              </b-tabs>
            </b-card>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminComposeMessage from "./AdminComposeMessage.vue";
import AdminMessageList from "./AdminMessageList.vue";
import AdminMessageDetail from "./AdminMessageDetail.vue";
import Api from "@/resource/Api";
import axios from "axios"
export default {
  components: {
    AdminMessageList,
    AdminComposeMessage,
    AdminMessageDetail,
  },
  data() {
    return {
      canCompose: false,
      showMessage: null,
      selected: null,
      messages: {},
      breadcrumbItems: [{ title: "All Sent Messages", active: true }],
      users : []
    };
  },
  methods: {
    getMessage(item) {
      this.canCompose = false;
      this.showMessage = item;
    },

    getSentMessages() {
      let url = process.env.VUE_APP_API_URL_V2+`get-sent-message-list?page_size=10`;
      let loader = this.$loading.show();
      axios.get(url)
        .then((res) => {
          this.messages = res.data;
          this.showMessage = this.messages.records[0];
          if (this.messages.records.length < 1) {
            this.canCompose = true;
            this.showMessage = null;
          } else {
              this.canCompose = false;
          }
        })
        .catch((err) => {
          console.log("error", err);
        })
        .finally(() => {
          loader.hide();
        });
    },

  },
  created() {
    //temporary
    ///this.$router.push({name:"GroupDashboard"})
    this.getSentMessages();
  },
  watch: {
    messages: function(newData, oldData) {
      if (newData != oldData) {
        this.showMessage =
          newData.records.length > 0 ? newData.records[0] : null;
      }
    },
  },
};
</script>
<style
  src="@/assets/css/group_dashboard/admin_message_center.css"
  scoped
></style>
<style>
.compose_message .form-group label {
  font-size: 14px !important;
  color: #254050;
}
.compose_message .custom-file-label {
  border: 1px solid #03c3ec !important;
  border-radius: 4px;
}
.compose_message #input-group-2 .form-file-text {
  color: #778699 !important;
  font-size: 13px;
}
.compose_message .custom-file-label::after {
  display: none;
}
@media only screen and (max-width: 1205px) {
  .compose_message .form-group label {
    font-size: 12px !important;
  }
  .compose_message #input-group-2 .form-file-text {
    font-size: 12px;
  }
}
.admin_message_center_tab {
    display: block;
    width: 100%;
    padding: 10px;
}
.admin_message_center_tab h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #2c3f58;
}
.admin_message_center_tab .card {
    border: none;
}
.admin_message_center_tab .card .card-header {
    padding: 0;
    border-bottom: none;
    padding-top: 20px;
    padding-bottom: 25px;
    background: #f7fafc;
}
.admin_message_center_tab .card .card-header ul {
    margin: 0;
    padding: 0;
}
.admin_message_center_tab .card .nav-pills li a {
    color: #03c3ec;
    border: 1px solid #03c3ec;
    display: inline-block;
    padding: 8px 40px;
    margin-right: 10px;
    text-align: center;
    border-radius: 60px;
    font-size: 14px;
    background: transparent;
}
.admin_message_center_tab .card .nav-pills li a:hover {
    background-image: linear-gradient( #00CCFF 0%, #1597B8 49%, #0567BC 100%)!important;
    color: #fff;
}
.admin_message_center_tab .card .nav-pills li a.active {
    background-image: linear-gradient( #00CCFF 0%, #1597B8 49%, #0567BC 100%)!important;
    color: #fff;
}
.admin_message_center_tab .card .card-body {
    background: #f7f7f7;
    padding: 0;
}
.admin_message_center_tab .card .card-body .message_list .column .row {
    border-bottom: 1px solid #ebf0f4;
}
.admin_message_center_tab .card .card-body .message_list .column .row:hover {
    background: #ebf0f4;
    transition: all .4s ease;
}
.message-filter .mb-3 {
  margin-bottom: 0!important;
}

.active_background {
  background: #dce5ec94!important;
}

</style>
