<template>
  <div id="groupnav">
    <div class="logo">
      <router-link :to="{ name: 'GroupDashboard' }">
        <img src="@/assets/images/logoew.png" alt="corenroll-logo" />
      </router-link>
    </div>
    <div class="group_right">
      <div class="login_info">
        <p><span>Date: </span>{{ current_date }}</p>

        <!-- <p><span>Last login:</span> {{ group_last_login }}</p>

        <p><span>IP Address: </span>{{ group_ip_address }}</p> -->
      </div>

      <div class="notification_profile">
        <!-- <div class="group_notification">
          <a class="bell" id="notification" href="#">
            <img src="@/assets/images/bxs-bell.svg"
          /></a>

          <div class="notification_count">2</div>
        </div> -->
        <!--Notification pop up section starts -->
        <b-popover
          id="popover_notfication"
          target="notification"
          triggers="click"
          placement="bottom"
        >
          <div
            v-for="(item, index) in notification"
            :key="index"
            class="notify_group"
          >
            <div class="notify_icon"></div>
            <div class="notify_detail">
              <h6>{{ item.detail }}</h6>
            </div>
          </div>
        </b-popover>

        <!--Notification Section Ends -->
        <div class="group_profile">
          <div class="down">
            <b-dropdown id="dropdown" right>
              <template slot="button-content">
                <div class="group_logo">
                  <img :src="groupLogo" />
                </div>
                <div class="dropdown_icon">
                  <i class="fas fa-angle-down"></i>
                </div>
              </template>
              <div :class="{ 'hideBlock-content': showHideSwithcAccount }">
                <div>
                  <div class="d-flex">
                    <div class="img-profile-wrap">
                      <img
                        :src="groupLogo ? groupLogo : dummyImage"
                        alt="profile"
                      />
                    </div>
                    <div class="user-detail">
                      <h6 class="mb-0">{{ groupInfo.group_name }} <div v-if="mainGroupId == groupInfo.gid" class="" style="display: inline-block; font-size: 12px; font-style: italic; font-weight: 400;">
                        (Main Account)
                        </div></h6>
                      <p>{{ groupInfo.group_email }}</p>
                    </div>
                  </div>
                  <div class="icon-wrap">
                        <ul>
                          <li>
                            <router-link :to="{ name: 'ChangePassword' }">
                              <div class="icon-div">
                                <img src="../../assets/images/key.svg" alt="home" />
                              </div>
                              <p>Change Password</p>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'AccountInformation' }">
                              <div class="icon-div">
                                <img
                                  src="../../assets/images/edit-profile.svg"
                                  alt="home"
                                />
                              </div>
                              <p>Account Information</p>
                            </router-link>
                          </li>
                          <li>
                            <router-link :to="{ name: 'DisplaySetting' }">
                              <div class="icon-div">
                                  <img
                                    src="../../assets/images/settings.svg"
                                    alt="home"
                                  />
                                </div>
                                <p>Display Setting</p>
                              </router-link>
                          </li>
                        </ul>
                  </div>
                </div>
                <div class="switchAccount" @click="toggleSwithcAccount" v-if="subGroupData.length > 1">
                  <p>Switch Account</p>
                </div>
              </div>
              <div class="multipleAccounts" :class="{ showSwithcAccount: showHideSwithcAccount }">
                  <div class="multipleAcc-title">
                    <span @click="showHideSwithcAccount = false" class="backIcon"><i class="fas fa-arrow-left"></i></span>
                    <span>Select Profile</span>
                  </div>
                  <vue-scroll :ops="userHeaderScroll">
                  <div class="multipleProfile-list">                    
                      <div class="" v-if="subGroupData.length > 1">
                        <div class="d-flex align-items-center pb-3" v-for="group in subGroupData"  :key="group.gid"  @click="switchAccount(group.gid)">
                          <div class="switch-profile-wrap ">
                            <img
                              :src="group.group_profile_image ? group.group_profile_image : dummyImage"
                              alt="profile"
                            />
                          </div>
                          <div class="switch-user-detail d-flex align-items-center justify-content-between w-60">
                            <div class="userContent">
                              <span class="mb-0">{{ group.gname }} 
                                <div v-if="mainGroupId == group.gid" class="" style="display: inline-block; font-size: 12px; font-style: italic; font-weight: 400;">
                                  (Main Account)
                                </div>
                              </span>
                              <p>{{ group.gemail }}</p>
                            </div>
                            <div class="ml-3">
                              <input :checked="group.gid == groupInfo.gid" type="radio">
                            </div>
                          </div>
                        </div>
                      </div>                    
                  </div>
                </vue-scroll>
              </div>
              <!-- <b-dropdown-item class="nav-dropdown dropdown-div">
                <router-link :to="{ name: 'GroupDashboard' }">
                  Home
                </router-link>
              
              </b-dropdown-item> -->

              <!-- <b-dropdown-divider></b-dropdown-divider> -->
              <!-- <b-dropdown-item class="nav-dropdown">
                <router-link :to="{ name: 'PlanConfigurationList' }">
                  Configure Plans
                </router-link>
              </b-dropdown-item> -->
              <!-- <b-dropdown-divider v-if="subGroupData.length > 1"></b-dropdown-divider> -->
              <!-- <b-dropdown-item class="nav-dropdown">
                <router-link :to="{ name: 'ChangePassword' }">
                  Change Password
                </router-link>
              </b-dropdown-item> -->
              <!-- <b-dropdown-item class="nav-dropdown">
                <router-link :to="{ name: 'AccountInformation' }">
                  Edit Account Info
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item class="nav-dropdown">
                <router-link :to="{ name: 'DisplaySetting' }">
                  Display Settings
                </router-link>
              </b-dropdown-item> -->
              <!-- <b-dropdown-item class="nav-dropdown">
                <router-link :to="{ name: 'DefaultOtp' }">
                  Default Otp
                </router-link>
              </b-dropdown-item> -->


              


             <!-- <button class="add-account" v-b-modal.add_account>Add Account
                <img src="../../assets/images/add.svg"/>
              </button> -->
            </b-dropdown>
          </div>
        </div>
        <div class="corenrollApps-list" v-if="1 == 0">
          <b-dropdown class="dashboard-icons">
            <template #button-content>
              <img src="@/assets/images/icons/app-icon.svg" id="dash-icon" />
            </template>
            <template #item-content> </template>
            <b-dropdown-item href="javascript:void(0)">
              <div class="row">
                <a
                  href="https://www.purenroll.com/"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img src="@/assets/images/icons/admin.svg" alt="" />
                  <span class="d-block text-center">Admin</span>
                </a>
                <a
                  href="https://msg.purenroll.com/"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img src="@/assets/images/icons/message-center.svg" alt="" />
                  <span class="d-block text-center">Message center</span>
                </a>
                <a
                  href="https://files.purenroll.com/"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img src="@/assets/images/icons/file-management.svg" alt="" />
                  <span class="d-block text-center">File Management</span>
                </a>
                <a
                  href="https://reps.purenroll.com/"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img src="@/assets/images/icons/Dashboard-REP.svg" alt="" />
                  <span class="d-block text-center">Dashboard<br />(Rep)</span>
                </a>
                <a
                  href="https://dashboard.purenroll.com/"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img
                    src="@/assets/images/icons/Dashboard-Member.svg"
                    alt=""
                  />
                  <span class="d-block text-center">Dashboard (Member)</span>
                </a>
                <a
                  href="https://tickets.purenroll.com/"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img
                    src="@/assets/images/icons/ticket-management.svg"
                    alt=""
                  />
                  <span class="d-block text-center"
                    >Ticket<br />Management</span
                  >
                </a>
                <a
                  href="https://accounts.purenroll.com/"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img src="@/assets/images/icons/SSO.svg" alt="" />
                  <span class="d-block text-center">SSO</span>
                </a>
                <hr />
                <h3>Registrations</h3>
                <a
                  href="https://reps.purenroll.com/registration"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img src="@/assets/images/icons/Da-Reg.svg" alt="" />
                  <span class="d-block text-center">Rep<br />Registration</span>
                </a>
                <a
                  href="https://member.purenroll.com/sign-up"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img src="@/assets/images/icons/Dm-Reg.svg" alt="" />
                  <span class="d-block text-center"
                    >Member<br />Registration</span
                  >
                </a>
                <a
                  href="https://group.purenroll.com/registration"
                  class="col-4 dashIcon-block"
                  target="_blank"
                >
                  <img src="@/assets/images/icons/Dg-Reg.svg" alt="" />
                  <span class="d-block text-center"
                    >Group<br />Registration</span
                  >
                </a>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <add-account/>
  </div>
</template>
<script>
/*eslint-disable*/
import moment from "moment";
import defaultLogo from "@/assets/images/corenroll-logo.png";
import { mapGetters } from "vuex";
import AddAccount from './AddAccount.vue';
import Api from "@/resource/Api";
import helper from "@/resource/Helper";
import store from "@/store";

export default {
  components: { AddAccount },
  data() {
    return {
      showHideSwithcAccount: false,
      current_date: moment().format("MMMM Do, YYYY"),
      group_last_login: "December 12th, 2020",
      group_ip_address: "45.251.35.226",
      notification: [
        { detail: "Lorem Ipsum" },
        { detail: "Lorem Ipsum" },
        { detail: "Lorem Ipsum" },
      ],
      defaultLogo,
      subGroupData:[],
      dummyImage : require("../../assets/images/dummy.png"),
      mainGroupId: '',
      userHeaderScroll: {
        vuescroll: {
          mode: "native",
          wheelScrollDuration: "700",
        },
        scrollPanel: {
          maxHeight: 300,
          speed: 2000,
        },
        bar: {
          background: "#ddd",
        },
      },
    };
  },
  created() {
    this.getSubAccount();
  },
  methods: {
    getSubAccount() {
      this.mainGroupId = this.$store.getters.mainGroupId
      if(!this.$store.getters.mainGroupId) { store.commit("setMainGroupId", this.groupInfo.gid);}
      let url = 'sub-group/list?main_group='+this.$store.getters.mainGroupId
      Api.get(url)
        .then((res) => {
          this.subGroupData = res.data.data;
        })
        .catch((err) => {
          console.log("something went wrong!");
        });
    },
    switchAccount(groupId) {
      let loader = this.$loading.show();
      let url = "sub-group/switch-account"
      Api.post(url, {
          group_id: groupId
        })
        .then(function (response) {
          if (response.data.status == "success") {
            store.commit("setAuthentication", true);
            helper.setLoginDetails(response.data.data);
            helper.dashboardGetters();
          }
        }).finally(() => {
          loader.hide();
        });
    },
    toggleSwithcAccount() {
      let app = this;
      if (app.showHideSwithcAccount) {
        app.showHideSwithcAccount = false;
      } else {
        app.showHideSwithcAccount = true;
      }
    },

  },
  computed: {
    ...mapGetters({ groupInfo: "groupInfo" }),
    groupLogo() {
      return this.groupInfo.group_logo
        ? this.groupInfo.group_logo
        : defaultLogo;
    },
  },
};
</script>

<style src="@/assets/css/group_dashboard/group_dashboard_header.css"></style>
<style>

.down .dropdown-item {
  color: #1a051d !important;
  padding: 5px 16px 5px 16px !important;
  cursor: default;
}
.down .dropdown-item:hover,
.down .dropdown-item:focus {
  background-color: none;
}
.dropdown-div {
  display: flex;
}
.down .dropdown-divider {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;
}
.down .dropdown-menu.show {
  border: 0px;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 13%);;
}
.icon-wrap {
  margin-top: 0.9rem;
}
.icon-wrap ul{
  list-style: none;
}
.icon-wrap  ul li a{
  display: flex;
  color: #000;
  padding: 5px 0;
  align-items: center;
  font-size: 15px;
}
.icon-div {
  margin-right: 0.6rem;
  width: 15px;
}
.icon-div img {
  width: 100%;
  position: relative;
}
.switch-title{
  color: #11193c;
  margin-bottom: 1rem;
  font-size: 14px !important;
  font-weight: 500;
  margin-bottom: 12px;
}
.add-account{
  align-items: center;
}
.add-account img{
  background-color: none;
  color: #fff;
}
#dropdown .btn {
  border-color: black;
  background-color: black;
}
#dropdown button {
  display: flex;
  align-items: center;
  justify-content: center;
}
#dropdown .btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
#dropdown button::after {
  display: none;
}
#dropdown button .dropdown_icon {
  margin-left: 15px;
}
#dropdown .dropdown-menu.show {
  width: 290px;
  border-radius: 12px;
  padding: 1rem;
  left: -39px;
  box-shadow: rgb(178 245 255 / 45%);
}

.right-most .down {
  margin: 10px 0 0 8px;
}
.img-profile-wrap {
  width: 50px;
  height: 50px;
  border: 2px solid #02c5f5;
  background-color: #fff;
  border-radius: 50px;
  margin-right: 0.8rem;
}
.img-profile-wrap img {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  object-fit: cover;
}
.switch-profile-wrap{
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 50px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #ddd;
}
.switch-profile-wrap img{
  width: 100%;
  height: 100%;
  border-radius: 50px;
  object-fit: cover;
}
.user-detail {
  color: #11193c;
  margin-top: 0.2rem;
}
.user-detail h6{
  font-size: 18px;
}
.user-detail p{
  font-size: 14px;
  font-style: italic;
}
.switch-user-detail{
  color: #11193c;
  cursor: pointer;
}
.switch-user-detail span{
  font-size: 14px;
  font-weight: 600;
}
.switch-user-detail p{
  font-size: 12px;
  font-style: italic;
  word-break: break-word;
}
.switch-user-detail .userContent {
  max-width: 166px;
}
.add-account {
  background-image: linear-gradient(#00ccff 0%, #1597b8 49%, #0567bc 100%);
  border: none;
  padding: 6px 46px;
  width: 100%;
  color: #fff;
  margin-right: 0px;
  border-radius: 4px;
  margin-top: 1rem;
}
#dropdown .dropdown-menu:before {
  position: absolute;
  top: -7px;
  right: 35px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: "";
}

#dropdown .dropdown-menu:after {
  position: absolute;
  top: -6px;
  right: 35px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #3b3b3b;
  border-left: 6px solid transparent;
  content: "";
  box-shadow: #757575;
}

.nav-dropdown a,
.nav-dropdown a:hover {
  text-decoration: none !important;
  color: #757575 !important;
}
.nav-dropdown a:active {
  background-color: #f7fafc !important;
}
.switchAccount{
  background: #1597B8;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
  color: #fff;
}
.hideBlock-content, .multipleAccounts{
  display: none;
}
.multipleAccounts.showSwithcAccount{
  display: block;
}
.multipleAcc-title{
  color: #11193C;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  margin-bottom: 1rem;
}
.backIcon{
  width: 30px;
  display: block;
  margin-right: 10px;
  cursor: pointer;
}
.backIcon svg{
  width: 100% !important;
}
.w-60{
  width: 195px;
}
.group_profile .btn-group{
  box-shadow: inherit !important;
}
.multipleProfile-list{
  max-height: 200px;
}
</style>
