<template>
  <div class="initial_page">
    <div class="main_page">
      <div class="welcome">
        <h1>Send Code</h1>
        <p>
          Choose where you would like to receive the code
        </p>
      </div>
      <div class="form">
        <div class="send_code">
          <h3>Choose where you would like to receive the verification code?</h3>
          <b-form-group >
            <b-form-radio
            name="radios"
              v-model="phone"
            >SMS (09123456790)</b-form-radio>
            <b-form-radio
            name="radios"
              v-model="email"
            >Email (sc**@htstream.com)</b-form-radio>
            <b-form-radio
            name="radios"
              v-model="both"
            >Both (SMS & Email)</b-form-radio>
          </b-form-group>
           
           
          <div class="error_message" v-if="formErrorFlag == true">
            {{ formErrors["error"] }}
          </div>
          <button @click="sendForgotPassword()">
            <b-spinner v-if="verifyLoader == true" small></b-spinner>
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import helper from "../resource/Helper";
// import axios from "axios";
export default {
  data() {
    return {
      email: "",
      phone: "",
      both:"",
      formErrorFlag: false,
      formErrors: [],
      verifyLoader: false,
    };
  },
  // methods: {
  //   sendForgotPassword() {
  //     let app = this;
  //     this.formErrorFlag = false;
  //     if (this.email == "" || helper.validateEmail(this.email) == false) {
  //       this.formErrors["error"] = "Please enter valid email address.";
  //       this.formErrorFlag = true;
  //       return;
  //     }
  //     app.verifyLoader = true;
  //     axios
  //       .post("/auth/reset-password", {
  //         data: app.email,
  //         option: "email"
  //       })
  //       .then(function (response) {
  //         if (response.data.status == "success") {
  //           helper.infoMessage(
  //             app,
  //             response.data.status,
  //             response.data.message
  //           );
  //           app.$store.commit("setMemberEmail", app.email);
  //           helper.delayRedirect("VerifyPasswordChangeOTP",1)
  //           app.verifyLoader = false;
  //         }
  //       })
  //       .catch(function (error) {
  //         if (error.response.data.status == "error") {
  //           app.formErrorFlag = true;
  //           app.formErrors["error"] = error.response.data.message;
  //         }
  //         app.verifyLoader = false;
  //       });
  //   },
  // },
};
</script>

<style>
@import "../../../assets/css/group_dashboard/send_code.css";
</style>